export const SIMULATION = "SIMULATION";
export const SIMULATION_FAIL = "SIMULATION_FAIL";
export const SIMULATION_SUCCESS = "SIMULATION_SUCCESS";

export const FIXED_SIMULATION = "FIXED_SIMULATION";
export const FIXED_SIMULATION_FAIL = "FIXED_SIMULATION_FAIL";
export const FIXED_SIMULATION_SUCESS = "FIXED_SIMULATION_SUCESS";

export const FETCH_MEAN_DATA = "FETCH_MEAN_DATA";
export const FETCH_MEAN_DATA_FAIL = "FETCH_MEAN_DATA_FAIL";
export const FETCH_MEAN_DATA_SUCCESS = "FETCH_MEAN_DATA_SUCCESS";

export const QUICK_SIMULATION = "QUICK_SIMULATION";
export const QUICK_SIMULATION_FAIL = "QUICK_SIMULATION_FAIL";
export const QUICK_SIMULATION_SUCCESS = "QUICK_SIMULATION_SUCCESS";

export type Constants =
  | typeof SIMULATION
  | typeof SIMULATION_FAIL
  | typeof SIMULATION_SUCCESS
  | typeof FIXED_SIMULATION
  | typeof FIXED_SIMULATION_FAIL
  | typeof FIXED_SIMULATION_SUCESS
  | typeof FETCH_MEAN_DATA
  | typeof FETCH_MEAN_DATA_FAIL
  | typeof FETCH_MEAN_DATA_SUCCESS
  | typeof QUICK_SIMULATION
  | typeof QUICK_SIMULATION_FAIL
  | typeof QUICK_SIMULATION_SUCCESS
  ;

export type SimulationPayload = {
  month_id: number;
  cbot: number;
  dolar: number;
  basis: number;
  volume_trigger: number;
  volume_trigger_negative: false;
  cbot_volume: number;
  dolar_volume: number;
  basis_volume: number;
};

export type QuickSimulationResponse = { 
  cbot_simulated: number,
  basis_simulated: number,
  dollar_simulated: number,
  frame_simulated: number
}
export type QuickSimulationPayload = { 
  month_id: number,
  basis: number,
  cbot: number,
  dolar: number,
}
export type SimulationResponse = {
  cbot_market: number;
  dollar_market: number;
  basis_market: number;
  cbot_fixated: number;
  dollar_fixated: number;
  basis_fixated: number;
  frame_market: number;
  volume_cbot: number;
  volume_dolar: number;
  volume_basis: number;
  volume_total: number;
  frame_fixated: number;
  cbot_simulated: number;
  basis_simulated: number;
  dollar_simulated: number;
  frame_simulated: number;
};

export type MeanData = {
  cbot_mean: number;
  basis_mean: number;
  dolar_mean: number;
  frame_mean: number;
};
export type SimulationState = {
  fail: boolean;
  loading: boolean;
  simulationResponse: SimulationResponse | null;
  quickSimulation: QuickSimulationResponse | null;
  meanData: MeanData | null;
};

export type SimulationAction = {
  type: Constants;
  payload: {
    data: SimulationResponse | MeanData | QuickSimulationResponse;
  };
};
