import Axios from "axios";

const Api = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const baseURL = "https://api.cargillframe.com.br";
Api.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    if (error?.response?.status === 401) {
      window.localStorage.clear();
      window.location.reload();
    } else {
      return error.response;
    }
  }
);

export default Api;
