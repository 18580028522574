import React from "react";

import {
  Legend,
  CartesianGrid,
  AreaChart,
  Area,
  ResponsiveContainer,
  YAxis,
  Tooltip,
  XAxis,
} from "recharts";

import { PendenciesContractMonthInfo } from "../../modules/types";
import {
  CustomLabelBasis,
  CustomLabelCBOT,
  CustomLabelDolar
} from "./CustomLabel";

type Props = {
  infoPending?: PendenciesContractMonthInfo[];
  cbotHide: boolean;
  basisHide: boolean;
  dolarHide: boolean;
  open_closed_basis: boolean;
  open_closed_dolar: boolean;
  open_closed_cbot: boolean;
  symbol: string;
};

const renderTooltipContent = (o: any) => {
  const { active, payload } = o;

  if (active && payload?.length > 0) {
    return (
      <div
        className="customized-tooltip-content"
        style={{ backgroundColor: "white", paddingRight: 10, borderRadius: 5 }}
      >
        <ul className="list">
          <li
            key={`1`}
            style={{ color: "#66bc46", fontSize: "18px", fontWeight: "bold" }}
          >
            {`${payload[0].payload.reference_month}`}
          </li>
          <li key={`2`} style={{ color: "#66bc46", fontSize: "18px" }}>
            {`${payload[0].name}: ${payload[0].value.toFixed(1)}`}
          </li>
        </ul>
      </div>
    );
  } else {
    return null;
  }
};

const LineChartComponent: React.FC<Props> = ({
  infoPending,
  cbotHide,
  dolarHide,
  basisHide,
  open_closed_basis,
  open_closed_dolar,
  open_closed_cbot,
  symbol,
}) => {
  return (
    <ResponsiveContainer width='100%' height={320}>
      <AreaChart
        height={250}
        data={infoPending}
        style={{ color: "white" }}
      >
        <CartesianGrid
          height={300}
          strokeDasharray="0 0"
          strokeWidth={0.2}
          style={{ color: "black" }}
        />
        <Legend />
        <YAxis name={" "+ symbol + "/Ton "} unit={" " + symbol + " "} />
        <XAxis xAxisId={0} dataKey="reference_month" axisLine={false} />
        <XAxis xAxisId={1} dataKey="volume" unit=" TON" axisLine={false} />
        <Tooltip content={renderTooltipContent} />

        <Area
          dot={true}
          width={50}
          height={20}
          isAnimationActive={false}
          fill="#7159c1"
          type="monotone"
          dataKey="frame_quote"
          name={symbol + " /Ton "}
          stroke="#66bc46"
          fillOpacity={0.1}
          strokeWidth={2}
          hide={cbotHide === false || basisHide === false ? false : true}
          legendType="circle"
        />
        <Area
          dot={false}
          fill="none"
          isAnimationActive={false}
          type="natural"
          dataKey="cbot_quote"
          hide={cbotHide}
          strokeWidth={0}
          fillOpacity={0}
          legendType="none"
          label={(props) => {
            return (
              <CustomLabelCBOT
                {...props}
                open_closed_cbot={open_closed_cbot}
                stroke
                position="bottomRight"
              />
            );
          }}
        />
        <Area
          dot={false}
          fill="#0072bc"
          isAnimationActive={false}
          type="natural"
          dataKey="basis_quote"
          hide={basisHide}
          strokeWidth={0}
          fillOpacity={0}
          legendType="none"
          label={(props) => {
            return (
              <CustomLabelBasis
                {...props}
                open_closed_basis={open_closed_basis}
                stroke
                position="bottomRight"
              />
            );
          }}
        />

        <Area
          dot={false}
          fill="#66bc46"
          isAnimationActive={false}
          type="natural"
          dataKey="dolar_quote"
          hide={dolarHide}
          strokeWidth={0}
          fillOpacity={0}
          legendType="none"
          name="DOLAR"
          label={(props) => {
            return (
              <CustomLabelDolar
                {...props}
                open_closed_dolar={open_closed_dolar}
                stroke
                position="bottomRight"
              />
            );
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
export default LineChartComponent;
