import React from "react";
import styles from "./theme/index.module.scss";
import { ContractInfo } from "../../modules/Main/Contracts/types";
import Formula from '../formula';

type Props = {
  contractInfo: ContractInfo;
};
const SiderInfo: React.FC<Props> = ({ contractInfo }) => {

  return (
		<div className={styles.mainContainer}>
			<div className={styles.expressionContainer}>
				<span className={styles.spanWrapper} style={{fontSize: '11px'}}>
					<Formula contractInfo={contractInfo} exibirValor={false} carregarBackground={false} />
				</span>
			</div>

			<div className={styles.row}>
				<div className={styles.col1}>
					<span className={styles.span}>Data de expiração</span>
					<br />
					<span className={styles.span}>Cidade de Origem</span>
					<br />
					<span className={styles.span}>Cidade de Destino</span>
					<br />
					<span className={styles.span}>Frete</span>
					<br />
					<span className={styles.span}>Imposto</span>
					<br />
					<span className={styles.span}>Encargos</span>
					<br />
					<span
						className={styles.span}
						style={{ paddingBottom: 10, display: 'inline-block' }}
					>
						Fee
					</span>
				</div>
				<div className={styles.col2}>
					<span className={styles.span}>{contractInfo?.end_date}</span>
					<br />
					<span className={styles.span}>{contractInfo?.origin_city}</span>
					<br />
					<span className={styles.span}>{contractInfo?.destiny_city}</span>
					<br />
					<span className={styles.span}>{contractInfo?.frete}</span>
					<br />
					<span className={styles.span}>{contractInfo?.tax}</span>
					<br />
					<span className={styles.span}>{contractInfo?.encargos}% a.m</span>
					<br />
					<span className={styles.span}>{contractInfo?.fee} R$</span>
				</div>
			</div>
		</div>
	);
};
export default SiderInfo;
