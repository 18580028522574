import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';

import NotificationComponent from '../../NotificationBell';

import { AppState } from '../../../rootReducer';
// import frame from '../../../assets/frame.svg';
import oleoSvg from '../../../assets/oleo.svg';
import milhoSvg from '../../../assets/milho.svg';
import sojaSvg from '../../../assets/farelo-soja.svg';

import styles from './theme/index.module.scss'

type StyleProps = {
  style: CSSProperties;
};

const InformationHeader: React.FC<StyleProps> = ({ style }) => {

 const {
  selectedContractName,
  selectedProductName
  } = useSelector((state: AppState) => state.infoHeader);

	const switchProducts = (product: string) => {
		switch (product) {
			case 'Milho':
				return <img src={milhoSvg} className={styles.img} alt={product} />;
			case 'Farelo de Soja':
				return <img src={sojaSvg} className={styles.img} alt={product} />;
			case 'Óleo de Soja':
				return <img src={oleoSvg} className={styles.img} alt={product} />;
			default:
				break;
		}
	};

	return (
		<div style={style} className={styles.container}>
			<div className={styles.content}>
				{/* <img src={frame} alt='frame' className={styles.frame}/> */}
				<strong>FRAME</strong>

				<div className={styles.divider} />
				{switchProducts(selectedProductName)}

				<strong>
					{selectedProductName}: {selectedContractName}
				</strong>
				<NotificationComponent />
			</div>
		</div>
	);
}
export default InformationHeader;