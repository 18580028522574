import React, { useState } from "react";
import { Button, Tooltip, Popover } from "antd";
import { useSelector } from "react-redux";

import TooltipSpot from "./TooltipSpot";
import moneySvg from '../../../assets/money.svg';

import { getPercent } from "../../../utils/getPercent";
import { maskMoney } from "../../../utils/moneyMask";

import { AppState } from "../../../rootReducer";
import {
  ContractsHistoryMonth,
} from "../../../modules/Main/Contracts/types";

import './theme/overrides.css'
import styles from "./theme/index.module.scss";

type Props = {
  contractshistoryMonth: ContractsHistoryMonth[];
  cbotHide: [boolean, (value: boolean) => void];
  basisHide: [boolean, (value: boolean) => void];
  dolarHide: [boolean, (value: boolean) => void];
};

const FrameTable: React.FC<Props> = ({
  contractshistoryMonth,
  cbotHide,
  basisHide,
  dolarHide,
}) => {
	const [saca, setTon] = useState("Ton");
	const [inde, setIndex] = useState<number | null>(null);

	const { selectedProductName } = useSelector(
		(state: AppState) => state.infoHeader
	);

	const priceSaca = (frame_quote: number) => Number(60 * frame_quote) / 1000;

	const val = contractshistoryMonth?.filter((value) => {
		if (value.spot_price) {
			return value.spot_price;
		}
		// eslint-disable-next-line array-callback-return
		return;
	});

	return (
		<div className={styles.tableWrapper}>
			<div className={styles.tableContainer}>
				<div className={styles.buttonFilter}>
					{selectedProductName === "Milho" && (
						<div className={styles.buttonContainer}>
							<Button
								shape="round"
								size="middle"
								style={{
									border: "none",
									width: 110,
									marginRight: -15,
									backgroundColor: saca === "saca" ? "#66bc46" : "transparent",
									color: "white",
									height: "100%",
									fontFamily: "helvetica",
								}}
								onClick={() => {
									setTon("saca");
								}}
							>
								SACA
							</Button>
							<Button
								shape="round"
								size="middle"
								style={{
									border: "none",
									width: 110,
									marginLeft: -10,
									backgroundColor: saca === "Ton" ? "#66bc46" : "transparent",
									color: "white",
									fontFamily: "helvetica",
								}}
								onClick={() => {
									setTon("Ton");
								}}
							>
								TON
							</Button>
						</div>
					)}
				</div>
				{/* 
				//aqui
				*/}
				<div className={styles.resultContainer}>
					<div className={styles.tableRow}>
						<div className={styles.squareUnity}>
							<Button
								size="small"
								style={{
									fontWeight: "bold",
									color: "black",
									backgroundColor: "white",
									border: "none",
									borderRadius: "2px",
									width: "65px",
								}}
							>
								PREÇO
							</Button>
						</div>
						{contractshistoryMonth?.map((item) => (
							<div className={styles.resultSpan} key={item.id.toString()}>
								{saca === "Ton" || selectedProductName !== "Milho"
									? maskMoney(Number(item?.frame_quote)?.toFixed(2))
									: maskMoney(priceSaca(item?.frame_quote).toFixed(2))}
							</div>
						))}
					</div>
				</div>

				<div className={styles.cbotContainer}>
					<div className={styles.tableRow}>
						<div className={styles.squareUnity}>
							<Tooltip
								title={
									<>
										<span style={{ color: "#ea6f24", fontWeight: "bold" }}>
											Referência cotação de farelo de soja chicago board of
											trade (CBOT)
										</span>
									</>
								}
							>
								<Button
									size="small"
									style={{
										fontWeight: "bold",
										color: "white",
										backgroundColor: "#ea6f24",
										border: "none",
										borderRadius: "2px",
										width: "65px",
									}}
									onClick={() => {
										if (dolarHide[0] && basisHide[0]) {
											dolarHide[1](false);
											basisHide[1](false);
										} else {
											cbotHide[1](false);
											basisHide[1](true);
											dolarHide[1](true);
										}
									}}
								>
									CBOT
								</Button>
							</Tooltip>
						</div>
						{contractshistoryMonth?.map((item) => (
							<div className={styles.cbotSpan} key={item.id.toString()}>
								{getPercent(item.cbot_volume, item.fixated_cbot)
									.toFixed(0)
									.replace(".", ",")}
								%
							</div>
						))}
					</div>
				</div>
				<div className={styles.basisContainer}>
					<div className={styles.tableRow}>
						<div className={styles.squareUnity}>
							<Tooltip
								title={
									<>
										<span style={{ color: "#0072bc", fontWeight: "bold" }}>
											Referência de prêmio no porto de Paranaguá
										</span>
									</>
								}
							>
								<Button
									size="small"
									style={{
										fontWeight: "bold",
										color: "white",
										backgroundColor: "#0072bc",
										border: "none",
										borderRadius: "2px",
										width: "65px",
									}}
									onClick={() => {
										if (cbotHide[0] && dolarHide[0]) {
											cbotHide[1](false);
											dolarHide[1](false);
										} else {
											basisHide[1](false);
											cbotHide[1](true);
											dolarHide[1](true);
										}
									}}
								>
									BASIS
								</Button>
							</Tooltip>
						</div>
						{contractshistoryMonth?.map((item) => (
							<div className={styles.basisSpan} key={item.id.toString()}>
								{getPercent(item.basis_volume, item.fixated_basis)
									.toFixed(0)
									.replace(".", ",")}
								%
							</div>
						))}
					</div>
				</div>
				<div className={styles.dolarContainer}>
					<div className={styles.tableRow}>
						<div className={styles.squareUnity}>
							<Tooltip
								title={
									<>
										<span style={{ color: "#66bc46", fontWeight: "bold" }}>
											Referência de cotação de dólar BM&F
										</span>
									</>
								}
							>
								<Button
									size="small"
									style={{
										fontWeight: "bold",
										color: "white",
										backgroundColor: "#66bc46",
										border: "none",
										borderRadius: "2px",
										width: "65px",
									}}
									onClick={() => {
										if (cbotHide[0] && basisHide[0]) {
											cbotHide[1](false);
											basisHide[1](false);
										} else {
											dolarHide[1](false);
											basisHide[1](true);
											cbotHide[1](true);
										}
									}}
								>
									DÓLAR
								</Button>
							</Tooltip>
						</div>
						{contractshistoryMonth?.map((item) => (
							<div className={styles.dolarSpan} key={item.id.toString()}>
								{getPercent(item.dolar_volume, item.fixated_dolar)
									.toFixed(0)
									.replace(".", ",")}
								%
							</div>
						))}
					</div>
				</div>

				{/* spot */}
				{val.length > 0 && (
					<div className={styles.spotContainer}>
						<div className={styles.tableRow}>
							<div className={styles.squareUnity}>
								<Tooltip
									title={
										<span style={{ color: "#FFD600", fontWeight: "bold" }}>
											Referência cotação de (SPOT)
										</span>
									}
								>
									<Button
										size="small"
										style={{
											fontWeight: "bold",
											color: "black",
											backgroundColor: "#FFD600",
											border: "none",
											borderRadius: "2px",
											width: "65px",
											alignItems: "center",
											justifyContent: "center",
											marginLeft: 50 / contractshistoryMonth.length + "%",
										}}
									>
										BALCÃO
									</Button>
								</Tooltip>
							</div>
							{contractshistoryMonth?.map((item, index) => {
								return (
									<div className={styles.spotSpan} key={item.id.toString()}>
										{maskMoney(Number(item?.spot_price)?.toFixed(2))}
										<Popover
											visible={index === inde}
											content={
												<TooltipSpot
													frete={item?.frete}
													month_id={item?.id}
													spot_end_date={item?.spot_end_date}
													spot_volume={item?.spot_volume}
													reference_month={item?.reference_month}
													tax={item?.tax}
													spot_fixated={item.spot_fixated}
													setIndex={setIndex}
													spot_price={item?.spot_price}
												/>
											}
										>
											<img
												src={moneySvg}
												alt="moneySvg"
												onClick={() => setIndex(index)}
												className={styles.imgSpot}
											/>
										</Popover>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
export default FrameTable;
