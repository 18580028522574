import React from "react";
import styles from "./theme/index.module.scss";

type Props = {
	topItems: Array<string>;
};

const TopRow: React.FC<Props> = ({ topItems }) => {
	return (
		<div className={styles.activeTop}>
			<div className={styles.itemsRow}>
				{topItems.map((item) => (
					<div className={styles.pendentHeaderTitle} key={item}>
						{item}
					</div>
				))}
			</div>
		</div>
	);
};
export default TopRow;
