import {
  SIMULATION,
  SIMULATION_SUCCESS,
  SIMULATION_FAIL,
  FIXED_SIMULATION_SUCESS,
  FIXED_SIMULATION_FAIL,
  FIXED_SIMULATION,
  SimulationPayload,
  FETCH_MEAN_DATA_FAIL,
  FETCH_MEAN_DATA_SUCCESS,
  QUICK_SIMULATION,
  QUICK_SIMULATION_FAIL,
  QUICK_SIMULATION_SUCCESS,
  QuickSimulationPayload,
} from "./types";

import { Dispatch } from "redux";
import Api from "../../../api/api";

export const meanVolumeSimulate =
	(payload: SimulationPayload) => (dispatch: Dispatch) => {
		dispatch({ type: SIMULATION });

		Api.post('user/month/simulation/volume', payload, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		})
			.then((res) => {
				if (res.status === 200) {
					dispatch({
						type: SIMULATION_SUCCESS,
						payload: {
							data: res.data,
						},
					});
				} else {
					dispatch({
						type: SIMULATION_FAIL,
					});
				}
			})
			.catch((err) => dispatch({ type: SIMULATION_FAIL }));
	};

export const fixedSimulate = (month_id: number) => (dispatch: Dispatch) => {
	dispatch({ type: FIXED_SIMULATION });
	Api.get(`user/simulation/mean?month_id=${month_id}`, {
		headers: {
			authorization: `${localStorage.getItem('token')}`,
		},
	})
		.then((res) => {
			if (res.status === 200) {
				dispatch({
					type: FIXED_SIMULATION_SUCESS,
					payload: {
						data: res.data,
					},
				});
			} else {
				dispatch({
					type: FIXED_SIMULATION_FAIL,
				});
			}
		})
		.catch((err) => {
			dispatch({
				type: FIXED_SIMULATION_FAIL,
			});
		});
};

export const getMeanData = (month_id: number) => (dispatch: Dispatch) => {
	dispatch({
		type: FETCH_MEAN_DATA_FAIL,
	});

	Api.get(`user/simulation/mean?month_id=${month_id}`, {
		headers: {
			authorization: `${localStorage.getItem('token')}`,
		},
	})
		.then((res) => {
			if (res.status === 200 && res.data) {
				dispatch({
					type: FETCH_MEAN_DATA_SUCCESS,
					payload: {
						data: res.data,
					},
				});
			} else {
				dispatch({
					type: FETCH_MEAN_DATA_FAIL,
				});
			}
		})
		.catch((err) =>
			dispatch({
				type: FETCH_MEAN_DATA_FAIL,
			})
		);
};

export const quickSimulate =
	(payload: QuickSimulationPayload) => (dispatch: Dispatch) => {
		dispatch({
			type: QUICK_SIMULATION,
		});

		Api.post('/user/month/simulation/price', payload, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		})
			.then((res) => {
				if (res.status === 200 && res.data) {
					dispatch({
						type: QUICK_SIMULATION_SUCCESS,
						payload: {
							data: res.data,
						},
					});
				} else {
					dispatch({
						type: QUICK_SIMULATION_FAIL,
					});
				}
			})
			.catch((err) =>
				dispatch({
					type: QUICK_SIMULATION_FAIL,
				})
			);
	};
