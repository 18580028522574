import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "../modules/Login";
import Main from "../modules/Main";
import PrivateRoute from "./PrivateRoute";
import OrderPage from "../modules/Order";
import AcceptedOrders from "../modules/AcceptedOrders";
import LoginCallback from '../modules/LoginCallback/index';
import Pendencies from "../modules/Pendencies";
import InfoPendencies from "../modules/Pendencies/components";

var pages = [
  {
    exact: true,
    path: "/",
    component: Login,
    private: false,
  },
  {
    exact: true,
    path: "/login",
    component: LoginCallback,
    private: false,
  },
  {
    exact: true,
    path: "/main",
    component: Main,
    private: true,
  },
  {
    exact: true,
    path: "/order",
    component: OrderPage,
    private: true,
  },
  {
    exact: true,
    path: "/acceptedorders",
    component: AcceptedOrders,
    private: true,
  },
  {
    exact: true,
    path: "/pendencies",
    component: Pendencies,
    private: true,
  },
  {
    exact: true,
    path: "/pendencies/pending_contract",
    component: InfoPendencies,
    private: true,
  },
];

const Routes: React.FC = () => {
  return (
		<Router>
			<Switch>
				{pages.map((item, index) =>
					item.private ? (
						<PrivateRoute {...item} key={item.path} />
					) : (
						<Route
							exact={item.exact}
							path={item.path}
							component={item.component}
							key={index}
						/>
					)
				)}
			</Switch>
		</Router>
	);
};
export default Routes;
