import React from "react";
import styles from "./theme/index.module.scss";
import redLabel from "../../../../assets/icons/redlabel-down.png";
import greenlabel from "../../../../assets/icons/greenlabel-up.png";
import orangelabel from "../../../../assets/icons/orangelabel.png";
import VariablesSpan from '../../../VariablesSpan/index';

enum positionColor {
  transparent = 0,
  doubleRed = -2,
  red = -1,
  orange = 1,
  green = 2,
  doubleGreen = 3,
  currentCbot = 10
}

type Props = {
  start: number;
  end: number;
  frequency: string;
  position: positionColor;
  current: number;
};
const SwingRow: React.FC<Props> = ({ position, frequency, current, start, end }) => {
  const selectColor = (position: positionColor) => {
    switch (position) {
      case positionColor.transparent:
        return null
      case positionColor.red:
        return (<img src={redLabel} alt="red label" style={{ height: 15, width: 15 }} />)
      case positionColor.doubleRed:
        return (<div><img src={redLabel} alt="red label" style={{ height: 15, width: 15 }} /><img src={redLabel} alt="red label" style={{ height: 15, width: 15 }} /></div>)
      case positionColor.green:
        return (<img src={greenlabel} alt="green label" style={{ height: 15, width: 15 }} />)
      case positionColor.doubleGreen:
        return (<div><img src={greenlabel} alt="green label" style={{ height: 15, width: 15 }} /><img src={greenlabel} alt="green label" style={{ height: 15, width: 15 }} /></div>)
      case positionColor.orange:
        return (<img src={orangelabel} alt="orange label" style={{ height: 15, width: 15 }} />)
      default:
        return null
    }
  }

  return (
    <div className={styles.swingDataRow}>
      <div className={styles.swingRowItem}>{`${start.toFixed(2).replace(".", ",")}`}</div>
      <div className={styles.swingRowItem}>{`${end.toFixed(2).replace(".", ",")}`}</div>
      <div className={styles.swingRowItem}>{frequency}</div>
      <div className={styles.swingRowItem}>
        {selectColor(position)}
      </div>
      <div className={styles.swingRowItem}>
        {current === 1 ? <div className={styles.divVariableRow} ><VariablesSpan variableName={'cbot'} height="25px" width="70px" /></div> : null}
      </div>
    </div>
  );
};
export default SwingRow;
