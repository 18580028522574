import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AcceptedOrdersMain from "../../components/AcceptedOrdersComponents/AcceptedOrdersMain";
import PageFooter from "../../components/Footer";
import SiderComponent from "../../components/Sider";

import { orderAcceptedFetch } from "../../api/orderFetch";
import { AppState } from "../../rootReducer";

import {
  fetchFixated,
  getSelectedContractInfo,
  selectedUserContractGetAll,
  selectHoliday,
} from "../Main/Contracts/actions";
import { fetchAcceptedOrders } from "../Order/Orders/actions";

import {
  ContractInfo,
  Contracts,
  ContractsHistoryMonth,
  FixatedValues,
  FrameNextPreviewSelected,
  MonthSelected,
} from "../Main/Contracts/types";

import { Order } from "../Order/Orders/types";

import InformationHeader from "../../components/Frame/InformationHeader";
import styles from "./theme/index.module.scss";

const AcceptedOrders: React.FC = () => {
  const { Content } = Layout;
  const dispatch = useDispatch();
  const [acceptedOrdersA, setAcceptedOrdersA] = useState<Order[]>([]);

  const {
    monthSelected,
    contractInfo,
    loading,
    contractshistoryMonth,
    frameNextPreviewSelected,
  }: {
    contracts: Contracts[] | null;
    contractshistoryMonth: ContractsHistoryMonth[];
    monthSelected: MonthSelected;
    frameNextPreviewSelected: FrameNextPreviewSelected;
    contractInfo: ContractInfo;
    loading: boolean;
    fixatedValues: FixatedValues;
  } = useSelector((state: AppState) => state.contracts);

  const { acceptedOrders }: { acceptedOrders: Order[] } = useSelector(
    (state: AppState) => state.orders
  );
  const { color } = useSelector((state: AppState) => state.infoHeader);
  const { contractsWhitProducts } = useSelector(
    (state: AppState) => state.product
  );

  useEffect(() => {
    if (contractInfo !== undefined && monthSelected)
      localStorage.setItem("monthSelected", monthSelected?.selected.toString());
    dispatch(
      fetchAcceptedOrders(contractInfo?.id, monthSelected?.selected, "")
    );
  }, [monthSelected, contractInfo]);

  //FUNCAO QUE ATIVA O FUNCIONAMENTO DO SWR
  const { data } = orderAcceptedFetch<Order[]>();

  let monthSelectedStorage = localStorage.getItem("monthSelected");
  let contractSelectedId = localStorage.getItem("contractIdSelected");

  useEffect(() => {
    let contractIdSelectedStorage = localStorage.getItem("contractIdSelected");
    if (
      contractIdSelectedStorage != "" &&
      contractIdSelectedStorage != null &&
      monthSelected
    ) {
      let variable = localStorage.getItem("variable")
        ? localStorage.getItem("variable")
        : "";
      dispatch(
        fetchAcceptedOrders(
          contractIdSelectedStorage,
          monthSelected?.selected,
          variable ? variable : ""
        )
      );
    }
  }, [data, monthSelected]);

  useEffect(() => {
    if (monthSelected?.selected) {
      dispatch(fetchFixated(monthSelected?.selected));
    }
  }, [monthSelected, dispatch]);

  let contractIdSelectedStorage = localStorage.getItem("contractIdSelected");
  useEffect(() => {
    if (contractIdSelectedStorage !== "") {
      dispatch(
        selectedUserContractGetAll(Number(contractIdSelectedStorage), null)
      );
      dispatch(getSelectedContractInfo(Number(contractIdSelectedStorage)));
      dispatch(selectHoliday(Number(contractIdSelectedStorage)));
    }
  }, [contractIdSelectedStorage, dispatch]);

  useEffect(() => {
    setAcceptedOrdersA(acceptedOrders);
  }, [acceptedOrders]);

  return (
    <Layout>
      <SiderComponent
        contracts={contractsWhitProducts}
        monthSelected={monthSelected}
        contractInfo={contractInfo}
      />
      <Layout
        className="site-layout"
        style={{
          minHeight: "100vh",
          justifyContent: "center",
          backgroundColor: "#0B1E22",
          left: 290,
          position: "absolute",
          width: "calc(100% - 290px)",
        }}
      >
        <div
          style={{
            width: "calc(100% - 325px)",
            top: 6,
            position: "fixed",
            zIndex: 999,
            background: "none",
          }}
        >
          <InformationHeader
            style={{
              marginLeft: 35,
              background: `linear-gradient(90deg, #000000 27.71%, #${
                color || "D2955D"
              } 75.91%)`,
            }}
          />
        </div>

        <Content
          style={{
            margin: "16px",
            padding: 20,
            backgroundColor: "#0B1E22",
            marginTop: 60,
          }}
        >
          {!contractsWhitProducts || contractsWhitProducts?.length <= 0 ? (
            <p
              style={{
                fontSize: 18,
                color: "#fff",
                marginTop: 60,
                textAlign: "center",
              }}
            >
              Nenhum contrato disponível
            </p>
          ) : (
            <>
              <div className={styles.frameMainContainer}>
                <div className={styles.frameContainer}>
                  <AcceptedOrdersMain
                    contractshistoryMonth={contractshistoryMonth}
                    monthSelected={monthSelected}
                    contractInfo={contractInfo}
                    acceptedOrders={acceptedOrdersA}
                    loading={loading}
                    frameNextPreviewSelected={frameNextPreviewSelected}
                  />
                </div>
              </div>
            </>
          )}
        </Content>
      </Layout>
      <PageFooter />
    </Layout>
  );
};

export default AcceptedOrders;
