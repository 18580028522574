import React from "react";
import styles from "./theme/index.module.scss";
import VariablesSpan from "../../../VariablesSpan";
import { Switch, Button, InputNumber } from "antd";

type Props = {
  variableName: string;
  directionSwitch: ["<" | ">" | null, (value: "<" | ">") => void];
  activeSwitch: [boolean, (value: boolean) => void];
  triggerHandler: [number, (value: number) => void];
  placeholder: string;
  switchActive?: boolean;
};
const AlertRow: React.FC<Props> = ({
  variableName,
  directionSwitch,
  activeSwitch,
  triggerHandler,
  placeholder,
  switchActive
}) => {
  const renderStep = () => {
    switch (variableName) {
      case "cbot":
        return 0.5;
      case "dolar":
        return 0.1;
      case "preço":
        return 100;
      case "basis":
        return 0.5;
    }
  };
  return (
    <div className={styles.alertRow}>
      <div className={styles.rowItem}>
        <VariablesSpan height="30px" width="80px" variableName={variableName} />
      </div>
      <div className={styles.rowItem}>
        <div className={styles.selectActual}>
          <Button
            shape="round"
            size="middle"
            style={{
              border: "none",
              width: 50,
              marginRight: -5,
              color: "white",
              backgroundColor:
                directionSwitch[0] === "<" ? "#f22" : "transparent",
            }}
            onClick={() =>
              directionSwitch[1](directionSwitch[0] === "<" ? ">" : "<")
            }
          >
            {"<"}
          </Button>
          <Button
            shape="round"
            size="middle"
            style={{
              border: "none",
              width: 50,
              marginLeft: -5,
              color: "white",
              backgroundColor:
                directionSwitch[0] === ">" ? "#66bc46" : "transparent",
            }}
            onClick={() =>
              directionSwitch[1](directionSwitch[0] === ">" ? "<" : ">")
            }
          >
            {">"}
          </Button>
        </div>
      </div>
      <div className={styles.rowItem}>
        <span className={styles.resultSpan}>
          <InputNumber
            size="middle"
            type="number"
            className={styles.inputOverride}
            placeholder={placeholder}
            value={triggerHandler[0]}
            onChange={(value) => triggerHandler[1](Number(value))}
            step={renderStep()}
            style={{
              backgroundColor: "#5fa046",
              border: "none",
              color: "white",
              alignItems: "center",
              justifyContent: "center",
              width: "150px",
            }}
          />
        </span>
      </div>
          {switchActive !== true && (
            <div className={styles.rowItem}>
              <Switch onClick={(value) => activeSwitch[1](value)} />
            </div>
           )}
    </div>
  );
};
export default AlertRow;
