import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectedOptionGraphic } from "../../../../modules/Main/Contracts/actions";
import { AppState } from "../../../../rootReducer";
import {
  ContractsHistoryMonth,
  FrameNextPreviewSelected
} from "../../../../modules/Main/Contracts/types";

import SvgComponent from "../SVGRender";
import { selectedValueQuote } from "../../FrameTable/modules/actions";

type Props = {
  x: number;
  y: number;
  value: any;
  stroke: any;
  open_closed_cbot: boolean;
  open_closed_basis: boolean;
  open_closed_dolar: boolean;
  reference_month: string;
  index: number;
};

export const CustomLabelCBOT: React.FC<Props> = ({
	x,
	y,
	value,
	stroke,
	open_closed_cbot,
	index,
}) => {
	let values!: 'maior' | 'menor' | 'igual';
	const dispatch = useDispatch();

	const {
		contractshistoryMonth,
		frameNextPreviewSelected,
	}: {
		frameNextPreviewSelected: FrameNextPreviewSelected;
		contractshistoryMonth: ContractsHistoryMonth[];
	} = useSelector((appState: AppState) => appState.contracts);

	const cbot = contractshistoryMonth[index]?.cbot_quote;

	contractshistoryMonth[index]?.alerts?.find((val) => {
		if (val === undefined || val === null) return null;

		if (val.type === 'cbot') {
			if (val.trigger < cbot) {
				values = 'menor';
			} else if (val.trigger > cbot) {
				values = 'maior';
			} else if (val.trigger === cbot) {
				values = 'igual';
			}
		}
		return null;
	});

	return (
		<SvgComponent
			y={40}
			x={x >= 1195 ? x - 60 : x}
			open_closed={open_closed_cbot}
			variable={'cbot'}
			value={value}
			values={values}
			color="#ea6f24"
			title="Referência cotação de farelo de soja chicago board of trade (CBOT)"
			select={() => {
				dispatch(selectedValueQuote(value));

				if (frameNextPreviewSelected?.nowActive === true) {
					dispatch(selectedOptionGraphic('cbot', true, false, false));
				} else {
					return;
				}
			}}
		/>
	);
};

export const CustomLabelBasis: React.FC<Props> = ({
	x,
	y,
	value,
	stroke,
	index,
	open_closed_basis,
}) => {
	let values!: 'maior' | 'menor' | 'igual';

	const dispatch = useDispatch();

	const {
		contractshistoryMonth,
		frameNextPreviewSelected,
	}: {
		frameNextPreviewSelected: FrameNextPreviewSelected;
		contractshistoryMonth: ContractsHistoryMonth[];
	} = useSelector((appState: AppState) => appState.contracts);

	const basis = contractshistoryMonth[index]?.basis_quote;

	contractshistoryMonth[index]?.alerts?.find((val) => {
		if (val === undefined || val === null) return null;

		if (val.type === 'basis') {
			if (val.trigger < basis) {
				values = 'menor';
			} else if (val.trigger >= basis) {
				values = 'maior';
			} else if (val.trigger === basis) {
				values = 'igual';
			}
		}
		return null;
	});

	return (
		<SvgComponent
			y={90}
			x={x >= 1195 ? x - 60 : x}
			open_closed={open_closed_basis}
			variable={'basis'}
			value={value}
			values={values}
			color="#0072bc"
			title="Referência de prêmio no porto de Paranaguá"
			select={() => {
				dispatch(selectedValueQuote(value));

				if (frameNextPreviewSelected?.nowActive === true) {
					dispatch(selectedOptionGraphic('basis', false, true, false));
				} else {
					return;
				}
			}}
		/>
	);
};

export const CustomLabelDolar: React.FC<Props> = ({
  x,
  y,
  value,
  stroke,
  index,
  open_closed_dolar,
}) => {
  
  let values!: 'maior' | 'menor' | 'igual';

  const dispatch = useDispatch();

  const {
    contractshistoryMonth,
    frameNextPreviewSelected,
  }: {
    frameNextPreviewSelected: FrameNextPreviewSelected;
    contractshistoryMonth: ContractsHistoryMonth[];
  } = useSelector((appState: AppState) => appState.contracts);

  const dolar = contractshistoryMonth[index]?.dolar_quote;

  contractshistoryMonth[index]?.alerts?.find(val => {
    if (val === undefined || val === null) return null;
    
    if(val.type === 'dolar') {
      if (val.trigger < dolar) {
        values = 'menor'
      } else if (val.trigger > dolar) {
        values = 'maior'
      } else if (val.trigger > dolar) {
        values = 'igual'
      }
    }
    return null;
  });

    return (
    <SvgComponent
      x={x >= 1195 ? x - 60 : x}
      y={140}
      open_closed={open_closed_dolar}
	  variable={'dolar'}
      value={value}
      values={values}
      color='#66bc46'
      title="Referência de cotação de dólar BM&F"
      select={() => {
        dispatch(selectedValueQuote(value));

        if(frameNextPreviewSelected?.nowActive === true) {
          dispatch(selectedOptionGraphic('dolar', false, false, true))
        } else {
          return;
        }
      }}
    />
  );
};
