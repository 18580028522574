import { Dispatch } from "redux";
import { notification } from "antd";
import {
	PENDENCIES,
	PENDENCIES_FAIL,
	PENDENCIES_SUCCESS,
	CONTRACT_REQUEST,
	CONTRACT_REQUEST_FAIL,
	CONTRACT_REQUEST_SUCCESS,
	CONTRACT_MONTH_INFO_REQUEST,
	CONTRACT_MONTH_INFO_REQUEST_FAIL,
	CONTRACT_MONTH_INFO_REQUEST_SUCCESS,
	SEND_CONTRACT_APPROVED,
	SEND_CONTRACT_APPROVED_SUCCESS,
	RESET_CONTRACT_APPROVED_SUCCESS,
	SEND_CONTRACT_APPROVED_FAIL,
} from './types';
import Api from '../../../api/api';

export const fetchAllContractsPendencies = () => (dispatch: Dispatch) => {
	dispatch({
		type: PENDENCIES,
	});

	Api.get('/user/contract/request/getall', {
		headers: {
			authorization: `${localStorage.getItem('token')}`,
		},
	}).then((res) => {
		if (res.status === 200) {
			dispatch({
				type: PENDENCIES_SUCCESS,
				payload: {
					data: res.data,
				},
			});
		} else {
			dispatch({
				type: PENDENCIES_FAIL,
			});
		}
	});
};

export const fetchContractInfo =
	(request_id: number) => (dispatch: Dispatch) => {
		dispatch({
			type: CONTRACT_REQUEST,
		});

		Api.get(`user/contract/request?request_id=${request_id}`, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		}).then((res) => {
			if (res.status === 200) {
				dispatch({
					type: CONTRACT_REQUEST_SUCCESS,
					payload: {
						data: res.data,
					},
				});
			} else {
				dispatch({
					type: CONTRACT_REQUEST_FAIL,
				});
			}
		});
	};

export const fetchContractMonthInfo =
	(request_id: number) => (dispatch: Dispatch) => {
		dispatch({
			type: CONTRACT_MONTH_INFO_REQUEST,
		});

		Api.get(`/user/contract/request/month/getall?request_id=${request_id}`, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		}).then((res) => {
			if (res.status === 200) {
				dispatch({
					type: CONTRACT_MONTH_INFO_REQUEST_SUCCESS,
					payload: {
						data: res.data,
					},
				});
			} else {
				dispatch({
					type: CONTRACT_MONTH_INFO_REQUEST_FAIL,
				});
			}
		});
	};

export const sendApproveDeny =
	(data: { request_id: number; accepted: boolean; note?: string }) =>
	(dispatch: Dispatch) => {
		dispatch({
			type: SEND_CONTRACT_APPROVED,
		});

		Api.post(`/user/contract/request/approvedeny`, data, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		})
			.then((res) => {
				if (res.status === 200) {
					dispatch({
						type: SEND_CONTRACT_APPROVED_SUCCESS,
						payload: {
							data: res.data,
						},
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: SEND_CONTRACT_APPROVED_FAIL,
				});

				notification.error({
					message: err.message,
					duration: 5,
				});
			});
	};

export const resetContactApproved = () => (dispatch: Dispatch) => {
	dispatch({
		type: SEND_CONTRACT_APPROVED,
	});

	dispatch({
		type: RESET_CONTRACT_APPROVED_SUCCESS,
	});
}; 