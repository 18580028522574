import React from "react";
import styles from "./theme/index.module.scss";
import VariablesSpan from "../../../VariablesSpan";
import decline from "../../../../assets/icons/decline.png";
import approve from "../../../../assets/icons/approve.png";
import MonthSpan from "../../../MonthSpan";
import { Order } from "../../../../modules/Order/Orders/types";
import { Popconfirm, notification } from "antd";
import moment from "moment";
import { UserInfo } from "../../../../modules/Main/User/types";

type Props = {
  pendingOrder: Order;
  handleCancel: (order_id: number) => void | null;
  handleConfirm: (order_id: number, accepted: boolean) => void | null;
  userInfo: UserInfo | null;
};
const PendingRow: React.FC<Props> = ({ pendingOrder, handleCancel, userInfo, handleConfirm }) => {
  return (
    <div className={pendingOrder?.base_user === userInfo?.id && pendingOrder?.base_user_send_order != userInfo?.id ? styles.rowContainerUnchecked : styles.rowContainer} >
      <div className={styles.columnContainer}>
        <VariablesSpan
          height="20px"
          width="50px"
          variableName={pendingOrder?.variable}
        />
        <span>{moment(pendingOrder?.date).format("DD/MM/YYYY - HH:mm")}</span>
      </div>
      <div className={styles.columnContainer}>
        <span>{pendingOrder?.fixated_remain}</span>
        <MonthSpan
          height="auto"
          width="50px"
          month={pendingOrder?.reference_month}
        />
      </div>
      <div className={styles.columnContainer}>
        <span className={styles.priceSpan}>{pendingOrder.price}</span>
        <span>{moment(pendingOrder?.date).format("DD/MM/YYYY - HH:mm")}</span>
      </div>
      <div className={styles.columnContainer}>

        <span>{pendingOrder?.valid_until} {pendingOrder?.valid_until > 1 ? "dias" : "dia"}  </span>


        <span>
          {moment(pendingOrder?.expiration_date).format("DD/MM/YYYY - HH:mm")}
        </span>
      </div>
      <div className={styles.columnContainer}>
        <Popconfirm
          title="Você realmente deseja cancelar esta solicitação de precificação?"
          onConfirm={() => handleCancel(pendingOrder?.id)}
          okText="Sim"
          cancelText="Não"
          onCancel={() =>
            notification.warn({
              message: "Nenhuma alteração foi feita nas suas solicitações de precificação.",
            })
          }
        >
          <img
            src={decline}
            alt="negar/cancelar"
            style={{ width: "20px", cursor: "pointer" }}
          />
        </Popconfirm>
      </div><div className={styles.columnContainer}>
        {
          //quando usuario logado for igual a base_user
          //e usuario logado diferente de base_user_send_order
        }
        {pendingOrder?.base_user === userInfo?.id && pendingOrder?.base_user_send_order != userInfo?.id ? (
        <Popconfirm
          title="Você realmente deseja confirmar esta solicitação de precificação?"
          onConfirm={() => handleConfirm(pendingOrder?.id, true)}
          okText="Sim"
          cancelText="Não"
          onCancel={() =>
            notification.warn({
              message: "Nenhuma alteração foi feita nas suas solicitações de precificação.",
            })
          }
        >
          <img
            src={approve}
            alt="Aprovar/Confirmar"
            style={{ width: "25px", cursor: "pointer" }}
          />
        </Popconfirm>
        ) : null}
      </div>
    </div>
  );
};

export default PendingRow;
