import React from "react";
import Logo from "../../assets/icons/loading1.gif";
const Loading: React.FC = () => {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        WebkitTransform: "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
      }}
    >
      <img
        src={Logo}
        alt="frame-loading"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      />
    </div>
  );
};

export default Loading;
