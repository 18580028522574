import React from 'react';
import styles from './theme/index.module.scss';
import { Tooltip } from 'antd';
import { ContractInfo } from '../../modules/Main/Contracts/types';
import { PendenciesContractInfo } from '../../modules/Pendencies/modules/types';

type Props = {
	contractInfo: ContractInfo | PendenciesContractInfo;
	exibirValor: boolean;
	carregarBackground: boolean;
};

const Formula: React.FC<Props> = ({ contractInfo, exibirValor, carregarBackground }) => {
	const iconShow = (
		item: string
	) => {
		switch (item) {
			case '/':
				return (
					<>
						<br />
						{encarg()}
					</>
				);
			case 'cbot':
				return c();
			case 'basis':
				return b();
			case 'dolar':
				return d();
			case 'fator':
				return montarInput('FATOR', contractInfo?.factor.toString(), '27px');
			case 'fee':
				return montarInput('FEE', contractInfo?.fee?.toString(), '27px');
			case 'icms':
				return montarInput('ICMS', contractInfo?.icms.toString(), '27px');
			case 'juros':
				return montarInput('JUROS', contractInfo?.juros.toString(), '27px');
			case 'spread':
				return montarInput('SPREAD', contractInfo?.spread.toString(), '27px');
			case 'encargos':
				return montarInput('ENCARGOS', contractInfo?.encargos.toString(), '70px');
			case '':
				break;
			default:
				return montarInputDefault(item);
		}
	};

	const encarg = () => {
		return <hr style={{ width: '85%', border: '1px solid white' }} />;
	};

	const montarInput = (type: string, valor: string, minWidth: string) => {
		return (
			<input style={{minWidth: minWidth}}
				className={exibirValor ? (carregarBackground ? styles.montarInputComValor : styles.montarInputComValorBackgroundOff) : carregarBackground ? styles.montarInputSemValor : styles.montarInputComValorBackgroundOff }
				disabled
				value={exibirValor ? valor : type}
			/>
		);
	};

	const montarInputDefault = (valor: string) => {
		return (
			<span
			className={carregarBackground ? Number(valor) ? styles.montarSpanNumber : styles.montarSpan : styles.montarSpanDefault}
			>{valor}</span>
		);
	};


	const c = () => {
		return (
			<Tooltip
				title={
					<span style={{ color: '#ea6f24', fontWeight: 'bold' }}>
						Referência cotação de farelo de soja chicago board of trade (CBOT)
					</span>
				}
			>
				<span
					style={{
						backgroundColor: '#ea6f24',
						padding: 5,
						fontWeight: 'bold',
						borderRadius: 5,
						color: 'white',
						marginLeft: 4,
						marginRight: 4
					}}
				>
					C
				</span>
			</Tooltip>
		);
	};

	const b = () => {
		return (
			<Tooltip
				title={
					<span style={{ color: '#0072bc', fontWeight: 'bold' }}>
						Referência de prêmio no porto de Paranaguá
					</span>
				}
			>
				<span
					style={{
						backgroundColor: '#0072bc',
						padding: 5,
						fontWeight: 'bold',
						borderRadius: 5,
						color: 'white',
						marginLeft: 4,
						marginRight: 4
					}}
				>
					B
				</span>
			</Tooltip>
		);
	};

	const d = () => {
		return (
			<Tooltip
				title={
					<span style={{ color: '#66bc46', fontWeight: 'bold' }}>
						Referência de cotação de dólar BM&F
					</span>
				}
			>
				<span
					style={{
						backgroundColor: '#66bc46',
						padding: 5,
						fontWeight: 'bold',
						borderRadius: 5,
						color: 'white',
						marginLeft: 4,
						marginRight: 4
					}}
				>
					D
				</span>
			</Tooltip>
		);
	};

	const equation = () => {
		if (contractInfo && contractInfo?.equation) {
			let positionArrayOrder = [];
			let itensFormula = [
				'cbot',
				'basis',
				'dolar',
				'fator',
				'fee',
				'icms',
				'juros',
				'spread',
				'encargos',
				'/',
			];

			let equationResult: any = [];
			let splitEquation = contractInfo?.equation.split(" ");
			for (let x = 0; x < splitEquation.length; x++) {
				if(Number(itensFormula.indexOf(splitEquation[x])) >= 0){
					equationResult.push(iconShow(splitEquation[x]))
				}else{
					equationResult.push(iconShow(splitEquation[x]));
				}
			}

			return equationResult;
		}
	};

	return <> {equation()}</>;
};

export default Formula;
