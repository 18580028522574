import cmaSWR from 'swr';
import api from './api';
import { Notification } from '../modules/Notifications/types';

export function cmaFetch<Data = Notification, Error = any>() {
	let urlSend = `/lambda/CMA/okta?user_email=${localStorage.getItem(
		'user_email'
	)}`;

	const { data, error, mutate } = cmaSWR<Notification, Error>(
		urlSend,
		async (urlSend) => {
			const response = await api.post(
				urlSend,
				{},
				{
					headers: {
						authorization: `${localStorage.getItem('token')}`,
					},
				}
			);

			return response.data;
		},
		{ refreshInterval: 15000 }
	);

	return { data, error, mutate };
}
