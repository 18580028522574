import React from 'react';
import { useDispatch } from 'react-redux';
import { createNegotiationSpot } from '../modules/actions';

import styles from '../theme/index.module.scss'

type Props = {
  price: number;
  month_id: number;
  tonValue: number;
  setVisible:  React.Dispatch<React.SetStateAction<boolean>>;
  setIndex: React.Dispatch<React.SetStateAction<number | null>>
};

const ModalConfirmation: React.FC<Props> = ({
  price,
  tonValue,
  month_id,
  setVisible,
  setIndex
}) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.modal_overlay}>
      <div className={styles.modal_wrapper}>
        <div className={styles.modal}>
          <h3>Deseja mesmo enviar essa ordem?</h3>

            <div className={styles.buttons}>
              <button onClick={() => { setVisible(false); setIndex(null) }}>Cancelar</button>
              <button onClick={() => {
                dispatch(createNegotiationSpot({
                  force_create: true,
                  month_id,
                  price,
                  volume: tonValue,
                }))
                setVisible(false);
                setIndex(null)
              }}>Enviar</button>
            </div>
        </div>
      </div>
    </div>
  )
}

export default ModalConfirmation;