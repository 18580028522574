import {
	SET_PRODUCT_HEADER,
	SET_CONTRACT_HEADER,
	INDEX_PRODUCT_SELECTED,
	SET_PRODUCT_HEADER_SUCCESS,
	SET_CONTRACT_HEADER_SUCCESS,
	INDEX_PRODUCT_SELECTED_SUCCESS,
	SET_CONTRACT_NOT_EXISTS_HEADER_SUCCESS,
} from './types';
import { Dispatch } from 'redux';

export const setProductHeader =
	(productSelected: string, color: string) => (dispatch: Dispatch) => {
		dispatch({ type: SET_PRODUCT_HEADER });
		dispatch({
			type: SET_PRODUCT_HEADER_SUCCESS,
			payload: {
				selectedProductName: productSelected,
				color,
			},
		});
	};

export const setContractHeader =
	(contractSelected: string, id_contract?: number) => (dispatch: Dispatch) => {
		dispatch({ type: SET_CONTRACT_HEADER });
		dispatch({
			type: SET_CONTRACT_HEADER_SUCCESS,
			payload: {
				id_contract,
				selectedContractName: contractSelected,
			},
		});
	};

export const setContractNotExistsHeader =
	(contractSelected: string, id_contract?: number) => (dispatch: Dispatch) => {
		dispatch({ type: SET_CONTRACT_HEADER });
		dispatch({
			type: SET_CONTRACT_NOT_EXISTS_HEADER_SUCCESS,
			payload: {
				id_contract,
				selectedContractNameNotExists: contractSelected,
			},
		});
	};

  export const selectedProductIndex = (indexProduct: number) => (dispatch: Dispatch) => {
    dispatch({ type: INDEX_PRODUCT_SELECTED });
      dispatch({
        type: INDEX_PRODUCT_SELECTED_SUCCESS,
        payload: {
          indexProductSelected: indexProduct,
        },
      });
    }