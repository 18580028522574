export const SET_PRODUCT_HEADER = "SET_PRODUCT_HEADER";
export const SET_PRODUCT_HEADER_SUCCESS = "SET_PRODUCT_HEADER_SUCCESS";

export const INDEX_PRODUCT_SELECTED = "INDEX_PRODUCT_SELECTED";
export const INDEX_PRODUCT_SELECTED_SUCCESS = "INDEX_PRODUCT_SELECTED_SUCCESS";

export const SET_CONTRACT_HEADER = "SET_CONTRACT_HEADER_WITH_PRODUCTS";
export const SET_CONTRACT_HEADER_SUCCESS = "SET_CONTRACT_HEADER_WITH_PRODUCTS_SUCCESS";
export const SET_CONTRACT_NOT_EXISTS_HEADER_SUCCESS =
	'SET_CONTRACT_NOT_EXISTS_HEADER_SUCCESS';

export type Constants =
	| typeof SET_PRODUCT_HEADER
	| typeof SET_CONTRACT_HEADER
	| typeof INDEX_PRODUCT_SELECTED
	| typeof SET_PRODUCT_HEADER_SUCCESS
	| typeof INDEX_PRODUCT_SELECTED_SUCCESS
	| typeof SET_CONTRACT_HEADER_SUCCESS
	| typeof SET_CONTRACT_NOT_EXISTS_HEADER_SUCCESS;

export type State = {
	color: string;
	loading: boolean;
	selectedProductName: string;
	indexProductSelected: number;
	id_contract?: number;
	selectedContractName: string;
	selectedContractNameNotExists: string;
};

export type Action = {
	type: Constants;
	payload: {
		color: string;
		loading: boolean;
		id_contract?: number;
		selectedProductName: string;
		indexProductSelected: number;
		selectedContractName: string;
		selectedContractNameNotExists: string;
	};
};