import React from "react";
import styles from "./theme/index.module.scss";
type Props = {
  days: string;
};
const CircleDay: React.FC<Props> = ({ days }) => {

  let backgroundColor = "white";
  let color = "#434950";

  if(days != null && Number(days)<=10 && Number(days)>5){
    backgroundColor = "orange";
    color = "white";
  }else if(days != null && Number(days)<=5){
    backgroundColor = "red";
    color = "white";
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.circle} style={{backgroundColor:backgroundColor}}>
        <span
          style={{
            fontWeight: "bold",
            color: color,
            fontSize: "30px",
            marginTop: "2%",
          }}
        >
          {days}
        </span>
      </div>
      <div className={styles.message}>
        <span>Dias para o fechamento</span>
      </div>
    </div>
  );
};

export default CircleDay;
