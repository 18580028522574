import {
  FETCH_USER_INFO,
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_INFO_FAIL,
  State,
  Action,
} from "./types";

const Initial_State: State = {
  fail: false,
  loading: false,
  userInfo: null,
};

const userReducer = (state = Initial_State, action: Action) => {
  switch (action.type) {
    case FETCH_USER_INFO:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload.data,
      };
    case FETCH_USER_INFO_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    default:
      return {
        ...state,
      };
  }
};

export default userReducer;
