export const PENDENCIES = "PENDENCIES";
export const PENDENCIES_FAIL = "PENDENCIES_FAIL";
export const PENDENCIES_SUCCESS = "PENDENCIES_SUCCESS";

export const CONTRACT_REQUEST = "CONTRACT_REQUEST";
export const CONTRACT_REQUEST_FAIL = "CONTRACT_REQUEST_FAIL";
export const CONTRACT_REQUEST_SUCCESS = "CONTRACT_REQUEST_SUCCESS";

export const SEND_CONTRACT_APPROVED = "SEND_CONTRACT_APPROVED";
export const SEND_CONTRACT_APPROVED_FAIL = "SEND_CONTRACT_APPROVED_FAIL";
export const SEND_CONTRACT_APPROVED_SUCCESS = "SEND_CONTRACT_APPROVED_SUCCESS";
export const RESET_CONTRACT_APPROVED_SUCCESS =
	'RESET_CONTRACT_APPROVED_SUCCESS';

export const CONTRACT_MONTH_INFO_REQUEST = 'CONTRACT_MONTH_INFO_REQUEST';
export const CONTRACT_MONTH_INFO_REQUEST_FAIL =
	'CONTRACT_MONTH_INFO_REQUEST_FAIL';
export const CONTRACT_MONTH_INFO_REQUEST_SUCCESS =
	'CONTRACT_MONTH_INFO_REQUEST_SUCCESS';

export type Constants =
	| typeof PENDENCIES
	| typeof PENDENCIES_FAIL
	| typeof PENDENCIES_SUCCESS
	| typeof SEND_CONTRACT_APPROVED
	| typeof SEND_CONTRACT_APPROVED_FAIL
	| typeof SEND_CONTRACT_APPROVED_SUCCESS
	| typeof RESET_CONTRACT_APPROVED_SUCCESS
	| typeof CONTRACT_REQUEST
	| typeof CONTRACT_REQUEST_FAIL
	| typeof CONTRACT_REQUEST_SUCCESS
	| typeof CONTRACT_MONTH_INFO_REQUEST
	| typeof CONTRACT_MONTH_INFO_REQUEST_FAIL
	| typeof CONTRACT_MONTH_INFO_REQUEST_SUCCESS;

export type PendenciesContractMonthInfo = {
  request_id: number;
  volume: number;
  reference_month: string;
  product_base: number;
  frame_quote: number;
  basis_quote: number;
  dolar_quote: number;
  cbot_quote: number;};

export type Pendencies = {
  id: number;
  open: boolean;
  end_date: string;
  accepted: boolean;
  begin_date: string;
  product_base: number;
  user_contracted: number;
  user_send_request: number;
  name: string;
  destiny_city: string;
  destiny_state: string;
  origin: {
    id: number;
    city: string;
    state: string;
    region: string;
    plant: string;
  }
};

export type PendenciesContractInfoMonth = {
  month: string;
  limit: number;
}

export type PendenciesContractInfo = {
    user_contracted: number;
    origin_base: number;
    destiny_city: string;
    destiny_state: string;
    product_base: number;
    fee: number;
    company_fee: number
    tax: string;
    total_volume: number;
    encargos: number;
    spread: number;
    factor: number;
    icms: number;
    juros: number;
    frete: string;
    volume_trigger: number;
    begin_date: string;
    end_date: string;
    currency: string;
    orders_size: number;
    month_limits: PendenciesContractInfoMonth[];
    equation: string;
    allowed_users: [  number ];
    id: number;
    user_send_request: number;
    open: boolean;
    accepted: boolean;
};

export type ResponseContract = {
  id: number;
  name: string;
  user_send_request: number;
  user_contracted: number;
  destiny_city: string;
  destiny_state: string;
  product_base: number;
  begin_date: string;
  end_date: string;
  open: boolean;
  accepted: boolean;
  origin: {
    id: number;
    city: string;
    state: string;
    region: string;
    plant: string;
  },
  contract_id: number;
  closed_date: Date;
}

export type State = {
  fail: boolean;
  loading: boolean;
  pending: Pendencies | [];
  pendenciesContractInfo: PendenciesContractInfo | null;
  pendenciesContractMonthInfo: PendenciesContractMonthInfo[] | [];
  responseContract: ResponseContract | null;
};

export type Action = {
  type: Constants;
  payload: {
    data:
    | Pendencies[]
    | PendenciesContractInfo
    | PendenciesContractMonthInfo[]
    | ResponseContract;
  };
};
