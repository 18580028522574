import {
	State,
	Action,
	SET_PRODUCT_HEADER,
	SET_CONTRACT_HEADER,
	INDEX_PRODUCT_SELECTED,
	SET_PRODUCT_HEADER_SUCCESS,
	SET_CONTRACT_HEADER_SUCCESS,
	INDEX_PRODUCT_SELECTED_SUCCESS,
	SET_CONTRACT_NOT_EXISTS_HEADER_SUCCESS,
} from './types';

const INITIAL_STATE: State = {
	color: '',
	loading: true,
	id_contract: undefined,
	selectedProductName: '',
	selectedContractName: '',
	selectedContractNameNotExists: '',
	indexProductSelected: 0,
};

const pushInformationHeader = (state = INITIAL_STATE, action: Action) => {
	switch (action.type) {
		case SET_PRODUCT_HEADER:
			return {
				...state,
				loading: true,
			};
		case SET_PRODUCT_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				color: action.payload.color,
				selectedProductName: action.payload.selectedProductName,
			};

		case SET_CONTRACT_HEADER:
			return {
				...state,
				loading: true,
			};
		case SET_CONTRACT_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				id_contract: action.payload.id_contract,
				selectedContractName: action.payload.selectedContractName,
			};
		case SET_CONTRACT_NOT_EXISTS_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				id_contract: action.payload.id_contract,
				selectedContractNameNotExists:
					action.payload.selectedContractNameNotExists,
			};

		case INDEX_PRODUCT_SELECTED:
			return {
				...state,
				loading: true,
			};
		case INDEX_PRODUCT_SELECTED_SUCCESS:
			return {
				...state,
				loading: false,
				indexProductSelected: action.payload.indexProductSelected,
			};

		default: {
			return {
				...state,
			};
		}
	}
};

export default pushInformationHeader;