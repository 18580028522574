import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import SiderComponent from "../../components/Sider";
import {
  Contracts,
  MonthSelected,
  ContractInfo,
  ContractsHistoryMonth,
  FixatedValues,
} from "../Main/Contracts/types";

import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../rootReducer";
import PendingOrders from "../../components/OrderComponents/PendingOrders";
import CreateOrder from "../../components/OrderComponents/CreateOrder";
import ActiveAlerts from "../../components/OrderComponents/ActiveAlerts";
import Alerts from "../../components/OrderComponents/Alerts";
import SwingData from "../../components/OrderComponents/SwingData";
import { fetchPendingOrders } from "./Orders/actions";
import { Order, CreatedOrderStateObject } from "./Orders/types";

import {
  selectedUserContractGetAll,
  selectedMonth,
  fetchFixated,
  getSelectedContractInfo,
  selectHoliday,
} from "../Main/Contracts/actions";
import SelectMonth from "../../components/SelectMonth";
import { fetchAlerts } from "./Alerts/actions";
import { AlertListResponse, AlertCreateResponse } from "./Alerts/types";
import { fetchStateSwing, fetchSwings } from "./Swing/action";
import { SwingState } from "./Swing/types";
import SwingAbove from "../../components/OrderComponents/SwingAbove/index";
import { fetchUserInfo } from "../Main/User/actions";
import { UserInfo } from "../Main/User/types";
import PageFooter from "../../components/Footer";

import redLabel from "../../assets/icons/redlabel-down.png";
import greenlabel from "../../assets/icons/greenlabel-up.png";

import styles from "./theme/index.module.scss";
import { getAllProducts } from "../../components/SelectFrame/modules/actions";
import { orderFetch } from "../../api/orderFetch";
import InformationHeader from "../../components/Frame/InformationHeader";

const OrderPage: React.FC = () => {
  const { Content } = Layout;
  const dispatch = useDispatch();

  const [successSwing, setSuccessSwing] = useState(true);

  const {
    contracts,
    monthSelected,
    contractInfo,
    contractshistoryMonth,
    fixatedValues,
	loading : loadingContracts,
  }: {
    contracts: Contracts[];
    monthSelected: MonthSelected;
    contractInfo: ContractInfo;
    fixatedValues: FixatedValues;
    contractshistoryMonth: ContractsHistoryMonth[];
	loading: boolean;
  } = useSelector((state: AppState) => state.contracts);

  const {
    alerts,
    alertData,
    loading: alertLoading,
    toggleAlertData,
    basisAlertResponse,
    loadingSwing,
    failSwing,
  }: {
    alerts: AlertListResponse[];
    alertData: AlertCreateResponse;
    toggleAlertData: AlertCreateResponse;
    swingResponse: string;
    loading: boolean;
    basisAlertResponse: { status: boolean };
    loadingSwing: boolean;
    failSwing: boolean;
  } = useSelector((state: AppState) => state.alerts);

  const {
    pendingOrders,
    loading: orderloading,
    createdOrder,
    cancelOrder,
  }: {
    pendingOrders: Order[] | null;
    createdOrder: CreatedOrderStateObject;
    loading: boolean;
    cancelOrder: boolean;
  } = useSelector((state: AppState) => state.orders);

  const {
    userInfo,
  }: {
    userInfo: UserInfo | null;
  } = useSelector((state: AppState) => state.user);

  const {
    swing,
    swingList,
    loading: swingLoading,
    fail: swingFail,
  }: SwingState = useSelector((state: AppState) => state.swings);

  const {
    color,
  } = useSelector((state: AppState) => state.infoHeader);

  const {
    contractsWhitProducts,
  } = useSelector((state: AppState) => state.product);

 useEffect(() => {
  if(userInfo !== null && userInfo?.accept_term){
    dispatch(getAllProducts());
  }
}, [dispatch, userInfo])

  let contract_id = localStorage.getItem("contractIdSelected");
	useEffect(() => {
		if (contract_id !== "") {
			dispatch(selectedUserContractGetAll(Number(contract_id), null));
			dispatch(getSelectedContractInfo(Number(contract_id)));
			dispatch(selectHoliday(Number(contract_id)));
		}
	}, [contract_id, dispatch]);

	useEffect(() => {
		if (monthSelected) dispatch(fetchAlerts(monthSelected?.selected));
	}, [contracts, monthSelected, dispatch]);

  useEffect(() => {
    if (contract_id)
      dispatch(fetchPendingOrders({ contract_id: parseInt(contract_id) }));
  }, [createdOrder.data, cancelOrder, dispatch, contract_id]);

  //FUNCAO QUE ATIVA O FUNCIONAMENTO DO SWR
  const { data } = orderFetch<Order[]>();

  useEffect(() => {
    if (contract_id){
      dispatch(fetchPendingOrders({ contract_id: parseInt(contract_id) }));
    }
  }, [contract_id, data, dispatch])


  useEffect(() => {
    if(monthSelected)
      dispatch(fetchAlerts(monthSelected?.selected));
  }, [alertData, monthSelected, dispatch, toggleAlertData]);

  useEffect(() => {
    if (monthSelected?.selected) {
      dispatch(fetchSwings({ month_id: monthSelected.selected }));
    }
  }, [dispatch, monthSelected]);

  useEffect(() => {
    if (monthSelected?.selected) {
      dispatch(fetchSwings({ month_id: monthSelected.selected }));
    }
  }, [monthSelected, dispatch]);
  
  useEffect(() => {
    if (loadingSwing) setSuccessSwing(true);
    if (!loadingSwing && successSwing && !failSwing) {
      if (contract_id) {
        dispatch(fetchStateSwing({ contract_id: parseInt(contract_id) }));
        setSuccessSwing(false);
      }
    }
  }, [contract_id, dispatch, failSwing, loadingSwing, monthSelected, successSwing]);

  useEffect(() => {
    if (monthSelected?.selected) {
      dispatch(fetchFixated(monthSelected?.selected));
    }
  }, [monthSelected, dispatch, createdOrder.data]);

  useEffect(() => {

    if (contractshistoryMonth && contractshistoryMonth.length && !monthSelected?.selected) {
      dispatch(
        selectedMonth(contractshistoryMonth[0].id, contractshistoryMonth)
      );
    }
  }, [contractshistoryMonth, dispatch, monthSelected]);

  useEffect(() => {
    if (monthSelected?.selected) {
      dispatch(fetchFixated(monthSelected?.selected));
    }
  }, [monthSelected, dispatch, createdOrder.data]);

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [basisAlertResponse, dispatch]);

  return (
		<Layout>
			<SiderComponent
				contracts={contractsWhitProducts}
				monthSelected={monthSelected}
				contractInfo={contractInfo}
			/>
			<Layout
				className="site-layout"
				style={{
					minHeight: '100vh',
					justifyContent: 'center',
					backgroundColor: '#0B1E22',
					left: 290,
					position: 'absolute',
					width: 'calc(100% - 290px)',
				}}
			>
				<div
					style={{
						width: 'calc(100% - 280px)',
						top: 6,
						position: 'fixed',
						zIndex: 999,
						background: 'none',
					}}
				>
					<InformationHeader
						style={{
							marginLeft: 40,
							marginRight: 43,
							background: `linear-gradient(90deg, #000000 27.71%, #${
								color || 'D2955D'
							} 75.91%)`,
						}}
					/>
				</div>
				<Content
					style={{
						margin: '16px',
						padding: 20,
						backgroundColor: '#0B1E22',
					}}
				>
					{!contractsWhitProducts || contractsWhitProducts?.length > 0 ? (
						<>
							{contractshistoryMonth && contractshistoryMonth.length === 0 ? (
								<>
									<div className={styles.frameBodyContainer}>
										<div className={styles.lineChartContainer}>
											<h1
												style={{
													fontFamily: 'helvetica',
													color: 'white',
													fontSize: 18,
													textAlign: 'center',
													marginTop: 60,
												}}
											>
												Nenhum mês futuro encontrado para este contrato!!!
											</h1>
										</div>
									</div>
								</>
							) : (
								<>
									<div className={styles.firstRow}>
										<div className={styles.pendingOrder}>
											<span
												className={styles.componentSpan}
												style={{ marginLeft: '2%' }}
											>
												SOLICITAÇÕES DE PRECIFICAÇÃO
											</span>
											<PendingOrders
												pendingOrders={pendingOrders}
												userInfo={userInfo}
												createdOrder={createdOrder}
												loading={orderloading}
											/>
										</div>
										<div className={styles.createOrder}>
											<span className={styles.componentSpan}>
												CRIAR SOLICITAÇÃO DE PRECIFICAÇÃO
											</span>
											<SelectMonth
												months={contractshistoryMonth}
												monthSelected={monthSelected}
											/>
											<CreateOrder
												contractInfo={contractInfo}
												monthSelected={monthSelected}
												createdOrder={createdOrder}
												loading={orderloading}
												fixatedValues={fixatedValues}
												loadingFixatedValues={loadingContracts}
											/>
										</div>
									</div>
									<div className={styles.secondRow}>
										<div className={styles.activeAlerts}>
											<span
												className={styles.componentSpan}
												style={{ marginLeft: '2%' }}
											>
												ALERTAS ATIVOS
											</span>
											<SelectMonth
												months={contractshistoryMonth}
												monthSelected={monthSelected}
											/>
											<ActiveAlerts alerts={alerts} loading={alertLoading} />
										</div>
										<div className={styles.alerts}>
											<span
												className={styles.componentSpan}
												style={{ marginLeft: '2%' }}
											>
												ALERTAS
											</span>
											<SelectMonth
												months={contractshistoryMonth}
												monthSelected={monthSelected}
											/>
											<Alerts monthSelected={monthSelected} />
										</div>
									</div>
									<div className={styles.thirdRow}>
										<div className={styles.swingData}>
											<SwingAbove
												contractshistoryMonth={contractshistoryMonth}
												monthSelected={monthSelected}
												userData={userInfo}
												selectedSwing={swing}
												loadingCheck={loadingSwing}
												contractId={Number(contract_id)}
											/>
											<SwingData
												swings={swingList}
												loading={swingLoading}
												fail={swingFail}
												hasMonth={!!monthSelected}
											/>
											{swingList !== null && swingList?.length > 0 ? (
												<div
													className={styles.legend}
													style={{ maxWidth: '46%' }}
												>
													<div className={styles.legendBox}>
														<span
															style={{
																display: 'inline-flex',
																color: 'white',
																fontWeight: 'bold',
															}}
														>
															<img
																src={greenlabel}
																alt="red label"
																style={{
																	height: 15,
																	width: 15,
																	marginTop: 3,
																	marginRight: 8,
																}}
															/>{' '}
															Nível Acima
														</span>
													</div>
													<div className={styles.legendBox}>
														<span
															style={{
																display: 'inline-flex',
																color: 'white',
																fontWeight: 'bold',
															}}
														>
															<img
																src={redLabel}
																alt="red label"
																style={{
																	height: 15,
																	width: 15,
																	marginTop: 3,
																	marginRight: 8,
																}}
															/>{' '}
															Nível Abaixo
														</span>
													</div>

													<span
														style={{
															fontFamily: 'helvetica',
															color: 'white',
															textAlign: 'justify',
															width: '100%',
															fontStyle: 'italic',
															fontWeight: 'lighter',
															fontSize: 11,
														}}
													>
														Os dados swing não são referências de preços no
														mercado futuro, referem-se apenas a cálculos
														estatísticos utilizando dados históricos do{' '}
														<a
															href="https://rapidapi.com/apidojo/api/yahoo-finance1/details"
															target="_blank"
															rel="noreferrer"
														>
															Yahoo Finance
														</a>{' '}
														e a cotação atual do Farelo de Soja da{' '}
														<a
															href="https://www.cma.com.br"
															target="_blank"
															rel="noreferrer"
														>
															CMA
														</a>
													</span>
												</div>
											) : null}
										</div>
									</div>
								</>
							)}
						</>
					) : (
						<p
							style={{
								fontSize: 18,
								color: '#fff',
								marginTop: 60,
								textAlign: 'center',
							}}
						>
							Nenhum contrato disponível
						</p>
					)}
				</Content>
			</Layout>
			<PageFooter />
		</Layout>
	);
};
export default OrderPage;
