import {
  FETCH_PENDING_ORDERS,
  FETCH_PENDING_ORDERS_SUCESS,
  FETCH_PENDING_ORDERS_FAIL,
  CREATE_ORDER,
  CREATE_ORDER_FAIL,
  CREATE_ORDER_SUCCESS,
  Action,
  OrderState,
  CANCEL_ORDER,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAIL,
  FETCH_ACCEPTED_ORDERS,
  FETCH_ACCEPTED_ORDERS_SUCCESS,
  FETCH_ACCEPTED_ORDERS_FAIL,
} from "./types";

const INITIAL_STATE: OrderState = {
  fail: false,
  loading: false,
  pendingOrders: null,
  createdOrder: {
    data: null,
    error: null,
    total: {
      cbot: null,
      basis: null,
      dolar: null,
    },
  },
  cancelOrder: null,
  acceptedOrders: null,
};

const orderReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case FETCH_PENDING_ORDERS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PENDING_ORDERS_SUCESS:
      return {
        ...state,
        loading: false,
        pendingOrders: action.payload.data,
        createdOrder: {
          total: {
            cbot: action.payload.total.cbot,
            basis: action.payload.total.basis,
            dolar: action.payload.total.dolar,
          },
        },
      };
    case FETCH_PENDING_ORDERS_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };

    case CREATE_ORDER:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        createdOrder: {
          data: action.payload.data,
          error: null,
        },
      };
    case CREATE_ORDER_FAIL:
      return {
				...state,
				loading: false,
				fail: true,
				createdOrder: {
					error: action.payload.error,
				},
			};
    case CANCEL_ORDER:
      return {
        ...state,
        loading: true,
        cancelOrder: false,
      };
    case CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        cancelOrder: action.payload.total,
      };
    case CANCEL_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        error: false,
        cancelOrder: false,
      };
    case FETCH_ACCEPTED_ORDERS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ACCEPTED_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        acceptedOrders: action.payload.data,
      };
    case FETCH_ACCEPTED_ORDERS_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    default:
      return { ...state };
  }
};
export default orderReducer;
