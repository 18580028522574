import React, { useState, useEffect } from "react";
//styles and antd imports
import { Checkbox, Spin } from "antd";
import styles from "./theme/index.module.scss";

import Modal from "antd/lib/modal/Modal";
import { useDispatch } from "react-redux";
import { notificationSeen } from "../../../modules/Notifications/actions";

type NotificationItemProp = {
  title: string;
  message: string;
  checked?: boolean;
  id: number;
  status: string;
  selectedAlerts: [Array<number>, (value: Array<number>) => void];
  selectAll: boolean;
  resetCheckbox: boolean;
  loading: boolean;
};

const NotificationItem: React.FC<NotificationItemProp> = ({
  title,
  message,
  checked = false,
  id,
  status,
  selectedAlerts,
  resetCheckbox,
  loading,
  selectAll,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsChecked(false);
  }, [loading]);

  if (loading) {
    return <Spin />;
  } else {
    return (
      <div className={styles.mainNotificationItemContainer}>
        <div className={styles.itemRow}>
          <Checkbox
            checked={resetCheckbox ? isChecked : selectAll ? true : isChecked}
            onChange={(e) => {
              setIsChecked(!isChecked);
              if (e.target.checked) {
                selectedAlerts[1]([...selectedAlerts[0], id]);
              } else {
                let removedItemArray = selectedAlerts[0].filter(
                  (item) => item !== id
                );
                selectedAlerts[1](removedItemArray);
              }
            }}
          />
          <div className={styles.notificationData}>
            <div className={styles.itemColumn}>
              <div
                onClick={() => {
                  setIsOpen(!isOpen);
                  dispatch(notificationSeen({ ids: [id] }));
                }}
                className={styles.title}
              >
                {title}
              </div>
            </div>
            {status === "seen" ? null : (
              <span className={styles.notSeen}></span>
            )}
          </div>
        </div>
        <Modal
          visible={isOpen}
          closable={true}
          onCancel={() => setIsOpen(!isOpen)}
          onOk={() => setIsOpen(!isOpen)}
          cancelButtonProps={{
            style: {
              display: "none",
            },
          }}
        >
          <div className={styles.modalContainer}>
            <div className={styles.modalTitle}>{title}</div>
            <div className={styles.modalMessage}>{message}</div>
          </div>
        </Modal>
      </div>
    );
  }
};
export default NotificationItem;
