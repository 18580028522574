import React from "react";

type Props = {
  height: number | string;
  width: number | string;
  month: string;
};
const MonthSpan: React.FC<Props> = ({ month, width, height }) => {
  return (
    <span
      style={{
        width,
        height,
        backgroundColor: "#66bc46",
        color: "#444d4f",
        fontWeight: "bold",
        borderRadius: "10px",
        padding: "10",
        textAlign: "center",
      }}
    >
      {month?.toUpperCase()}
    </span>
  );
};
export default MonthSpan;
