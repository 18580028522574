import {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_FAIL,
  FETCH_NOTIFICATIONS_SUCCESS,
  NOTIFICATION_SEEN,
  NOTIFICATION_SEEN_FAIL,
  NOTIFICATION_SEEN_SUCCESS,
  NOTIFICATION_DELETE,
  NOTIFICATION_DELETE_FAIL,
  NOTIFICATION_DELETE_SUCCESS,
  NotificationSeenPayload,
} from "./types";
import { Dispatch } from "redux";

import Api from "../../api/api";

export const notificationFetch = () => (dispatch: Dispatch) => {
	dispatch({
		type: FETCH_NOTIFICATIONS,
	});

	Api.get('user/notification/all', {
		headers: {
			authorization: `${localStorage.getItem('token')}`,
		},
	}).then((res) => {
		if (res?.status === 200 && res.data) {
			dispatch({
				type: FETCH_NOTIFICATIONS_SUCCESS,
				payload: {
					data: {
						notifications: res.data,
					},
				},
			});
		} else {
			dispatch({
				type: FETCH_NOTIFICATIONS_FAIL,
			});
		}
	});
};

export const notificationSeen =
	(payload: NotificationSeenPayload) => (dispatch: Dispatch) => {
		dispatch({
			type: NOTIFICATION_SEEN,
		});

		Api.post('user/notification/seen', payload, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		})
			.then((res) => {
				if (res.status === 200 && res.data) {
					dispatch({
						type: NOTIFICATION_SEEN_SUCCESS,
						payload: {
							data: res.data,
						},
					});
				} else {
					dispatch({
						type: NOTIFICATION_SEEN_FAIL,
					});
				}
			})
			.catch((err) =>
				dispatch({
					type: NOTIFICATION_SEEN_FAIL,
				})
			);
	};

export const notificationDelete =
	(payload: NotificationSeenPayload) => (dispatch: Dispatch) => {
		dispatch({ type: NOTIFICATION_DELETE });

		Api.post('user/notification/delete', payload, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		})
			.then((res) => {
				if (res.status === 200 && res.data) {
					dispatch({
						type: NOTIFICATION_DELETE_SUCCESS,
						payload: {
							data: res.data,
						},
					});
				} else {
					dispatch({
						type: NOTIFICATION_DELETE_FAIL,
					});
				}
			})
			.catch((err) =>
				dispatch({
					type: NOTIFICATION_DELETE_FAIL,
				})
			);
	};
