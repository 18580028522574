import React, { useEffect } from 'react';
import { Tooltip } from 'antd';

import { Contracts } from '../../../../modules/Main/Contracts/types';

import styles from '../../theme/index.module.scss';
import { setContractHeader } from '../modules/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';

type ProductProps = {
	loading: boolean;
	selectContract: (id: number) => void;
	contract: Contracts[] | null;
};

const SelectContract: React.FC<ProductProps> = ({
	loading,
	contract,
	selectContract,
}) => {
	const dispatch = useDispatch();

	const { id_contract, selectedContractName } = useSelector(
		(appState: AppState) => appState.infoHeader
	);

	useEffect(() => {
		if (id_contract)
			localStorage.setItem('contractIdSelected', id_contract.toString());
	}, [id_contract]);

	return (
		<>
			<div className={styles.chooseFrame}>
				<span>Escolha o frame:</span>
			</div>

			{!contract || contract?.length <= 0 ? (
				<p
					style={{
						color: '#000',
						fontSize: '12px',
						fontWeight: 300,
						textAlign: 'center',
					}}
				>
					Nenhum contrato encontrado para o produto selecionado
				</p>
			) : (
				<Tooltip
					title={
						<span
							style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: 12 }}
						>
							{selectedContractName || contract[0].name}
						</span>
					}
				>
					<select
						id="select"
						disabled={loading}
						className={styles.selectInput}
						onChange={(e) => {
							selectContract(Number(e.target.value));
							dispatch(
								setContractHeader(
									e.target.selectedOptions[0].text,
									Number(e.target.value)
								)
							);
						}}
					>
						{contract.map((values) => (
							<option
								key={values.id.toString()}
								value={values.id}
								selected={values.id === id_contract}
								className={styles.optionSelected}
							>
								{values.name}
							</option>
						))}
					</select>
				</Tooltip>
			)}
		</>
	);
};
export default SelectContract;
