import {
  CREATE_ALERT,
  CREATE_ALERT_FAIL,
  CREATE_ALERT_SUCCESS,
  FETCH_ALERTS,
  FETCH_ALERTS_SUCCESS,
  FETCH_ALERTS_FAIL,
  TOGGLE_ALERT,
  TOGGLE_ALERT_SUCCESS,
  TOGGLE_ALERT_FAIL,
  SWING_ALERT,
  SWING_ALERT_SUCCESS,
  SWING_ALERT_FAIL,
  BASIS_ALERT,
  BASIS_ALERT_FAIL,
  BASIS_ALERT_SUCCESS,
  SwingAlertPayload,
  BasisAlertPayload,
} from "./types";
import { Dispatch } from "redux";
import Api from "../../../api/api";
import { notification } from "antd";

export const createAlert = (payload: object) => (dispatch: Dispatch) => {
	dispatch({
		type: CREATE_ALERT,
	});

	function removeEmptyProperties(obj: any) {
		var propNames = Object.getOwnPropertyNames(obj);
		for (var i = 0; i < propNames.length; i++) {
			var propName = propNames[i];
			if (obj[propName] === 0 || obj[propName] === undefined) {
				delete obj[propName];
			}
		}
	}
	removeEmptyProperties(payload);

	const renderMessage = (message: string) => {
		switch (message) {
			case 'excess of volume':
				return 'Excesso de Volume';
			case 'order already exists':
				return 'Solicitação de precificação já existe';
			case 'Cannot create alerts for past months':
				return 'Não foi possível criar alerta para o mês passado';
			default:
				return 'Não foi possível criar o alerta, verifique os dados e tente novamente';
		}
	};

	Api.post('user/alert/create', payload, {
		headers: { authorization: `${localStorage.getItem('token')}` },
	}).then((res) => {
		if (res.status === 200 && res.data && res.data[0]?.success === true) {
			//console.log(res.data, 'data');

			dispatch({
				type: CREATE_ALERT_SUCCESS,
				payload: {
					data: res.data,
				},
			});

			notification.success({
				message: 'Alerta criado com sucesso',
				duration: 3,
			});
		} else {
			dispatch({
				type: CREATE_ALERT_FAIL,
			});

			notification.info({
				message: renderMessage(res.data?.error),
				duration: 5,
			});
		}
	});
};

export const fetchAlerts = (month_id: number) => (dispatch: Dispatch) => {
	let urlComplement = '';
	if (month_id) {
		urlComplement = '?month_id=' + month_id;
	}

	dispatch({
		type: FETCH_ALERTS,
	});
	Api.get('user/alert/list' + urlComplement, {
		headers: { authorization: `${localStorage.getItem('token')}` },
	})
		.then((res) => {
			if (res.status === 200 && res.data) {
				dispatch({
					type: FETCH_ALERTS_SUCCESS,
					payload: {
						data: res.data,
					},
				});
			} else {
				dispatch({
					type: FETCH_ALERTS_FAIL,
				});
			}
		})
		.catch((err) =>
			dispatch({
				type: FETCH_ALERTS_FAIL,
			})
		);
};

export const deleteAlert = (alert_id: number) => (dispatch: Dispatch) => {
	let dataDelete = {
		alert_id: alert_id,
	};

	dispatch({
		type: FETCH_ALERTS,
	});
	Api.post('user/alert/delete', dataDelete, {
		headers: { authorization: `${localStorage.getItem('token')}` },
	})
		.then((res) => {
			if (res.status === 200 && res.data) {
				dispatch({
					type: TOGGLE_ALERT_SUCCESS,
					payload: {
						data: res.data,
					},
				});
				notification.success({
					message: `Alerta excluído com sucesso`,
					duration: 3,
				});
			} else {
				dispatch({
					type: FETCH_ALERTS_FAIL,
				});
			}
		})
		.catch((err) =>
			dispatch({
				type: FETCH_ALERTS_FAIL,
			})
		);
};

export const toggleAlert =
	(alert_id: number, toggle: boolean) => (dispatch: Dispatch) => {
		dispatch({
			type: TOGGLE_ALERT,
		});

		Api.post(`user/alert/toggle?alert_id=${alert_id}&toggle=${toggle}`, null, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		})
			.then((res) => {
				if (res.status === 200 && res.data) {
					dispatch({
						type: TOGGLE_ALERT_SUCCESS,
						payload: {
							data: res.data,
						},
					});

					notification.success({
						message: toggle
							? 'Alerta ativado com sucesso'
							: 'Alerta desativado com sucesso',
						duration: 5,
					});
				} else {
					dispatch({
						type: TOGGLE_ALERT_FAIL,
					});
				}
			})
			.catch((err) =>
				dispatch({
					type: TOGGLE_ALERT_FAIL,
				})
			);
	};

export const swingAlertActivate =
	(payload: SwingAlertPayload) => (dispatch: Dispatch) => {
		dispatch({
			type: SWING_ALERT,
		});

		Api.post('user/contract/alert/swing', payload, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		})
			.then((res) => {
				if (res.status === 200 && res.data) {
					dispatch({
						type: SWING_ALERT_SUCCESS,
						payload: {
							data: res.data,
						},
					});
					notification.success({
						message: `Alerta ${
							payload.activate ? 'ativado' : 'desativado'
						} com sucesso`,
						duration: 5,
					});
				} else {
					dispatch({
						type: SWING_ALERT_FAIL,
					});
				}
			})
			.catch((err) =>
				dispatch({
					type: SWING_ALERT_FAIL,
				})
			);
	};

export const basisAlertActivate =
	(payload: BasisAlertPayload) => (dispatch: Dispatch) => {
		dispatch({
			type: BASIS_ALERT,
		});

		Api.post('user/alert/basis', payload, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		})
			.then((res) => {
				if (res.status === 200 && res.data) {
					dispatch({
						type: BASIS_ALERT_SUCCESS,
						payload: {
							data: res.data,
						},
					});
					notification.success({
						message: `Alerta ${
							payload.activate ? 'ativado' : 'desativado'
						} com sucesso`,
						duration: 5,
					});
				} else {
					dispatch({
						type: BASIS_ALERT_FAIL,
					});
				}
			})
			.catch((err) =>
				dispatch({
					type: BASIS_ALERT_FAIL,
				})
			);
	};
