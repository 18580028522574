export const FETCH_CONTRACTS = "FETCH_CONTRACTS";
export const FETCH_CONTRACTS_FAIL = "FETCH_CONTRACTS_FAIL";
export const FETCH_CONTRACTS_SUCCESS = "FETCH_CONTRACTS_SUCCESS";

export const LIST_CONTRACT = "LIST_CONTRACT";
export const LIST_CONTRACT_SUCCESS = "LIST_CONTRACT_SUCCESS";
export const LIST_CONTRACT_FAIL = "LIST_CONTRACT_FAIL";

export const SELECTED_CONTRACT_SUCCESS = "SELECTED_CONTRACT_SUCCESS";
export const SELECTED_CONTRACT_FAIL = "SELECTED_CONTRACT_FAIL";

export const SELECTED_MONTH = "SELECTED_MONTH";
export const SELECTED_MONTH_SUCCESS = "SELECTED_MONTH_SUCCESS";

export const FRAME_NEXT_PREVIEW = "FRAME_NEXT_PREVIEW";

export const FETCH_HOLIDAYS = "FETCH_HOLIDAYS";
export const SELECTED_HOLIDAYS_SUCCESS = "SELECTED_HOLIDAYS_SUCCESS";
export const SELECTED_HOLIDAYS_FAIL = "SELECTED_HOLIDAYS_FAIL";

export const DOWNLOADING_CONTRACT = "DOWNLOADING_CONTRACT";
export const CONTRACT_DOWNLOAD_SUCCESS = "CONTRACT_DOWNLOAD_SUCCESS";
export const CONTRACT_DOWNLOAD_FAIL = "CONTRACT_DOWNLOAD_FAIL";

export const FETCH_CONTRACT_INFO = "FETCH_CONTRACT_INFO";
export const FETCH_CONTRACT_INFO_FAIL = "FETCH_CONTRACT_INFO_FAIL";
export const FETCH_CONTRACT_INFO_SUCCESS = "FETCH_CONTRACT_INFO_SUCCESS";

export const FETCH_FIXATED = "FETCH_FIXATED";
export const FETCH_FIXATED_FAIL = "FETCH_FIXATED_FAIL";
export const FETCH_FIXATED_SUCCESS = "FETCH_FIXATED_SUCCESS";

export const FETCH_TRADINGVIEW = "FETCH_TRADINGVIEW";
export const FETCH_TRADINGVIEW_FAIL = "FETCH_TRADINGVIEW_FAIL";
export const FETCH_TRADINGVIEW_SUCCESS = "FETCH_TRADINGVIEW_SUCCESS";

export const OPTION_GRAPHIC_SELECTED = "OPTION_GRAPHIC_SELECTED";

export const CONFIG_MARKET_SUCCESS = "CONFIG_MARKET_SUCCESS";
export const CONFIG_MARKET_FAIL = "CONFIG_MARKET_FAIL";

export type Constants =
  | typeof FETCH_CONTRACTS
  | typeof FETCH_CONTRACTS_FAIL
  | typeof FETCH_CONTRACTS_SUCCESS
  | typeof LIST_CONTRACT
  | typeof LIST_CONTRACT_SUCCESS
  | typeof LIST_CONTRACT_FAIL
  | typeof SELECTED_CONTRACT_SUCCESS
  | typeof SELECTED_CONTRACT_FAIL
  | typeof FETCH_HOLIDAYS
  | typeof SELECTED_HOLIDAYS_SUCCESS
  | typeof SELECTED_HOLIDAYS_FAIL
  | typeof DOWNLOADING_CONTRACT
  | typeof CONTRACT_DOWNLOAD_SUCCESS
  | typeof CONTRACT_DOWNLOAD_FAIL
  | typeof SELECTED_MONTH
  | typeof SELECTED_MONTH_SUCCESS
  | typeof FRAME_NEXT_PREVIEW
  | typeof FETCH_CONTRACT_INFO
  | typeof FETCH_CONTRACT_INFO_FAIL
  | typeof FETCH_CONTRACT_INFO_SUCCESS
  | typeof FETCH_FIXATED
  | typeof FETCH_FIXATED_FAIL
  | typeof FETCH_FIXATED_SUCCESS
  | typeof FETCH_TRADINGVIEW
  | typeof FETCH_TRADINGVIEW_FAIL
  | typeof FETCH_TRADINGVIEW_SUCCESS
  | typeof OPTION_GRAPHIC_SELECTED
  | typeof CONFIG_MARKET_SUCCESS
  | typeof CONFIG_MARKET_FAIL;

export type Contracts = {
  id: number;
  name: string;
};

export type Alerts = {
  id: number;
  month_base: number;
  user_base: number;
  type: string;
  trigger: number;
  direction: string;
  is_active: boolean;
};

export type ContractsHistoryMonth = {
  id: number;
  base_contract: number;
  reference_month: string;
  cbot_volume: number;
  dolar_volume: number;
  basis_volume: number;
  frame_quote: number;
  basis_quote: number;
  open_closed_cbot: boolean;
  open_closed_basis: boolean;
  open_closed_dolar: boolean;
  dolar_quote: number;
  cbot_quote: number;
  fixated_cbot: number;
  fixated_dolar: number;
  fixated_basis: number;
  spot_price: number,
  spot_volume: number,
  spot_fixated: number,
  end_date: string;
  frete: string;
  days_to_end: number;
  spot_end_date: number;
  tax: string;
  alerts: Alerts[]
};

export type ContractSelectedMonth = {
  id: number;
  name: string;
  user_contracted: number;
  company_base: number;
  origin_city: string;
  destiny_city: string;
  fee: string;
  tax: string;
  encargos: number;
  cbot_rule: string;
  premio_rule: string;
  dolar_rule: string;
  frete: string;
  volume_trigger: number;
  end_date: string;
  currency: string;
  orders_size: string;
  equation: string;
  open_closed_cbot: boolean;
  open_closed_basis: boolean;
  open_closed_dolar: boolean;
};
export type ContractInfo = {
  id: string;
  name: string;
  user_contracted: number;
  company_base: number;
  origin_city: string;
  destiny_city: string;
  fee: string;
  tax: string;
  icms: string;
  juros: string;
  factor: string;
  spread: string;
  encargos: number;
  cbot_rule: string;
  premio_rule: string;
  dolar_rule: string;
  frete: string;
  volume_trigger: number;
  end_date: string;
  currency: string;
  orders_size: string;
  equation: string;
};
export type MonthSelected = {
  selected: number;
  contractSelected: ContractsHistoryMonth;
};

export type OptionGraphicSelected = {
  optionSelected: 'cbot' | 'basis' | 'dolar' | '';
  cbot: boolean;
  basis: boolean;
  dolar: boolean;
};

export type FrameNextPreviewSelected = {
  prevActive: boolean;
  nowActive: boolean;
};

export type FixatedValues = {
  id: number;
  base_month: number;
  fixated_cbot: number;
  fixated_dolar: number;
  fixated_basis: number;
  end_date: string;
};

export type TradingView = {
  url_analise: string;
  url_grafico: string;
  url_overview: string;
  url_urd_brl: string;
};

export type State = {
  contractDownloading: boolean;
  holidaysLoading: boolean;
  loading: boolean;
  false: boolean;
  monthSelected: MonthSelected | null;
  frameNextPreviewSelected: FrameNextPreviewSelected | null;
  optionGraphicSelected: OptionGraphicSelected | null;
  contracts: Contracts[] | null;
  contractshistoryMonth: ContractsHistoryMonth[] | ContractsHistoryMonth | null;
  contractshistoryMonthSelected: ContractsHistoryMonth | null;
  contractSelectedMonth: ContractSelectedMonth | null;
  monthSelectLoading: boolean;
  contractInfo: ContractInfo[] | null;
  fixatedValues: FixatedValues | null;
  monthHolidays: MonthHolidays[] | null;
  tradingView: TradingView | null;
  configMarket: ConfigMarket | null;
};
export type Action = {
  type: Constants;
  payload: {
    data:
      | Contracts[]
      | ContractsHistoryMonth[]
      | ContractSelectedMonth[]
      | ContractsHistoryMonth
      | ContractInfo[]
      | FixatedValues
      | MonthHolidays[]
      | OptionGraphicSelected
      | TradingView
      | ConfigMarket;
  };
};

export type MonthHolidays = {
  holidays: Holidays[];
  reference_month: string;
  id: number;
  base_contract: number;
  base_month: number;
  cbot_volume: number;
  dolar_volume: number;
  basis_volume: number;
  triggered_volume: number;
  trigger_activated: number;
  month_date: string;
};

export type Holidays = {
  id: number;
  base_month_quote: number;
  name: string;
  variable: string;
  day: string;
  country: string;
};

export type ConfigMarket = {
  id: Number;
  cbot_open: string;
  cbot_close: string;
  basis_open: string;
  basis_close: string;
  dolar_open: string;
  dolar_close: string;
  spot_open: string;
  spot_close: string;
  created_date: string;
  modification_date: string;
}
