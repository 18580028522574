export const AUTH = "AUTH";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_SUCCESS = "AUTH_SUCCESS";

export type LoginPayload = {
  email: String;
  password: String;
};
export type AuthResponse = {
  message: String;
  token: String;
  email: String;
  name: String;
  error: String;
  detail: String;
  accessToken: String;
};

export type State = {
  fail: boolean;
  loading: boolean;
  auth: AuthResponse[];
};

export type asyncConstants =
  | typeof AUTH
  | typeof AUTH_FAIL
  | typeof AUTH_SUCCESS;

export type Action = {
  type: asyncConstants;
  payload: {
    data: AuthResponse[];
  };
};
