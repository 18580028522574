import React, { useState, useEffect } from "react";

//styling and antd
import styles from "./theme/index.module.scss";
import "./theme/index.css";
import { UserOutlined, LockFilled } from "@ant-design/icons";
import { Input, Button, notification } from "antd";

//routes
import { RouteComponentProps } from "react-router-dom";

//redux imports
import { useDispatch, useSelector } from "react-redux";
import { authUser } from "./actions";


import logoFoot from "../../assets/icons/logoFootP.png";
import welcome from "../../assets/icons/welcome.svg";
import cargilFrameImg from "../../assets/icons/cargill-frame-interface.png"


//utils
import { pressEnter } from "../../utils/onKeyPress";
import { AppState } from "../../rootReducer";
import Loading from "../../components/Loading";
const Login: React.FC<RouteComponentProps> = (props) => {
  const [email, setEmail] = useState<String>("");
  const [password, setPassword] = useState<String>("");
  const { auth, fail, loading } = useSelector((state: AppState) => state.auth);

  const dispatch = useDispatch();
  /*useEffect(() => {
    let teste = 0;
    if (1 === teste) {
      props.history.replace("/main");
    } else {
      props.history.replace("/");
    }
  }, []);*/

  var loginButton;
  if (!loading) {
    loginButton = (
      <Button
       type="primary"
       size="large"
       className={styles.loginButton}
       style={{
         height: 50,
         width: 150,
         backgroundColor: "rgba(87, 184, 70, 0.8)",
         border: "none",
        }}
       onClick={() => handleRedirect()}
       onKeyPress={() => handleRedirect}
      
        /*href={`https://dev-2376773.okta.com/oauth2/default/v1/authorize?client_id=0oa2apxpnbKL3MS225d6&response_type=token&response_mode=fragment&scope=openid&redirect_uri=http://localhost:3000/login&state=1654as&nonce=1as45`}
        style={{
          height: 50,
          width: 150,
          backgroundColor: "rgba(87, 184, 70, 0.8)",
          border: "none",
        }} */
      >
        <span>Login</span>
      </Button>
    );
  } else {
    loginButton = <Loading />;
  }

  useEffect(() => {
    //console.log(process.env)
    if (localStorage.getItem("token")) {
      props.history.replace("/main");
    } else {
      return;
    }
  }, [props.history]);
  const handleRedirect = () => {
    window.location.href = `${process.env.REACT_APP_ISSUER}v1/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=token&response_mode=fragment&scope=openid&redirect_uri=${window.location.protocol + "//" + window.location.host + "/login"}&state=1654as&nonce=1as45`;
  }

  const handleSubmit = () => {
    if (email === "" || password === "") {
      notification.warn({
        message: "Preencha os campos.",
        placement: "topRight",
      });
    } else {
      let payload = {
        email: email,
        password: password,
      };
      dispatch(authUser(payload, props));
    }
  };

  return (
    <div className={styles.pageContainer}>
      <video autoPlay muted loop className={styles.video} preload="auto">
        <source
          src="https://cargill-server.s3-sa-east-1.amazonaws.com/cargill.mp4"
          type="video/mp4"
        ></source>
      </video>
      <div className={styles.loginContainer}>
        {/*<div className={styles.title}>
          <h1 style={{ fontFamily: 'helvetica'}}>LOGIN</h1>
        </div>*/}
        <div className={styles.inputContainer}>
        </div>
        <div className={styles.buttonContainer}>{loginButton}</div>

        
      </div>
      <div className={styles.logoContainer}>
        {/*<span style={{color: 'white', zIndex:999, fontSize: 50, position: 'fixed', top:'33%', left:'10%', fontFamily: 'helvetica', fontWeight: "normal"}}>
          Bem-vindo ao 
          </span>
          <span style={{color: 'white', zIndex:999, fontSize: 112, position: 'fixed', top:'39%', left:'10%', fontFamily: 'helvetica'}}>
          Cargill Frame!
          </span>*/}

        {/*<img
          src="https://cargill-server.s3-sa-east-1.amazonaws.com/logo.svg"
          alt="Frame-Logo"
        ></img>*/}

        <img style={{position: 'absolute', left:'9%', top:'40%', width:'25%', height: '18%', zIndex:1000}}
          src={welcome}
          alt="Frame-Logo"
        ></img>
        <img  style={{position: 'absolute', left:'9%', top: '46%', height: '16%', width: '45%'}}
          src={cargilFrameImg}></img>
      </div>
      <div style={{backgroundColor: "white", position: "fixed", height: 85, bottom: -1, width: '100%', zIndex:1000}}>
        <div style={{position: 'relative',
                  padding: '30px',
                  width: '50vh',
                  minWidth: '50vh',
                  marginLeft: '65%'
                  }}>
          <div style={{justifyContent: 'center',
                      alignItems: 'center',
                  display: 'flex'}}>
                    <img src={logoFoot} alt="logo" style={{width: 120, top: '8%', position: 'absolute'}} />
                    </div>
        </div>
          
      </div>
    </div>
  );
};

export default Login;
