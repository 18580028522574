export const SEND_CREATE_NEGOTIATION_SPOT = "SEND_CREATE_NEGOTIATION_SPOT";
export const SEND_CREATE_NEGOTIATION_SPOT_FAIL = "SEND_CREATE_NEGOTIATION_SPOT_FAIL";
export const SEND_CREATE_NEGOTIATION_SPOT_SUCCESS = "SEND_CREATE_NEGOTIATION_SPOT_SUCCESS";
export const SELECTED_MONTH_GRAPHIC = "SELECTED_MONTH_GRAPHIC";
export const VALUE_QUOTE_SELECTED = "VALUE_QUOTE_SELECTED";
export const VALUE_ID_MONTH_SELECTED = "VALUE_ID_MONTH_SELECTED";

export type Constants =
  | typeof SEND_CREATE_NEGOTIATION_SPOT
  | typeof SEND_CREATE_NEGOTIATION_SPOT_FAIL
  | typeof SEND_CREATE_NEGOTIATION_SPOT_SUCCESS
  | typeof VALUE_QUOTE_SELECTED
  | typeof VALUE_ID_MONTH_SELECTED
  | typeof SELECTED_MONTH_GRAPHIC;

  export type CreateOrder = {
    month_id: number,
    price: number,
    volume: number,
    force_create: true
  };

  export type OrderCreatedResponse = {
    id: number;
    base_month: number;
    base_contract: number;
    base_user: number;
    base_company: number;
    product_base: number;
    variable: string;
    fixated: number;
    fixated_remain: number;
    price: number;
    accepted: boolean;
    partial: boolean;
    date: Date;
    valid_until: number;
    expiration_date: Date;
    reference_month: string;
    cancel_user: boolean;
    base_user_send_order: number;
  };

  export type State = {
    fail: boolean;
    loading: boolean;
    valueQuote?: number;
    selectedIdMonth?: number;
    monthSelectedGraphic: string;
    orderCreateResponse: OrderCreatedResponse| null;
  };

  export type Action = {
    type: Constants;
    payload: {
      valueQuote?: number;
      selectedIdMonth?: number;
      monthSelectedGraphic: string;
      data: 
      | OrderCreatedResponse;
    }
  }