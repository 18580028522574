export const AUTH_REVOKE = "AUTH_REVOKE";
export const AUTH_REVOKE_FAIL = "AUTH_REVOKE_FAIL";
export const AUTH_REVOKE_SUCCESS = "AUTH_REVOKE_SUCCESS";

export type State = {
  failRevoke: boolean;
  loadingRevoke: boolean;
};

export type asyncConstants =
  | typeof AUTH_REVOKE
  | typeof AUTH_REVOKE_FAIL
  | typeof AUTH_REVOKE_SUCCESS;

export type Action = {
  type: asyncConstants;
};
