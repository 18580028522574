import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { hotjar } from "react-hotjar";
import { useDispatch, useSelector } from "react-redux";
import { CalculatorOutlined, CloseOutlined } from "@ant-design/icons";

import { AppState } from "../../../rootReducer";
import {
  selectedUserContractGetAll,
} from "../../../modules/Main/Contracts/actions";
import {
  ContractsHistoryMonth,
  MonthSelected,
  FrameNextPreviewSelected,
  ContractInfo,
} from "../../../modules/Main/Contracts/types";
import styles from "./../theme/index.module.scss";
import QuickSimulation from "../../QuickSimulation";

type Props = {
  contractshistoryMonth: ContractsHistoryMonth[];
  monthSelected: MonthSelected;
  frameNextPreviewSelected: FrameNextPreviewSelected;
  contractInfo: ContractInfo;
};

const FrameTop: React.FC<Props> = () => {
  const {
    contractInfo,
    frameNextPreviewSelected,
  }: {
    contractInfo: ContractInfo;
    frameNextPreviewSelected: FrameNextPreviewSelected;
  } = useSelector((state: AppState) => state.contracts);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if(isOpen){
      setTimeout(() => {
        hotjar.initialize(2030290, 6, "loadingPopup");
        localStorage.setItem('changeDataGlobalHome', 'false');
      }, 1000);
    }else{
      hotjar.initialize(2030290, 6, "loadingContracts");
      localStorage.setItem('changeDataGlobalHome', 'true');
    }
  }, [isOpen]);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   const framePreviewSelected = localStorage.getItem('frameNextPreviewSelected');
  //   if(framePreviewSelected && contractInfo) {
  //     // localStorage.setItem('frameNextPreviewSelected', 'true');
  //     dispatch(
  //       selectedUserContractGetAll(
  //         Number(contractInfo?.id),
  //         framePreviewSelected
  //       )
  //     );
  //   }

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch])
  
  const handleSelect = (option: any) => {
    localStorage.setItem('frameNextPreviewSelected', option+'');
    dispatch(
      selectedUserContractGetAll(
        Number(contractInfo?.id),
        option
      )
    );
  };


  return (
    <>
    <div className={styles.frameTop}>
        {(window.location.href).indexOf("main") > 0 ? (
          <span className={styles.frameSpan}>
            CALCULADORA
            {contractInfo ? (
              <CalculatorOutlined
                onClick={() => setIsOpen(!isOpen)}
                style={{
                  fontSize: "25px",
                  color: "#fff",
                  marginLeft: "5%",
                  alignItems: "center",
                }}
              />
            ) : null}
          </span>
        ) : null}

        <div className={styles.quickSimulationModalContainer}>
          <Modal
            visible={isOpen}
            okButtonProps={{ color: "#66bc46" }}
            okText="Fechar"
            onCancel={() => setIsOpen(!isOpen)}
            onOk={() => setIsOpen(!isOpen)}
            closeIcon={
              <CloseOutlined
                style={{
                  color: "#fff",
                  marginRight: "50px",
                  marginTop: 30,
                }}
              />
            }
            cancelButtonProps={{ style: { display: "none" } }}
          >
            <QuickSimulation />
          </Modal>
        </div>
        {contractInfo && (
          <div className={styles.containerButton}>
            {window.location.pathname === '/main' &&  (
              <Button
                shape="round"
                size="middle"
                style={{
                  border: "none",
                  width: 130,
                  marginRight: 20,
                  backgroundColor: '#51584C',
                  fontFamily: "helvetica",
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#fff',
                }}
                onClick={() => window.scroll(0, 1120) }
              >
              
              Fazer simulação
            </Button>
            )}

          <div className={styles.selectActual}>
            <Button
              shape="round"
              size="middle"
              style={{
                border: "none",
                width: 110,
                marginRight: -15,
                backgroundColor:
                  frameNextPreviewSelected?.prevActive
                    ? "#66bc46"
                    : "transparent",
                color: "white",
                height: "100%",
                fontFamily: "helvetica",
              }}
              onClick={() => handleSelect(false) }

            >
              Anterior
            </Button>
            <Button
              shape="round"
              size="middle"
              style={{
                border: "none",
                width: 110,
                marginLeft: -10,
                backgroundColor:
                  frameNextPreviewSelected?.nowActive
                    ? "#66bc46"
                    : "transparent",
                color: "white",
                fontFamily: "helvetica",
              }}
              onClick={() => handleSelect(true) }
            >
              Atual
            </Button>
          </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FrameTop;
