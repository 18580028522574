import orderSWR from 'swr'
import { Order } from '../modules/Order/Orders/types';
import api from './api';

export function orderFetch<Data = Order[], Error = any>() {
	let urlSend = `/user/contract/order/pendents/getall?contract_id=${localStorage.getItem(
		'contractIdSelected'
	)}&next=true`;

	const { data, error, mutate } = orderSWR<Order[], Error>(
		urlSend,
		async (urlSend) => {
			const response = await api.get(urlSend, {
				headers: {
					authorization: `${localStorage.getItem('token')}`,
				},
			});

			return response.data;
		},
		{ refreshInterval: 10000 }
	);

	return { data, error, mutate };
}

export function orderAcceptedFetch<Data = Order[], Error = any>() {
	let month_id_url = '';
	if (
		localStorage.getItem('monthSelected') !== '' &&
		localStorage.getItem('monthSelected') !== undefined &&
		localStorage.getItem('monthSelected') !== '0'
	)
		month_id_url = '&month_id=' + localStorage.getItem('monthSelected');

	let urlSend =
		'/user/contract/order/concluded/getall?contract_id=' +
		localStorage.getItem('contractIdSelected');

	let variableUrl = '';
	if (
		localStorage.getItem('variable') != null &&
		localStorage.getItem('variable') !== ''
	)
		variableUrl = '&variable=' + localStorage.getItem('variable');

	const { data, error, mutate } = orderSWR<Order[], Error>(
		urlSend + month_id_url + variableUrl,
		async (urlSend) => {
			const response = await api.get(urlSend, {
				headers: {
					authorization: `${localStorage.getItem('token')}`,
				},
			});

			return response.data;
		},
		{ refreshInterval: 10000 }
	);

	return { data, error, mutate };
}