import React, { useEffect } from "react";
import { Affix, Popover, Button } from "antd";
import { BellFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "../../rootReducer";
import NotificationList from "./NotificationList";
import { notificationFetch } from "../../modules/Notifications/actions";
import { NotificationSeenPayload } from "../../modules/Notifications/types";

import './overrides.css';

const NotificationComponent: React.FC = () => {
  const dispatch = useDispatch();

  const {
    seenNotification,
    deletedNotification,
  }: {
    seenNotification: NotificationSeenPayload;
    deletedNotification: NotificationSeenPayload;
  } = useSelector((state: AppState) => state.notifications);

  let seenNotificationsId = seenNotification?.ids;
  let deletedNotificationsID = deletedNotification?.ids;

  useEffect(() => {
    dispatch(notificationFetch());
  }, [dispatch, seenNotificationsId, deletedNotificationsID]);

  return (
    <Affix offsetTop={10} onChange={() => {}}  style={{
      position: 'absolute',
      right: 50,
      top: 0,
      bottom: 5,
    }}>
      <Popover
        trigger="hover"
        title="Notificações"
        placement="bottomLeft"
        content={NotificationList}
      >
        <Button shape="circle" size="large" style={{width: "45px", height: "45px"}}>
          <BellFilled style={{color: "#ff9933", fontSize: '30px'}} />
        </Button>
      </Popover>
    </Affix>
  );
};
export default NotificationComponent;
