export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_FAIL = "FETCH_NOTIFICATIONS_FAIL";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";

export const NOTIFICATION_SEEN = "NOTIFICATION_SEEN";
export const NOTIFICATION_SEEN_FAIL = "NOTIFICATION_SEEN_FAIL";
export const NOTIFICATION_SEEN_SUCCESS = "NOTIFICATION_SEEN_SUCCESS";

export const NOTIFICATION_DELETE = "NOTIFICATION_DELETE";
export const NOTIFICATION_DELETE_FAIL = "NOTIFICATION_DELETE_FAIL";
export const NOTIFICATION_DELETE_SUCCESS = "NOTIFICATION_DELETE_SUCCESS";

export type Action = {
  type: Constants;
  payload: {
    data: NotificationData | null | undefined;
  };
};

export type Notification = {
  id: number;
  base_user: number;
  notification_type: string;
  date: string;
  status: string;
  message: string;
  seen: string;
};

export type Constants =
  | typeof FETCH_NOTIFICATIONS
  | typeof FETCH_NOTIFICATIONS_FAIL
  | typeof FETCH_NOTIFICATIONS_SUCCESS
  | typeof NOTIFICATION_SEEN
  | typeof NOTIFICATION_SEEN_FAIL
  | typeof NOTIFICATION_SEEN_SUCCESS
  | typeof NOTIFICATION_DELETE
  | typeof NOTIFICATION_DELETE_FAIL
  | typeof NOTIFICATION_DELETE_SUCCESS;

export type NotificationData = {
  notifications: Notification[] | null;
};

export type NotificationSeenPayload = {
  ids: Array<number>;
};

export type State = {
  fail: boolean;
  loading: boolean;
  notificationsList: NotificationData | null;
  seenNotification: NotificationSeenPayload | null;
  deletedNotification: NotificationSeenPayload | null;
};
