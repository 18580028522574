import React, { useEffect } from "react";
import { Layout } from 'antd'
import { useDispatch, useSelector } from "react-redux";

import PageFooter from "../../components/Footer";
import SiderComponent from "../../components/Sider";
import ContractsRow from "./components/ContractsRow";
import TopRow from "../../components/OrderComponents/TopRow";
import NotificationComponent from "../../components/NotificationBell";

import pendenciesSvg from '../../assets/icons/pendigs_icon_header.svg'

import { AppState } from "../../rootReducer";
import { fetchAllContractsPendencies } from "./modules/actions";
import { getAllProducts } from "../../components/SelectFrame/modules/actions";

import { ContractInfo, MonthSelected } from "../Main/Contracts/types";
import { Pendencies as PendenciesType } from "./modules/types";

import './theme/overrides.css'
import { getSelectedContractInfo } from '../Main/Contracts/actions';
import { fetchUserInfo } from '../Main/User/actions';
import { fetchAlerts } from '../Order/Alerts/actions';

const Pendencies: React.FC = () => {
	const { Content, Header } = Layout;

	const dispatch = useDispatch();

	const items = ['Contratos', 'Origem', 'Destino', 'Produto', ''];

	const { contractsWhitProducts } = useSelector(
		(appState: AppState) => appState.product
	);

	const { userInfo } = useSelector((appState: AppState) => appState.user);

	const {
		pending,
	}: {
		pending: PendenciesType[];
	} = useSelector((appState: AppState) => appState.contractsPending);

	const {
		monthSelected,
		contractInfo,
	}: {
		monthSelected: MonthSelected;
		contractInfo: ContractInfo;
	} = useSelector((state: AppState) => state.contracts);

	let contractIdSelectedStorage = localStorage.getItem('contractIdSelected');

	useEffect(() => {
		dispatch(getSelectedContractInfo(Number(contractIdSelectedStorage)));
	}, [contractIdSelectedStorage, dispatch]);

	useEffect(() => {
		dispatch(fetchUserInfo());
	}, [dispatch]);

	useEffect(() => {
		if (userInfo?.accept_term) {
			dispatch(getAllProducts());
			dispatch(fetchAllContractsPendencies());
		}
	}, [dispatch, userInfo]);

	const modifiedData = pending?.filter((value) => value.accepted !== true);

	useEffect(() => {
		if (monthSelected) dispatch(fetchAlerts(monthSelected?.selected));
	}, [monthSelected, dispatch]);

	return (
		<Layout>
			<SiderComponent
				contracts={contractsWhitProducts}
				monthSelected={monthSelected}
				contractInfo={contractInfo}
			/>

			<Layout
				className="site-layout"
				style={{
					minHeight: '100vh',
					justifyContent: 'center',
					backgroundColor: '#0B1E22',
					left: 290,
					position: 'absolute',
					width: 'calc(100% - 290px)',
				}}
			>
				<div
					style={{
						width: 'calc(100% - 320px)',
						top: 6,
						position: 'fixed',
						zIndex: 999,
						background: 'none',
					}}
				>
					<Header style={{ marginLeft: 15 }}>
						<p>CONTRATOS PENDENTES</p>
						<img src={pendenciesSvg} alt="pendencies" className="img-header" />

						<NotificationComponent />
					</Header>
				</div>
				<Content
					style={{
						padding: 20,
						marginTop: '90px',
						marginBottom: '50px',
						marginLeft: '16px',
						marginRight: '30px',
						backgroundColor: '#07191d',
						justifyContent: 'space-around',
					}}
				>
					{modifiedData && modifiedData?.length > 0 ? (
						<>
							<TopRow topItems={items} />
							<div>
								{modifiedData?.map((item: PendenciesType) => (
									<ContractsRow pendencies={item} key={item?.id.toString()} />
								))}
							</div>
						</>
					) : (
						<p
							style={{
								fontSize: 18,
								color: '#fff',
								marginTop: 60,
								textAlign: 'center',
							}}
						>
							Nenhum contrato disponível para aprovação
						</p>
					)}
				</Content>
			</Layout>
			<PageFooter />
		</Layout>
	);
};;

export default Pendencies;
