export const FETCH_PENDING_ORDERS = "FETCH_PENDING_ORDERS";
export const FETCH_PENDING_ORDERS_FAIL = "FETCH_PENDING_ORDERS_FAIL";
export const FETCH_PENDING_ORDERS_SUCESS = "FETCH_PENDING_ORDERS_SUCESS";

export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";

export const CANCEL_ORDER = "CANCEL_ORDER";
export const CANCEL_ORDER_FAIL = "CANCEL_ORDER_FAIL";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";

export const FETCH_ACCEPTED_ORDERS = "FETCH_ACCEPTED_ORDERS";
export const FETCH_ACCEPTED_ORDERS_FAIL = "FETCH_ACCEPTED_ORDERS_FAIL";
export const FETCH_ACCEPTED_ORDERS_SUCCESS = "FETCH_ACCEPTED_ORDERS_SUCCESS";

export type Constants =
  | typeof FETCH_PENDING_ORDERS
  | typeof FETCH_PENDING_ORDERS_SUCESS
  | typeof FETCH_PENDING_ORDERS_FAIL
  | typeof CREATE_ORDER
  | typeof CREATE_ORDER_FAIL
  | typeof CREATE_ORDER_SUCCESS
  | typeof CANCEL_ORDER
  | typeof CANCEL_ORDER_FAIL
  | typeof CANCEL_ORDER_SUCCESS
  | typeof FETCH_ACCEPTED_ORDERS
  | typeof FETCH_ACCEPTED_ORDERS_FAIL
  | typeof FETCH_ACCEPTED_ORDERS_SUCCESS;

export type Order = {
  id: number;
  base_month: number;
  base_contract: number;
  base_user: number;
  base_company: number;
  fixated: number;
  fixated_remain: number;
  variable: string;
  price: number;
  accepted: boolean;
  date: string;
  valid_until: number;
  expiration_date: string;
  reference_month: string;
  month_reference: string;
  expiry_date: string;
  volume: number;
  partial: string;
  base_user_send_order: number;
};

export type CreateOrderPayload = {
  month_id: number;
  cbot: number;
  cbot_vol: number;
  basis: number;
  basis_vol: number;
  dolar: number;
  dolar_vol: number;
  force_create: boolean;
  valid_until: number;
  volume_trigger: boolean;
};

export type CreateOrderResponse = {
  id: number;
  base_month: number;
  base_contract: number;
  base_user: number;
  base_company: number;
  fixated_cbot: number;
  fixated_dolar: number;
  fixated_basis: number;
  cbot_price: number;
  basis_price: number;
  dolar_price: number;
  accepted: true;
  date: string;
  valid_until: number;
  expiration_date: string;
  reference_month: string;
};

export type CancelOrderPayload = {
  order_id: number;
};
export type ConfirmOrderPayload = {
  order_id: number;
  accepted: boolean;
};

export type CreatedOrderStateObject = {
  data: CreateOrderResponse | null;
  total: {
    cbot: number | null;
    basis: number | null;
    dolar: number | null;
  };
  error: string | null;
};
export type TotalI = {
  cbot: number | null;
  basis: number | null;
  dolar: number | null;
};

export type CancelOrderResponse = {
  id: number;
  base_month: number;
  base_contract: number;
  base_user: number;
  base_company: number;
  variable: string;
  fixated: number;
  price: number;
  accepted: false;
  date: string;
  valid_until: number;
  expiration_date: string;
  reference_month: string;
};

export type OrderState = {
  fail: boolean;
  loading: boolean;
  pendingOrders: Order[] | null;
  createdOrder: CreatedOrderStateObject;
  cancelOrder: CancelOrderResponse[] | null;
  acceptedOrders: CreatedOrderStateObject | null;
};

export type Action = {
  type: Constants;
  payload: CreatedOrderStateObject;
};
