import {
  FETCH_PRODUCTS,
  FETCH_CONTRACTS,
  FETCH_PRODUCTS_FAIL,
  FETCH_CONTRACTS_FAIL,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_CONTRACTS_SUCCESS,
} from './types';

import { Dispatch } from 'redux';
import Api from '../../../api/api';

export const getAllProducts = () => (dispatch: Dispatch) => {
	dispatch({ type: FETCH_PRODUCTS });

	Api.get('/user/products', {
		headers: {
			authorization: `${localStorage.getItem('token')}`,
		},
	})
		.then((res) => {
			if (res.status === 200) {
				dispatch({
					type: FETCH_PRODUCTS_SUCCESS,
					payload: {
						data: res.data,
					},
				});
			} else {
				dispatch({ type: FETCH_PRODUCTS_FAIL });
			}
		})
		.catch(() => dispatch({ type: FETCH_PRODUCTS_FAIL }));
};

export const getContractsWhitProduct =
	(product_id: number) => (dispatch: Dispatch) => {
		dispatch({ type: FETCH_CONTRACTS });

		Api.get(`/user/contract/getall?product=${product_id}`, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		})
			.then((res) => {
				if (res.status === 200) {
					dispatch({
						type: FETCH_CONTRACTS_SUCCESS,
						payload: {
							data: res.data,
						},
					});
				} else {
					dispatch({ type: FETCH_CONTRACTS_FAIL });
				}
			})
			.catch(() => dispatch({ type: FETCH_CONTRACTS_FAIL }));
	};