import React from "react";
import styles from "./theme/index.module.scss";
import TopRow from "../TopRow";
import SwingRow from "./SwingRow";
import { Spin } from "antd";
import { Swing } from "../../../modules/Order/Swing/types";

type Props = {
  hasMonth: boolean;
  fail: boolean;
  loading: boolean;
  swings: Swing[] | null;
};
const SwingData: React.FC<Props> = ({ swings, loading, fail, hasMonth }) => {
  
  const renderMessage = (msg: String) => (
    <div className={styles.containerMessage}>
      <span className={styles.message}>{msg}</span>
    </div>
  );

  const list = ["Inicio", "Fim", "Frequência", "Posição", "Valor Atual"];
  if (fail) return renderMessage("Não foi possível carregar os swings");

  if (!hasMonth) return renderMessage("Nenhum mês foi selecionado");

  return (
		<div className={styles.swingDataContainer}>
			<TopRow topItems={list} />
			<div className={styles.swingDataBody}>
				<Spin spinning={loading} style={{ minHeight: 40 }}>
					{swings?.map((item: Swing) => (
						<SwingRow
							key={item.top.toString()}
							frequency={`${item.frequency}`}
							current={item.current}
							position={item.flag}
							start={item.bottom}
							end={item.top}
						/>
					))}
				</Spin>
			</div>
		</div>
	);
};

export default SwingData;
