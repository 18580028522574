import {
  SIMULATION,
  SIMULATION_FAIL,
  SIMULATION_SUCCESS,
  SimulationState,
  SimulationAction,
  FIXED_SIMULATION,
  FIXED_SIMULATION_FAIL,
  FIXED_SIMULATION_SUCESS,
  FETCH_MEAN_DATA_FAIL,
  FETCH_MEAN_DATA,
  FETCH_MEAN_DATA_SUCCESS,
  QUICK_SIMULATION,
  QUICK_SIMULATION_FAIL,
  QUICK_SIMULATION_SUCCESS,
  
} from "./types";

const INITIAL_STATE: SimulationState = {
  fail: false,
  loading: false,
  simulationResponse: null,
  meanData: null,
  quickSimulation: null,
};

const SimulationReducer = (state = INITIAL_STATE, action: SimulationAction) => {
  switch (action.type) {
    case SIMULATION:
      return {
        ...state,
        loading: true,
      };
    case SIMULATION_SUCCESS:
      return {
        ...state,
        loading: false,
        simulationResponse: action.payload.data,
      };

    case SIMULATION_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    case FIXED_SIMULATION:
      return {
        ...state,
        loading: true,
      };
    case FIXED_SIMULATION_SUCESS:
      return {
        ...state,
        loading: false,
        fixedSimulationResponse: action.payload.data,
      };
    case FIXED_SIMULATION_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    case FETCH_MEAN_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MEAN_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        meanData: action.payload.data,
      };
    case FETCH_MEAN_DATA_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    case QUICK_SIMULATION:
        return { 
          ...state,
          loading: true, 
        }

    case QUICK_SIMULATION_SUCCESS: 
        return { 
          ...state,
          loading: false,
          quickSimulation: action.payload.data,
        };
    case QUICK_SIMULATION_FAIL:
        return {
          ...state,
          loading: false,
          fail: true,
        }
    default:
      return {
        ...state,
      };
  }
};

export default SimulationReducer;
