import {
  CREATE_ALERT,
  CREATE_ALERT_FAIL,
  CREATE_ALERT_SUCCESS,
  FETCH_ALERTS,
  FETCH_ALERTS_FAIL,
  FETCH_ALERTS_SUCCESS,
  State,
  AlertsAction,
  TOGGLE_ALERT,
  TOGGLE_ALERT_FAIL,
  TOGGLE_ALERT_SUCCESS,
  SWING_ALERT,
  SWING_ALERT_FAIL,
  SWING_ALERT_SUCCESS,
  BASIS_ALERT,
  BASIS_ALERT_FAIL,
  BASIS_ALERT_SUCCESS,
} from "./types";

const INITIAL_STATE: State = {
  fail: false,
  loading: false,
  alertData: null,
  alerts: null,
  toggleAlertData: null,
  basisAlertResponse: null,
  loadingSwing: false,
  swingResponse: "",
  failSwing: false,
};

const alertReducer = (state = INITIAL_STATE, action: AlertsAction) => {
  switch (action.type) {
    case CREATE_ALERT:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ALERT_SUCCESS:
      return {
        ...state,
        loading: false,
        alertData: action.payload.data,
      };
    case CREATE_ALERT_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    case FETCH_ALERTS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALERTS_SUCCESS:
      return {
        ...state,
        loading: false,
        alerts: action.payload.data,
      };
    case FETCH_ALERTS_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    case TOGGLE_ALERT:
      return {
        ...state,
        loading: true,
      };
    case TOGGLE_ALERT_SUCCESS:
      return {
        ...state,
        loading: false,
        toggleAlertData: action.payload.data,
      };
    case TOGGLE_ALERT_FAIL:
      return {
        ...state,
        fail: true,
      };
    case SWING_ALERT:
      return {
        ...state,
        loadingSwing: true,
      };
    case SWING_ALERT_FAIL:
      return {
        ...state,
        loadingSwing: false,
        failSwing: true,
      };
    case SWING_ALERT_SUCCESS:
      return {
        ...state,
        loadingSwing: false,
      };
    case BASIS_ALERT:
      return {
        ...state,
        loading: true,
      };
    case BASIS_ALERT_SUCCESS:
      return {
        ...state,
        loading: false,
        basisAlertResponse: action.payload.data,
      };
    case BASIS_ALERT_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    default:
      return {
        ...state,
      };
  }
};

export default alertReducer;
