import React from "react";
import { Select } from "antd";
import {
  ContractsHistoryMonth,
  MonthSelected,
} from "../../modules/Main/Contracts/types";
import { selectedMonth } from "../../modules/Main/Contracts/actions";
import { useDispatch } from "react-redux";

type Props = {
  months: ContractsHistoryMonth[];
  monthSelected: MonthSelected;
};
const SelectMonth: React.FC<Props> = ({ months, monthSelected }) => {
  const { Option } = Select;
  const dispatch = useDispatch();

  const handleSelect = (option: number) => {
    dispatch(selectedMonth(option, months));
  };
  return (
		<>
			{months !== null && months?.length > 0 ? (
				<Select
					size="small"
					showSearch
					style={{
						width: "auto",
						maxWidth: "170px",
						color: "black",
						marginLeft: 5,
					}}
					value={monthSelected?.selected}
					placeholder="Selecione o Mês"
					notFoundContent="Nenhum mês foi encontrado"
					optionFilterProp="children"
					onSelect={(option) => handleSelect(Number(option))}
				>
					{months?.map((item) => (
						<Option key={item.id.toString()} value={item.id}>
							{item.reference_month}
						</Option>
					))}
				</Select>
			) : null}
		</>
	);
};

export default SelectMonth;
