import { 
  State,
  Action,
  FETCH_PRODUCTS,
  FETCH_CONTRACTS,
  FETCH_PRODUCTS_FAIL,
  FETCH_CONTRACTS_FAIL,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_CONTRACTS_SUCCESS,
} from './types';

const INITIAL_STATE: State = {
  error: false,
  loading: false,
  products: [],
  contractsWhitProducts: [],
};

 const getContractsAndProduct = (state = INITIAL_STATE, action: Action) => {
   switch (action.type) {
     case FETCH_CONTRACTS:
       return {
         ...state,
         loading: true,
       };
      case FETCH_CONTRACTS_SUCCESS:
        return {
          ...state,
          loading: false,
          contractsWhitProducts: action.payload.data,
        };
      case FETCH_CONTRACTS_FAIL:
        return {
          ...state,
          error: true,
          loading: false,
        };

      case FETCH_PRODUCTS:
        return {
          ...state,
          loading: true,
        };
      case FETCH_PRODUCTS_SUCCESS:
        return {
          ...state,
          loading: false,
          products: action.payload.data
        };
      case FETCH_PRODUCTS_FAIL:
        return {
          ...state,
          error: false,
          loading: false,
        };
     default: {
       return {
         ...state
       }
     }
   }
 };

 export default getContractsAndProduct;