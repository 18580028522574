export const FETCH_USER_INFO = "FETCH_USER_INFO";
export const FETCH_USER_INFO_FAIL = "FETCH_USER_INFO_FAIL";
export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";

export type Constants =
  | typeof FETCH_USER_INFO
  | typeof FETCH_USER_INFO_FAIL
  | typeof FETCH_USER_INFO_SUCCESS;

export type UserInfo = {
  id: number;
  name: string;
  email: "string";
  company_base: number;
  receives_basis_alert: boolean;
  accept_term: boolean;
};

export type State = {
  loading: boolean;
  fail: boolean;
  userInfo: UserInfo | null;
};

export type Action = {
  type: Constants;
  payload: {
    data: UserInfo;
  };
};
