import { Action,  AUTH_REVOKE,  AUTH_REVOKE_FAIL,  AUTH_REVOKE_SUCCESS,  State } from "./types";

const InitialState: State = {
  failRevoke: false,
  loadingRevoke: false,
};

const authRevokeReducer = (state = InitialState, action: Action) => {
  switch (action.type) {
    case AUTH_REVOKE:
      return {
        ...state,
        loadingRevoke: true,
        failRevoke: false,
      };
    case AUTH_REVOKE_SUCCESS:
      return {
        ...state,
        loadingRevoke: false,
      };
    case AUTH_REVOKE_FAIL:
      return {
        ...state,
        failRevoke: true,
        loadingRevoke: false,
      };
    default:
      return {
        ...state,
      };
  }
};
export default authRevokeReducer;
