import { CheckCircleOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import React from "react";
import { Order } from "../../../../modules/Order/Orders/types";
import MonthSpan from "../../../MonthSpan";
import VariablesSpan from "../../../VariablesSpan";
import styles from "./theme/index.module.scss";

type Props = {
  acceptedOrder: Order;
};
const AcceptedRow: React.FC<Props> = ({ acceptedOrder }) => {
  return (
    <div className={styles.rowContainer}>
      <div className={styles.columnContainer}>
        <VariablesSpan
          height="20px"
          width="60px"
          variableName={acceptedOrder?.variable}
        />
        <span>
          {moment(acceptedOrder?.date)
            .tz("America/Sao_Paulo")
            .format("DD/MM/YYYY HH:mm")}
          {/* {timeFormat(acceptedOrder?.date).format("DD/MM/YYYY - HH:mm")} */}
        </span>
      </div>
      <div className={styles.columnContainer}>
        <span>{acceptedOrder.fixated}</span>
        <MonthSpan
          height="auto"
          width="50px"
          month={acceptedOrder?.month_reference}
        />
      </div>
      <div className={styles.columnContainer}>
        <span className={styles.priceSpan}>
          {acceptedOrder.price.toString().replace(".", ",")}
        </span>
        <span>
          {moment(acceptedOrder?.date)
            .tz("America/Sao_Paulo")
            .format("DD/MM/YYYY HH:mm")}
          {/* {moment(acceptedOrder?.date).format("DD/MM/YYYY - HH:mm")} */}
        </span>
      </div>
      <div className={styles.columnContainer}>
        <span>
          {moment(acceptedOrder?.expiration_date)
            .tz("America/Sao_Paulo")
            .format("DD/MM/YYYY HH:mm")}
          {/* {timeFormat(acceptedOrder?.expiration_date).format(
            "DD/MM/YYYY - HH:mm"
          )} */}
        </span>
      </div>
      <div className={styles.columnContainer}>
        {acceptedOrder?.partial ? (
          <CheckCircleOutlined style={{ color: "green" }} />
        ) : (
          <CheckCircleOutlined />
        )}
      </div>
    </div>
  );
};

export default AcceptedRow;
