import React from "react";
import { Tooltip } from "antd";

type Props = {
  x: number;
  y: number;
  value: any;
  stroke: any;
  open_closed_cbot: boolean;
  open_closed_basis: boolean;
  open_closed_dolar: boolean;
};

export const CustomLabelCBOT: React.FC<Props> = ({
  x,
  y,
  value,
  stroke,
  open_closed_cbot,
}) => {

  return (
    <>
      <svg
        x={x >= 1195 ? x - 60 : x}
        y={30}
        width="60"
        height="30"
        viewBox="0 0 34 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Tooltip
            title={
              <>
                <span style={{ color: "#ea6f24", fontWeight: "bold" }}>
                Referência cotação de farelo de soja chicago board of trade (CBOT)
                </span>
              </>
            }
          >
          <g>
            <path
              d="M5 9.5H29C31.4853 9.5 33.5 7.48528 33.5 5C33.5 2.51472 31.4853 0.5 29 0.5H5C2.51472 0.5 0.5 2.51472 0.5 5C0.5 7.48528 2.51472 9.5 5 9.5Z"
              stroke="#ea6f24"
              fill={open_closed_cbot ? "transparent" : "#ea6f24"}
            />
            <text
              x="50%"
              y="60%"
              dominantBaseline="middle"
              textAnchor="middle"
              fill="#fff"
              fontWeight="bold"
              fontSize="5px"
            >
              {(value !== "" && value != null) ? value?.toFixed(1).replace(".", ",") : null}
              {(value == null || value === '') ? "-" : null}
            </text>
          </g>
        </Tooltip>
      </svg>
    </>
  );
};

export const CustomLabelBasis: React.FC<Props> = ({
  x,
  y,
  value,
  stroke,
  open_closed_basis,
}) => {

  return (
      <svg
        x={x >= 1195 ? x - 60 : x}
        y={50}
        width="60"
        height="30"
        viewBox="0 0 34 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Tooltip
            title={
              <>
                <span style={{ color: "#0072bc", fontWeight: "bold" }}>
                Referência de prêmio no porto de Paranaguá
                </span>
              </>
            }
          >
          <g>
            <path
              d="M5 9.5H29C31.4853 9.5 33.5 7.48528 33.5 5C33.5 2.51472 31.4853 0.5 29 0.5H5C2.51472 0.5 0.5 2.51472 0.5 5C0.5 7.48528 2.51472 9.5 5 9.5Z"
              stroke="#0072bc"
              fill={open_closed_basis ? "transparent" : "#0072bc"}
            />
            <text
              x="50%"
              y="60%"
              dominantBaseline="middle"
              textAnchor="middle"
              fill="#fff"
              fontWeight="bold"
              fontSize="5px"
            >
              {(value !== "" && value != null) ? value?.toString().replace(".", ",") : null}
              {(value == null || value === '') ? "-" : null}
            </text>
          </g>
        </Tooltip>
      </svg>
  );
};

export const CustomLabelDolar: React.FC<Props> = ({
  x,
  y,
  value,
  stroke,
  open_closed_dolar,
}) => {

  return (
      <svg
        x={x >= 1195 ? x - 60 : x}
        y={70}
        width="60"
        height="30"
        viewBox="0 0 34 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Tooltip
            title={
              <>
                <span style={{ color: "#66bc46", fontWeight: "bold" }}>
                Referência de cotação de dólar BM&F
                </span>
              </>
            }
          >
        <g>
          <path
            d="M5 9.5H29C31.4853 9.5 33.5 7.48528 33.5 5C33.5 2.51472 31.4853 0.5 29 0.5H5C2.51472 0.5 0.5 2.51472 0.5 5C0.5 7.48528 2.51472 9.5 5 9.5Z"
            stroke="#66bc46"
            fill={open_closed_dolar ? "transparent" : "#66bc46"}
          />
          <text
            x="50%"
            y="60%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="#fff"
            fontWeight="bold"
            fontSize="5px"
          >
            {(value !== "" && value != null) ? value?.toFixed(3).replace(".", ",") : null}
            {(value == null || value === '') ? "-" : null}
          </text>
        </g>
        </Tooltip>
      </svg>
  );
};
