
import { Dispatch } from 'redux';
import Api from '../../api/api';
import { AUTH_REVOKE, AUTH_REVOKE_SUCCESS,AUTH_REVOKE_FAIL } from './types';


export const logoutUser = () => (
    dispatch: Dispatch
  ) => {
    Api.get('user/revoke', {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		})
			.then((res) => {
				dispatch({
					type: AUTH_REVOKE,
				});
				if (res.status === 200) {
					dispatch({
						type: AUTH_REVOKE_SUCCESS,
					});
					localStorage.clear();
				} else {
					dispatch({
						type: AUTH_REVOKE_FAIL,
					});
				}
			})
			.catch((err) => {
				if (err) {
					dispatch({
						type: AUTH_REVOKE_FAIL,
					});
				}
			});
  };
  