import React from "react";
import { useSelector } from "react-redux";

import { Tooltip } from 'antd';
import LineChartComponent from "./Graphic";

import { PendenciesContractMonthInfo } from "../../modules/types";
import { PendenciesContractInfo } from "../../modules/types";

import styles from "../theme/index.module.scss";
import { AppState } from "../../../../rootReducer";

const FrameGraphic: React.FC = () => {

  const {
    pendenciesContractMonthInfo, pendenciesContractInfo
  } : {
    pendenciesContractMonthInfo: PendenciesContractMonthInfo[];
    pendenciesContractInfo: PendenciesContractInfo;
  } = useSelector((appState: AppState) => appState.contractsPending);

 const renderItemPendentValue = (i: number, lengthTotal: number) => {
	let styleItem = {
		"width": 100 / pendenciesContractMonthInfo.length+1 + '%',
		"display": 'flex',
		"flex-direction": 'row',
		"justify-content": 'center',
		"margin-right": 'auto',
	};
	if(i == lengthTotal){
		styleItem = {
			"width": 100 / pendenciesContractMonthInfo.length + '%',
			"display": 'flex',
			"flex-direction": 'row',
			"justify-content": 'center',
			"margin-right": '-25px',
		}
	}
	
	return styleItem;
  };

  return (
		<>
			<div className={styles.frameMainContainer}>
				<div className={styles.selectMonthContainer}>
					{pendenciesContractMonthInfo.map((value, i) => {
						return (
							<div
								style={renderItemPendentValue(i, pendenciesContractMonthInfo.length -1)}
							>
								<span key={value.request_id.toString()}>
									{value?.reference_month}
								</span>
							</div>
						);
					})}
				</div>

				<div className={styles.frameBodyContainer}>
					<LineChartComponent
						infoPending={pendenciesContractMonthInfo}
						basisHide={false}
						cbotHide={false}
						dolarHide={false}
						open_closed_basis={false}
						open_closed_cbot={false}
						open_closed_dolar={false}
						symbol={pendenciesContractInfo?.currency == "reais" ? "R$": "$"}
					/>
				</div>

				<div className={styles.selectMonthContainerValue}>
					{pendenciesContractMonthInfo.map((value, i) => {
						return (
							<Tooltip
									title={
										<>
											<span style={{ color: 'white', fontWeight: 'bold' }}>
												Valor projetado
											</span>
										</>
									}
								>
								<div
									style={renderItemPendentValue(i, pendenciesContractMonthInfo.length -1)}
								>
									{value.frame_quote == null ? (
										<span ng-if="value?.frame_quote" key={value.request_id.toString()}>
										{pendenciesContractInfo?.currency == "reais" ? "R$": "$"} 0
										</span>
									): 
										<span ng-if="value?.frame_quote" key={value.request_id.toString()}>
											{pendenciesContractInfo?.currency == "reais" ? "R$ ": "$ "}
											{value?.frame_quote?.toFixed(2)}
										</span>
									}
									
								</div>
							</Tooltip>
							
						);
					})}
				</div>
			</div>
		</>
	);
};

export default FrameGraphic;
