import React from "react";
import { Tooltip,  } from "antd";
import { WarningOutlined, FieldTimeOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import milhoSvg from '../../../../assets/milho.svg';
import sojaSvg from '../../../../assets/farelo-soja.svg';
import oleoSvg from '../../../../assets/oleo.svg';
import {
	selectedProductIndex,
	setContractNotExistsHeader,
	setProductHeader,
} from '../../../../components/SelectFrame/components/modules/actions';

import { Pendencies as PendenciesType } from '../../modules/types';

import styles from './theme/index.module.scss';

type Props = {
	pendencies: PendenciesType;
};

const ContractsRow: React.FC<Props> = ({ pendencies }) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const handle = (value: number, product_id: number, name: string) => {
		const val = {
			id: value,
			contract_name: name,
			id_product: pendencies.product_base,
		};

		dispatch(setContractNotExistsHeader(name, value));

		switch (product_id) {
			case 1:
				dispatch(selectedProductIndex(0));
				dispatch(setProductHeader('Farelo de Soja', 'D2955D'));
				localStorage.setItem('productIdSelected', product_id + '');
				break;
			case 2:
				dispatch(selectedProductIndex(1));
				dispatch(setProductHeader('Milho', 'E9D205'));
				localStorage.setItem('productIdSelected', product_id + '');
				break;
			case 3:
				dispatch(selectedProductIndex(2));
				dispatch(setProductHeader('Óleo de Soja', 'EF8F01'));
				localStorage.setItem('productIdSelected', product_id + '');
				break;
			default:
				break;
		}

		history.push('/pendencies/pending_contract', val);
	};

	const switchProducts = (id?: number) => {
		switch (id) {
			case 1:
				return <img key={id.toString()} src={sojaSvg} alt="sojaSvg" />;
			case 2:
				return <img key={id.toString()} src={milhoSvg} alt="milhoSvg" />;
			case 3:
				return <img key={id.toString()} src={oleoSvg} alt="oleoSvg" />;
			default:
				break;
		}
	};

	return (
		<div className={styles.rowContainer}>
			<div
				className={styles.columnContainer}
				style={{ display: 'flex', flexDirection: 'row' }}
			>
				<span>{pendencies?.name}</span>
				{pendencies.accepted === false && pendencies.open === false && (
					<Tooltip title="Este contrato está sob revisão" color="gray">
						<WarningOutlined style={{ color: 'yellow', marginLeft: 20 }} />
					</Tooltip>
                )}
                {pendencies.accepted === false && pendencies.open === true && (
                    <Tooltip title="Aguardando aprovação" color="gray">
                        <FieldTimeOutlined style={{ color: 'yellow', marginLeft: 20 }} />
                    </Tooltip>
                )}
			</div>

			<div className={styles.columnContainer}>
				<span>{pendencies?.origin.city}</span>
			</div>

			<div className={styles.columnContainer}>
				<span>{pendencies?.destiny_city}</span>
			</div>

			<div className={styles.columnContainer}>
				{switchProducts(pendencies?.product_base)}
			</div>

			<div className={styles.columnContainer}>
				<button
					onClick={() =>
						handle(pendencies.id, pendencies.product_base, pendencies.name)
					}
				>
					VER CONTRATO
				</button>
			</div>
		</div>
	);
};

export default ContractsRow;
