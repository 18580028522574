import React from "react"
import { Tooltip } from "antd";

type Props = {
  values: 'maior' | 'menor' | 'igual';
  open_closed: boolean;
  value: any;
  color: string;
  title: string;
  variable: string;
  x: number;
  y: number;
  select: () => void;
};

const SvgComponent = ({ color, open_closed, variable, value, values, title, x, y, select }: Props) =>  {

  const renderSwitchSVG = () => {
    switch (values) {
      case 'menor':
        return (
          <>
            <svg
              width="105"
              height="30"
              viewBox="0 0 55 17"
            >
              <path
                d="M5 9.5H29C31.4853 9.5 33.5 7.48528 33.5 5C33.5 2.51472 31.4853 0.5 29 0.5H5C2.51472 0.5 0.5 2.51472 0.5 5C0.5 7.48528 2.51472 9.5 5 9.5Z"
                stroke={color}
                fill={open_closed ? "transparent" : color}
              />
             </svg>
           
              <path fill="#000000" fill-opacity="1" stroke="#4a90d6" stroke-opacity="1" stroke-width="3" stroke-dasharray="none" stroke-linejoin="miter" stroke-linecap="butt" stroke-dashoffset="" fill-rule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" id="svg_10" d="M53.96153846153847,3.3076923076923137 "/>
              <path fill="rgb(255 153 51)" fill-opacity="1" stroke="rgb(241 186 130)"  stroke-opacity="1" stroke-width="2" stroke-dasharray="none" stroke-linejoin="miter" stroke-linecap="butt" stroke-dashoffset="" fill-rule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" d="M60.307694112523336,4.6923073001642575 C60.307694112523336,2.652358497811181 61.942840895296584,0.9999999679051886 63.9615406271154,0.9999999679051886 C65.98024035893421,0.9999999679051886 67.61538714170746,2.652358497811181 67.61538714170746,4.6923073001642575 C67.61538714170746,6.732256102517332 65.98024035893421,8.384614632423323 63.9615406271154,8.384614632423323 C61.942840895296584,8.384614632423323 60.307694112523336,6.732256102517332 60.307694112523336,4.6923073001642575 z" id="svg_11" />
              
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="10px" y="-5px"
                viewBox="-595 -130 1112 1112" enable-background={"new 0 0 512 512"} >
              <path fill={"#000000"} d="M256,82.752c-95.52,0-173.248,77.712-173.248,173.248S160.48,429.248,256,429.248c95.536,0,173.248-77.712,173.248-173.248
                S351.52,82.752,256,82.752z M273.632,152.096l-4.4,151.296h-21.44l-4.4-151.296H273.632z M258.512,368
                c-11.024,0-18.592-8.512-18.592-19.552c0-11.344,7.872-19.536,18.592-19.536c11.344,0,18.592,8.192,18.592,19.536
                C277.104,359.488,269.856,368,258.512,368z"/>
              <path fill={"#000000"}d="M256,0C114.608,0,0,114.608,0,256s114.608,256,256,256s256-114.608,256-256S397.392,0,256,0z M256,453.248
                c-108.928,0-197.248-88.32-197.248-197.248S147.072,58.752,256,58.752c108.944,0,197.248,88.304,197.248,197.248
                S364.944,453.248,256,453.248z"/>
              <path fill={"rgb(255 153 51)"} d="M256,58.752c-108.928,0-197.248,88.32-197.248,197.248S147.072,453.248,256,453.248
                c108.944,0,197.248-88.304,197.248-197.248S364.944,58.752,256,58.752z M256,429.248c-95.52,0-173.248-77.712-173.248-173.248
                S160.48,82.752,256,82.752c95.536,0,173.248,77.712,173.248,173.248S351.52,429.248,256,429.248z"/>
              <g>
                <path fill={"#FFFFFF"} d="M258.512,328.928c-10.72,0-18.592,8.192-18.592,19.536c0,11.024,7.568,19.552,18.592,19.552
                  c11.344,0,18.592-8.512,18.592-19.552C277.104,337.104,269.856,328.928,258.512,328.928z"/>
                <polygon fill={"#FFFFFF"} points="269.232,303.392 273.632,152.096 243.392,152.096 247.792,303.392 	"/>
              </g>
              </svg>
              {/* 
              <svg width="18px" height="15px" viewBox="-5 -2 24 24" xmlns="http://www.w3.org/2000/svg">
                <g id="a"/><g id="b"><line stroke={"red"} stroke-linecap={"round"} stroke-linejoin={"round"} x1="12" x2="12" y1="3.5" y2="20.5"/><line stroke={"red"} stroke-linecap={"round"} stroke-linejoin={"round"} x1="12.03" x2="18.99" y1="20.48" y2="13.52"/><line stroke={"red"} stroke-linecap={"round"} stroke-linejoin={"round"} x1="11.97" x2="5.01" y1="20.48" y2="13.52"/></g>
              </svg> */}
             
              {/* <path fill="#000000" fill-opacity="1" stroke="#4a90d6" stroke-opacity="1" stroke-width="3" stroke-dasharray="none" stroke-linejoin="miter" stroke-linecap="butt" stroke-dashoffset="" fill-rule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" id="svg_5" d="M53.96153846153847,3.3076923076923137 " />
              <path fill="rgb(255 153 51)" fill-opacity="1" stroke="rgb(241 186 130)"  stroke-opacity="1" stroke-width="2" stroke-dasharray="none" stroke-linejoin="miter" stroke-linecap="butt" stroke-dashoffset="" fill-rule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" d="M64.61538634227111,4.6923073001642575 C64.61538634227111,2.652358497811181 66.25053312504436,0.9999999679051886 68.26923285686317,0.9999999679051886 C70.28793258868198,0.9999999679051886 71.92307937145523,2.652358497811181 71.92307937145523,4.6923073001642575 C71.92307937145523,6.732256102517332 70.28793258868198,8.384614632423323 68.26923285686317,8.384614632423323 C66.25053312504436,8.384614632423323 64.61538634227111,6.732256102517332 64.61538634227111,4.6923073001642575 z" id="svg_1"/> */}

              </>
        );
    
      case 'maior': 
        return (
          <>
          <svg
            width="105"
            height="30"
            viewBox="0 0 55 17"
          >
            <path
              d="M5 9.5H29C31.4853 9.5 33.5 7.48528 33.5 5C33.5 2.51472 31.4853 0.5 29 0.5H5C2.51472 0.5 0.5 2.51472 0.5 5C0.5 7.48528 2.51472 9.5 5 9.5Z"
              stroke={color}
              fill={open_closed ? "transparent" : color}
            />
           </svg>
            <path fill="#000000" fill-opacity="1" stroke="#4a90d6" stroke-opacity="1" stroke-width="3" stroke-dasharray="none" stroke-linejoin="miter" stroke-linecap="butt" stroke-dashoffset="" fill-rule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" id="svg_12" d="M53.96153846153847,3.3076923076923137 "/>
            <path fill="rgb(255 153 51)" fill-opacity="1" stroke="rgb(241 186 130)"  stroke-opacity="1" stroke-width="2" stroke-dasharray="none" stroke-linejoin="miter" stroke-linecap="butt" stroke-dashoffset="" fill-rule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" d="M60.307694112523336,4.6923073001642575 C60.307694112523336,2.652358497811181 61.942840895296584,0.9999999679051886 63.9615406271154,0.9999999679051886 C65.98024035893421,0.9999999679051886 67.61538714170746,2.652358497811181 67.61538714170746,4.6923073001642575 C67.61538714170746,6.732256102517332 65.98024035893421,8.384614632423323 63.9615406271154,8.384614632423323 C61.942840895296584,8.384614632423323 60.307694112523336,6.732256102517332 60.307694112523336,4.6923073001642575 z" id="svg_13" />

            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="10px" y="-5px"
                viewBox="-595 -130 1112 1112" enable-background={"new 0 0 512 512"} >
              <path fill={"#000000"} d="M256,82.752c-95.52,0-173.248,77.712-173.248,173.248S160.48,429.248,256,429.248c95.536,0,173.248-77.712,173.248-173.248
                S351.52,82.752,256,82.752z M273.632,152.096l-4.4,151.296h-21.44l-4.4-151.296H273.632z M258.512,368
                c-11.024,0-18.592-8.512-18.592-19.552c0-11.344,7.872-19.536,18.592-19.536c11.344,0,18.592,8.192,18.592,19.536
                C277.104,359.488,269.856,368,258.512,368z"/>
              <path fill={"#000000"}d="M256,0C114.608,0,0,114.608,0,256s114.608,256,256,256s256-114.608,256-256S397.392,0,256,0z M256,453.248
                c-108.928,0-197.248-88.32-197.248-197.248S147.072,58.752,256,58.752c108.944,0,197.248,88.304,197.248,197.248
                S364.944,453.248,256,453.248z"/>
              <path fill={"rgb(255 153 51)"} d="M256,58.752c-108.928,0-197.248,88.32-197.248,197.248S147.072,453.248,256,453.248
                c108.944,0,197.248-88.304,197.248-197.248S364.944,58.752,256,58.752z M256,429.248c-95.52,0-173.248-77.712-173.248-173.248
                S160.48,82.752,256,82.752c95.536,0,173.248,77.712,173.248,173.248S351.52,429.248,256,429.248z"/>
              <g>
                <path fill={"#FFFFFF"} d="M258.512,328.928c-10.72,0-18.592,8.192-18.592,19.536c0,11.024,7.568,19.552,18.592,19.552
                  c11.344,0,18.592-8.512,18.592-19.552C277.104,337.104,269.856,328.928,258.512,328.928z"/>
                <polygon fill={"#FFFFFF"} points="269.232,303.392 273.632,152.096 243.392,152.096 247.792,303.392 	"/>
              </g>
              </svg>
              
            {/* <svg width="18px" height="15px" viewBox="-5 -2 24 24" xmlns="http://www.w3.org/2000/svg">
            <g id="a"/><g id="b">
            <line fill={"none"} stroke={'green'} stroke-linecap={"round"} stroke-linejoin={"round"} x1="12" x2="12" y1="20.5" y2="3.5"/><line fill={"none"} stroke={'green'} stroke-linecap={"round"} stroke-linejoin={"round"} x1="11.97" x2="5.01" y1="3.52" y2="10.48"/><line fill={"none"} stroke={'green'} stroke-linecap={"round"} stroke-linejoin={"round"} x1="12.03" x2="18.99" y1="3.52" y2="10.48"/></g>
            </svg> */}
            {/* <path fill="#000000" fill-opacity="1" stroke="#4a90d6" stroke-opacity="1" stroke-width="3" stroke-dasharray="none" stroke-linejoin="miter" stroke-linecap="butt" stroke-dashoffset="" fill-rule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" id="svg_5" d="M53.96153846153847,3.3076923076923137 " />
              <path fill="rgb(255 153 51)" fill-opacity="1" stroke="rgb(241 186 130)"  stroke-opacity="1" stroke-width="2" stroke-dasharray="none" stroke-linejoin="miter" stroke-linecap="butt" stroke-dashoffset="" fill-rule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" d="M64.61538634227111,4.6923073001642575 C64.61538634227111,2.652358497811181 66.25053312504436,0.9999999679051886 68.26923285686317,0.9999999679051886 C70.28793258868198,0.9999999679051886 71.92307937145523,2.652358497811181 71.92307937145523,4.6923073001642575 C71.92307937145523,6.732256102517332 70.28793258868198,8.384614632423323 68.26923285686317,8.384614632423323 C66.25053312504436,8.384614632423323 64.61538634227111,6.732256102517332 64.61538634227111,4.6923073001642575 z" id="svg_1"/> */}

            </>
        );
        // case 'igual': 
        //   return (
        //     <>
        //       <path
        //         d="M64.5 0h-38a8.5 8.5 0 0 0 0 17h38a8.5 8.5 0 0 0 0-17Z"
        //         fill='#fff'
        //       />
        //       <path d="M64.5 16a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z" fill="#7BB957" />
        //       {/* <path
        //         d="M64.929 9.01h.574c-.184.195-.286.306-.388.412-.645.642-1.288 1.286-1.928 1.93-.187.189-.27.411-.07.631.216.234.449.164.666-.018a1.43 1.43 0 0 0 .094-.083c.958-.96 1.92-1.915 2.876-2.88.338-.343.327-.537-.022-.89-.677-.686-1.361-1.365-2.043-2.048l-.834-.827c-.222-.218-.466-.358-.725-.09-.243.25-.105.485.102.692l1.006 1.007c.403.399.807.797 1.213 1.196l-.042.095h-.442"
        //         stroke="#fff"
        //       /> */}
        //       <path
        //         d="M46.5 0h-38a8.5 8.5 0 0 0 0 17h38a8.5 8.5 0 0 0 0-17Z"
        //         stroke={color}
        //         fill={open_closed ? "#07191d" : color}
        //       />
        //     </>
        //   );

      default:
        return (
          <svg
            width="105"
            height="30"
            viewBox="0 0 55 17"
          >
            <path
              d="M5 9.5H29C31.4853 9.5 33.5 7.48528 33.5 5C33.5 2.51472 31.4853 0.5 29 0.5H5C2.51472 0.5 0.5 2.51472 0.5 5C0.5 7.48528 2.51472 9.5 5 9.5Z"
              stroke={color}
              fill={open_closed ? "transparent" : color}
            />
          </svg>
        );
    }
  };

  return (
    
    <svg
      x={x}
      y={y}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="30"
      onClick={select}
    >

      <Tooltip
        title={
          <span style={{ color, fontWeight: "bold" }}>
            {title}
          </span>
        }
      >
        <g>
          {renderSwitchSVG()}

          <text
            x="40%"
            y="30%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="#fff"
            fontWeight="bold"
            fontSize="10px"

          >
            {(value !== "" && value != null) ? (variable == 'dolar' ? value?.toFixed(3).replace(".", ",") : value?.toFixed(1).replace(".", ",")) : null}
            {(value == null || value === '') ? "-" : null}
          </text>
        </g>
      </Tooltip>
    </svg>

  )
}

export default SvgComponent
