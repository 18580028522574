import React, { Fragment, useEffect, useState } from 'react';
import styles from './theme/index.module.scss';
import Months from '../Months';
import { Progress, Tooltip } from 'antd';
import CircleDay from './CircleDay';
import {
	ContractsHistoryMonth,
	MonthSelected,
	ContractInfo,
} from '../../modules/Main/Contracts/types';
import ProgressBar from './ProgessBar';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../rootReducer';
import { getPercent } from '../../utils/getPercent';
import { Order } from '../../modules/Order/Orders/types';
import { fetchAcceptedOrders } from '../../modules/Order/Orders/actions';
import CarouselArrows from './Carousel';

type Props = {
	contractshistoryMonth: ContractsHistoryMonth[];
	monthSelected: MonthSelected;
	contractInfo: ContractInfo;
};

const FixedContainer: React.FC<Props> = ({
	contractshistoryMonth,
	contractInfo,
	monthSelected,
}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (contractInfo !== undefined && contractInfo !== null && monthSelected !== undefined){
			dispatch(
				fetchAcceptedOrders(contractInfo?.id, monthSelected?.selected, '')
			);
		}
	}, [monthSelected, contractInfo]);

	const { acceptedOrders }: { acceptedOrders: Order[] } = useSelector(
		(state: AppState) => state.orders
	);

	const [listAcceptOrdersBasis, setListAcceptOrdersBasis] = useState<Order[]>(
		[]
	);
	const [avgBasis, setAvgBasis] = useState<number>(0);
	const [avgBasisVolume, setAvgBasisVolume] = useState<number>(0);
	const [listAcceptOrdersCbot, setListAcceptOrdersCbot] = useState<Order[]>([]);
	const [avgCbot, setAvgCbot] = useState<number>(0);
	const [avgCbotVolume, setAvgCbotVolume] = useState<number>(0);
	const [listAcceptOrdersDolar, setListAcceptOrdersDolar] = useState<Order[]>(
		[]
	);
	const [avgDolar, setAvgDolar] = useState<number>(0);
	const [avgDolarVolume, setAvgDolarVolume] = useState<number>(0);

	useEffect(() => {
		let avgBasisTmp = 0;
		let avgBasisVolumeTmp = 0;
		let avgCbotTmp = 0;
		let avgCbotVolumeTmp = 0;
		let avgDolarTmp = 0;
		let avgDolarVolumeTmp = 0;

		if (
			listAcceptOrdersCbot.length === 0 &&
			listAcceptOrdersBasis.length === 0 &&
			listAcceptOrdersDolar.length === 0 &&
			monthSelected !== undefined &&
			acceptedOrders !== undefined
		) {
			for (let index = 0; index < acceptedOrders?.length; index++) {
				if (acceptedOrders[index].variable === 'basis') {
					listAcceptOrdersBasis.push(acceptedOrders[index]);
					avgBasisTmp =
						avgBasisTmp +
						acceptedOrders[index].fixated * acceptedOrders[index].price;
					avgBasisVolumeTmp = avgBasisVolumeTmp + acceptedOrders[index].fixated;
				}

				if (acceptedOrders[index].variable === 'cbot') {
					listAcceptOrdersCbot.push(acceptedOrders[index]);
					avgCbotTmp =
						avgCbotTmp +
						acceptedOrders[index].fixated * acceptedOrders[index].price;
					avgCbotVolumeTmp = avgCbotVolumeTmp + acceptedOrders[index].fixated;
				}

				if (acceptedOrders[index].variable === 'dolar') {
					listAcceptOrdersDolar.push(acceptedOrders[index]);
					avgDolarTmp =
						avgDolarTmp +
						acceptedOrders[index].fixated * acceptedOrders[index].price;
					avgDolarVolumeTmp = avgDolarVolumeTmp + acceptedOrders[index].fixated;
				}

				if (index === acceptedOrders.length - 1) {
					setAvgBasis(avgBasisTmp);
					setAvgCbot(avgCbotTmp);
					setAvgDolar(avgDolarTmp);

					setAvgBasisVolume(avgBasisVolumeTmp);
					setAvgCbotVolume(avgCbotVolumeTmp);
					setAvgDolarVolume(avgDolarVolumeTmp);
				}
			}
		}
	}, [
		acceptedOrders,
		listAcceptOrdersBasis,
		listAcceptOrdersCbot,
		listAcceptOrdersDolar,
		monthSelected,
	]);

	useEffect(() => {
		setListAcceptOrdersCbot([]);
		setListAcceptOrdersBasis([]);
		setListAcceptOrdersDolar([]);

		setAvgBasis(0);
		setAvgCbot(0);
		setAvgDolar(0);
	}, [acceptedOrders, monthSelected]);

	return (
		<div className={styles.mainContainer}>
			<div className={styles.fixedTop}>
				<span className={styles.fixedSpan}>FIXADA - PRAZO </span>
			</div>
			{contractInfo ? (
				<>
					<div className={styles.topMonthsContainer}>
						<div className={styles.topMonths}>
							<Months
								contractshistoryMonth={contractshistoryMonth}
								monthSelected={monthSelected}
							/>
						</div>
					</div>
					<div className={styles.percentContainer}>
						<div className={styles.percent}>
							<div className={styles.circleMain}>
								<Progress
									style={{ maxWidth: '70%' }}
									type="circle"
									percent={Number(
										getPercent(
											monthSelected?.contractSelected?.cbot_volume,
											monthSelected?.contractSelected?.fixated_cbot
										).toFixed(2)
									)}
									strokeColor="#EA6F24"
									trailColor="#061417"
									showInfo={true}
									strokeLinecap="round"
									strokeWidth={8}
									format={(value) => {
										return (
											<CarouselArrows
												value={value?.toFixed(0)}
												monthSelected={monthSelected}
												fixated={monthSelected?.contractSelected?.cbot_volume}
												listAcceptOrders={listAcceptOrdersCbot}
												avg={avgCbot}
												avgVolume={avgCbotVolume}
											/>
										);
									}}
								/>
								<Tooltip
									title={
										<>
											<span style={{ color: '#ea6f24', fontWeight: 'bold' }}>
												Referência cotação de farelo de soja chicago board of
												trade (CBOT)
											</span>
										</>
									}
								>
									<span
										className={styles.circleSpan}
										style={{ color: '#EA6F24' }}
									>
										CBOT(%)
									</span>
								</Tooltip>
								<div className={styles.progress}>
									<ProgressBar
										amount={Number(
											getPercent(
												monthSelected?.contractSelected?.cbot_volume,
												monthSelected?.contractSelected?.fixated_cbot
											).toFixed(2)
										)}
										toolTipAmount={
											monthSelected?.contractSelected?.fixated_cbot
										}
										contractMax={monthSelected?.contractSelected?.cbot_volume}
										variableName="cbot"
									/>
								</div>
							</div>
							<div className={styles.circleMain}>
								<Progress
									style={{ maxWidth: '70%' }}
									type="circle"
									percent={Number(
										getPercent(
											monthSelected?.contractSelected?.basis_volume,
											monthSelected?.contractSelected?.fixated_basis
										).toFixed(2)
									)}
									strokeColor="#0072bc"
									trailColor="#061417"
									showInfo={true}
									strokeLinecap="round"
									strokeWidth={8}
									format={(value) => {
										return (
											<CarouselArrows
												value={value?.toFixed(0)}
												monthSelected={monthSelected}
												fixated={monthSelected?.contractSelected?.basis_volume}
												listAcceptOrders={listAcceptOrdersBasis}
												avg={avgBasis}
												avgVolume={avgBasisVolume}
											/>
										);
									}}
								/>
								<Tooltip
									title={
										<>
											<span style={{ color: '#0072bc', fontWeight: 'bold' }}>
												Referência de prêmio no porto de Paranaguá
											</span>
										</>
									}
								>
									<span
										className={styles.circleSpan}
										style={{ color: '#0072bc' }}
									>
										BASIS(%)
									</span>
								</Tooltip>
								<div className={styles.progress}>
									<ProgressBar
										amount={Number(
											getPercent(
												monthSelected?.contractSelected?.basis_volume,
												monthSelected?.contractSelected?.fixated_basis
											).toFixed(2)
										)}
										variableName="basis"
										toolTipAmount={
											monthSelected?.contractSelected?.fixated_basis
										}
										contractMax={monthSelected?.contractSelected?.basis_volume}
									/>
								</div>
							</div>
							<div className={styles.circleMain}>
								<Progress
									style={{ maxWidth: '70%' }}
									type="circle"
									percent={Number(
										getPercent(
											monthSelected?.contractSelected?.dolar_volume,
											monthSelected?.contractSelected?.fixated_dolar
										).toFixed(2)
									)}
									strokeColor="#66bc46"
									trailColor="#061417"
									showInfo={true}
									strokeLinecap="round"
									strokeWidth={8}
									format={(value) => {
										return (
											<CarouselArrows
												value={value?.toFixed(0)}
												monthSelected={monthSelected}
												fixated={monthSelected?.contractSelected?.dolar_volume}
												listAcceptOrders={listAcceptOrdersDolar}
												avg={avgDolar}
												avgVolume={avgDolarVolume}
											/>
										);
									}}
									// success={{ percent: 1 }}
								/>
								<Tooltip
									title={
										<>
											<span style={{ color: '#66bc46', fontWeight: 'bold' }}>
												Referência de cotação de dólar BM&F
											</span>
										</>
									}
								>
									<span
										className={styles.circleSpan}
										style={{ color: '#66bc46' }}
									>
										DÓLAR(%)
									</span>
								</Tooltip>
								<div className={styles.progress}>
									<ProgressBar
										amount={Number(
											getPercent(
												monthSelected?.contractSelected?.dolar_volume,
												monthSelected?.contractSelected?.fixated_dolar
											).toFixed(2)
										)}
										variableName="dolar"
										toolTipAmount={
											monthSelected?.contractSelected?.fixated_dolar
										}
										contractMax={monthSelected?.contractSelected?.dolar_volume}
									/>
								</div>
							</div>
							<div className={styles.remainingDays}>
								<CircleDay
									days={
										monthSelected?.contractSelected?.days_to_end < 0
											? '-'
											: monthSelected?.contractSelected?.days_to_end.toString()
									}
								/>
							</div>
						</div>
					</div>
				</>
			) : null}
		</div>
	);
};

export default FixedContainer;
