import {
  State,
  Action,
  VALUE_QUOTE_SELECTED,
  SELECTED_MONTH_GRAPHIC,
  VALUE_ID_MONTH_SELECTED,
  SEND_CREATE_NEGOTIATION_SPOT,
  SEND_CREATE_NEGOTIATION_SPOT_FAIL,
  SEND_CREATE_NEGOTIATION_SPOT_SUCCESS,
} from './types';

const INITIAL_STATE: State = {
  fail: false,
  loading: false,
  valueQuote: undefined,
  monthSelectedGraphic: '',
  orderCreateResponse: null,
  selectedIdMonth: undefined,
};

const createSpotReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case SEND_CREATE_NEGOTIATION_SPOT:
      return {
        ...state,
        loading: true,
      };
    case SEND_CREATE_NEGOTIATION_SPOT_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    case SEND_CREATE_NEGOTIATION_SPOT_SUCCESS:
      return {
        ...state,
        loading: false,
        orderCreateResponse: action.payload.data,
      };
    case SELECTED_MONTH_GRAPHIC:
      return {
        ...state,
        loading: false,
        monthSelectedGraphic: action.payload?.monthSelectedGraphic,
      };
    case VALUE_QUOTE_SELECTED:
      return {
        ...state,
        loading: false,
        valueQuote: action.payload?.valueQuote,
      };
    case VALUE_ID_MONTH_SELECTED:
      return {
        ...state,
        loading: false,
        selectedIdMonth: action.payload?.selectedIdMonth,
      };
      default: {
        return {
          ...state,
        };
      }
    }
  };

  export default createSpotReducer;