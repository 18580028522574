import {
  FETCH_PENDING_ORDERS,
  FETCH_PENDING_ORDERS_SUCESS,
  FETCH_PENDING_ORDERS_FAIL,
  CREATE_ORDER,
  CREATE_ORDER_FAIL,
  CREATE_ORDER_SUCCESS,
  CANCEL_ORDER,
  CANCEL_ORDER_FAIL,
  CANCEL_ORDER_SUCCESS,
  FETCH_ACCEPTED_ORDERS,
  FETCH_ACCEPTED_ORDERS_FAIL,
  FETCH_ACCEPTED_ORDERS_SUCCESS,
  CancelOrderPayload,
  Order,
  ConfirmOrderPayload,
} from "./types";
import { Dispatch } from "redux";
import Api from "../../../api/api";
import { notification } from 'antd';

type Data = {
	contract_id: number;
};

type Error = {
	error: string;
	detail: [
		{
			loc: [string, string];
			msg: string;
			type: string;
		}
	];
};

export const fetchPendingOrders =
	({ contract_id }: Data) =>
	(dispatch: Dispatch) => {
		dispatch({ type: FETCH_PENDING_ORDERS });

		Api.get(`/user/contract/order/pendents/getall?contract_id=${contract_id}`, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		})
			.then((res) => {
				if (res.status === 200) {
					const getAllFixated = (variable: string) => {
						let variableArray = res.data?.filter(
							(item: Order) => item.variable === variable
						);
						var variableTotal: number = 0;

						variableArray?.forEach((item: Order) => {
							variableTotal = item.fixated + variableTotal;
						});
						return variableTotal;
					};

					dispatch({
						type: FETCH_PENDING_ORDERS_SUCESS,
						payload: {
							data: res.data,
							total: {
								cbot: getAllFixated('cbot'),
								dolar: getAllFixated('dolar'),
								basis: getAllFixated('basis'),
							},
						},
					});
				} else {
					dispatch({ type: FETCH_PENDING_ORDERS_FAIL });
				}
			})
			.catch((err) => dispatch({ type: FETCH_PENDING_ORDERS_FAIL }));
	};

export const createOrder =
	(payload: any, clearState: () => void) => (dispatch: Dispatch) => {
		dispatch({
			type: CREATE_ORDER,
		});

		const renderMessage = (
			message: string,
			variable?: string,
			variable_two?: string
		) => {
			switch (message) {
				case 'excess of volume':
					return 'Excesso de Volume';
				case 'order already exists':
					return 'Solicitação de precificação já existe';
				case `${variable} cannot be negative`:
					return 'Não deve ser ser negativo';
				case `${variable_two} cannot be greater than 30 trading sessions`:
					return 'Não deve ser superior a 30 sessões de negociação';
				case 'valid_until invalid':
					return 'Quantidade de dias selecionado inválida ou mês selecionado não está mais disponível para solicitação de precificação';
				case `${variable} volume`:
					return 'Falha ao enviar solicitação de precificação - necessário fixar o flat price antes';
				case `if ${variable} was filled, ${variable_two} is required`:
					return `${variable_two} é requerido`;
				case `if ${variable_two} was filled, ${variable} is required`:
					return `${variable} é requerido`;
				case 'at least one volume needs to be filled':
					return 'Pelo menos um volume precisa ser preenchido';
				case 'only one volume needs to be filled':
					return 'Apenas um volume precisa ser preenchido';
				default:
					return 'Não foi possível enviar a solicitação de precificação';
			}
		};

		Api.post('user/order/create', payload, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		}).then((res) => {
			if (res.status === 200) {
				dispatch({
					type: CREATE_ORDER_SUCCESS,
					payload: {
						data: res.data,
					},
				});
				clearState();
				notification.success({
					message: 'Solicitação de precificação criada com sucesso',
					duration: 8,
				});

				if (res.data.to_tomorrow) {
					notification.warn({
						message:
							'Horário para solicitação do dia encerrado, sua solicitação de precificação foi enviada para o próximo dia',
						duration: 8,
					});
				}
			} else {
				const err = res.data as Error;

				dispatch({
					type: CREATE_ORDER_FAIL,
					payload: {
						error: err.error,
					},
				});

				const field_err = [
					'cbot_vol',
					'basis_vol',
					'valid_until',
					'dolar',
					'cbot',
					'basis',
					'dolar',
				];

				if (err.detail) {
					const err_api = err?.detail[0]?.loc;
					const err_api_msg = err?.detail[0]?.msg;

					const err_field = err_api[1];
					const err_field_variable = err_api[1].split('_')[0];

					const err_filtered = field_err.find((err) => err === err_field);

					notification.warn({
						message: renderMessage(
							err_api_msg,
							err_field_variable,
							err_filtered
						),
						duration: 5,
					});

					return;
				}

				notification.warn({
					message: renderMessage(err.error),
					duration: 5,
				});
				return;
			}
		});
	};

export const cancelOrder =
	(payload: CancelOrderPayload) => (dispatch: Dispatch) => {
		dispatch({
			type: CANCEL_ORDER,
		});

		Api.post('user/order/pendents/cancel', payload, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		}).then((res) => {
			if (res.status === 200) {
				dispatch({
					type: CANCEL_ORDER_SUCCESS,
					payload: { data: true },
				});
			} else {
				dispatch({
					type: CANCEL_ORDER_FAIL,
				});
				notification.warn({
					message:
						'Não foi possível cancelar a solicitação de precificação, tente novamente!',
					duration: 5,
				});
			}
		});
	};

export const confirmOrder =
	(payload: ConfirmOrderPayload) => (dispatch: Dispatch) => {
		dispatch({
			type: CANCEL_ORDER,
		});

		Api.post('/user/order/approvedeny', payload, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		}).then((res) => {
			if (res.status === 200) {
				dispatch({
					type: CANCEL_ORDER_SUCCESS,
					payload: { data: true },
				});
			} else {
				dispatch({
					type: CANCEL_ORDER_FAIL,
				});
				notification.warn({
					message:
						'Não foi possível confirmar a solicitação de precificação, tente novamente!',
					duration: 5,
				});
			}
		});
	};

export const fetchAcceptedOrders =
	(contract_id: string, month_id: number, variable: string) =>
	(dispatch: Dispatch) => {
		dispatch({
			type: FETCH_ACCEPTED_ORDERS,
		});

		let month_id_url = '';
		if (month_id && month_id > 0 && month_id !== undefined) {
			month_id_url = '&month_id=' + month_id;
		}

		let variableUrl = '';
		if (variable != null && variable !== undefined && variable !== '') {
			variableUrl = '&variable=' + variable;
		}

		Api.get(
			'/user/contract/order/concluded/getall?contract_id=' +
				contract_id +
				month_id_url +
				variableUrl,
			{
				headers: {
					authorization: `${localStorage.getItem('token')}`,
				},
			}
		)
			.then((res) => {
				if (res.status === 200 && res.data) {
					dispatch({
						type: FETCH_ACCEPTED_ORDERS_SUCCESS,
						payload: {
							data: res.data,
						},
					});
				} else {
					dispatch({
						type: FETCH_ACCEPTED_ORDERS_FAIL,
					});
				}
			})
			.catch((err) => dispatch({ type: FETCH_ACCEPTED_ORDERS_FAIL }));
	};
