import React, { useCallback, useEffect, useState } from "react";
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import SiderComponent from '../../../components/Sider';
import PageFooter from '../../../components/Footer';
import FrameGraphic from './Graphic';
import InformationHeader from './InformationHeader';
import ModalNote from './ModalNote';
import ApproveModalNote from './ApproveModalNote';

import { AppState } from '../../../rootReducer';
import { fetchContractInfo, fetchContractMonthInfo } from '../modules/actions';
import { getSelectedContractInfo } from '../../Main/Contracts/actions';
import { getAllProducts } from '../../../components/SelectFrame/modules/actions';
import {
	selectedProductIndex,
	setContractHeader,
	setProductHeader,
} from '../../../components/SelectFrame/components/modules/actions';

import {
	ContractInfo,
	MonthSelected,
	OptionGraphicSelected,
	ContractsHistoryMonth,
	FrameNextPreviewSelected,
} from '../../Main/Contracts/types';
import {
	PendenciesContractInfo,
	PendenciesContractMonthInfo,
} from '../modules/types';

import styles from './theme/index.module.scss';
import Formula from '../../../components/formula';

type Params = {
	id: number;
	contract_name: string;
	id_product: number;
};

const InfoPendencies: React.FC = () => {
	const { Content } = Layout;
	const dispatch = useDispatch();
	const history = useHistory();
	const { state } = useLocation<Params>();

	const [visible, setVisible] = useState<'review' | 'accepted' | ''>('');

	const { contractsWhitProducts } = useSelector(
		(appState: AppState) => appState.product
	);
	const { color } = useSelector((appState: AppState) => appState.infoHeader);

	const {
		monthSelected,
		contractInfo,
	}: {
		contractshistoryMonth: ContractsHistoryMonth[];
		monthSelected: MonthSelected;
		frameNextPreviewSelected: FrameNextPreviewSelected;
		contractInfo: ContractInfo;
		optionGraphicSelected: OptionGraphicSelected;
	} = useSelector((state: AppState) => state.contracts);

	const {
		pendenciesContractInfo,
	}: {
		pendenciesContractInfo: PendenciesContractInfo;
	} = useSelector((state: AppState) => state.contractsPending);

	const {
		pendenciesContractMonthInfo,
	}: {
		pendenciesContractMonthInfo: PendenciesContractMonthInfo[];
	} = useSelector((appState: AppState) => appState.contractsPending);

	const switchHeaderProduct = useCallback(
		(product_base: number) => {
			switch (product_base) {
				case 1:
					dispatch(setProductHeader('Farelo de Soja', 'D2955D'));
					dispatch(selectedProductIndex(0));
					break;
				case 2:
					dispatch(setProductHeader('Milho', 'E9D205'));
					dispatch(selectedProductIndex(1));
					break;
				case 3:
					dispatch(setProductHeader('Óleo de Soja', 'EF8F01'));
					dispatch(selectedProductIndex(2));
					break;
				default:
					break;
			}
		},
		[dispatch]
	);

	let contractIdSelectedStorage = localStorage.getItem('contractIdSelected');

	useEffect(() => {
		dispatch(fetchContractInfo(Number(state.id)));
		dispatch(fetchContractMonthInfo(Number(state.id)));
		dispatch(setContractHeader(state.contract_name, state.id));
	}, [dispatch, state, switchHeaderProduct]);

	useEffect(() => {
		dispatch(getAllProducts());
	}, [dispatch]);

	useEffect(() => {
		dispatch(getSelectedContractInfo(Number(contractIdSelectedStorage)));
	}, [contractIdSelectedStorage, dispatch]);

	const infos = {
		Fee: `${pendenciesContractInfo?.fee} R$`,
		Imposto: pendenciesContractInfo?.tax,
		Encargos: `${pendenciesContractInfo?.encargos}% a.m`,
		spread: pendenciesContractInfo?.spread,
		factor: pendenciesContractInfo?.factor,
		icms: pendenciesContractInfo?.icms,
		juros: pendenciesContractInfo?.juros,
		Frete: pendenciesContractInfo?.frete,
	};

	const showInfos = (infos: any) => {
		let parsedObj: Record<string, string> = {};
		const key = Object.keys(infos);

		return key.map((value) => {
			if (infos[value] !== null) {
				parsedObj = { ...parsedObj, [value]: infos[value] };

				return (
					<div key={value}>
						<p>{value}</p>
						<p>{parsedObj[value]}</p>
					</div>
				);
			}
			return null;
		});
	};

	const addNumbers = () => {
		let total = 0;
		let totalReturn = "";
		for (let value = 0; value < pendenciesContractMonthInfo.length; value++) {
			total += pendenciesContractMonthInfo[value].frame_quote;
			
		}
		total = Number(total.toFixed(2));
		if(pendenciesContractInfo?.currency == 'reais'){
			totalReturn = total.toLocaleString('pt-BR', { 
				style: "currency", 
				currency: "BRL"
			  });
		}else{
			totalReturn = total.toLocaleString('us-US', { 
				style: "currency", 
				currency: "USD"
			  });
		}
		
		return totalReturn;
	};

	const openModal = () => {
		switch (visible) {
			case 'accepted':
				return (
					<ApproveModalNote
						setVisible={setVisible}
						currency={pendenciesContractInfo?.currency}
						id={pendenciesContractInfo?.id}
						fee={pendenciesContractInfo?.fee}
					/>
				);

			case 'review':
				return (
					<ModalNote
						setVisible={setVisible}
						currency={pendenciesContractInfo?.currency}
						id={pendenciesContractInfo?.id}
						fee={pendenciesContractInfo?.fee}
					/>
				);

			default:
				break;
		}
	};

	return (
		<Layout>
			<SiderComponent
				contracts={contractsWhitProducts}
				monthSelected={monthSelected}
				contractInfo={contractInfo}
			/>

			<Layout
				className="site-layout"
				style={{
					minHeight: '100vh',
					justifyContent: 'center',
					backgroundColor: '#0B1E22',
					left: 290,
					position: 'absolute',
					width: 'calc(100% - 290px)',
				}}
			>
				<Content
					style={{
						paddingRight: 20,
						marginTop: '90px',
						marginBottom: '50px',
						marginLeft: '16px',
						marginRight: '30px',
						backgroundColor: '#07191d',
					}}
				>
					<div
						style={{
							width: 'calc(100% - 340px)',
							top: 6,
							position: 'fixed',
							zIndex: 999,
							background: 'none',
						}}
					>
						<InformationHeader
							style={{
								marginLeft: 5,
								background: `linear-gradient(90deg, #000000 27.71%, #${
									color || 'D2955D'
								} 75.91%)`,
							}}
						/>
					</div>

					<section className={styles.container}>
						<div className={styles.container_equation}>
							{/* <div aria-disabled="true"> */}
							
							<div className={styles.container_fee} style={{height: 127}}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										height: 10,
										marginBottom: 15,
										marginTop: -5
									}}
								>
									<hr style={{ border: '1px solid #666666', width: '30%' }} />
									Formula do frame
									<hr style={{ border: '1px solid #666666', width: '30%' }} />
								</div>
								<span className={styles.spanWrapper}>
									<Formula contractInfo={pendenciesContractInfo} exibirValor={false} carregarBackground={true} />
								</span>
							</div>
							{/* </div> */}
							<br></br><br></br>
							<div className={styles.expressionContainer}>
								<span className={styles.spanWrapper}>
									<Formula contractInfo={pendenciesContractInfo} exibirValor={true} carregarBackground={true} />
								</span>
							</div>
							{/* <div className={styles.info}>{showInfos(infos)}</div> */}
						</div>

						{/* <main>
							<div className={styles.container_fee}>
								<div>
									<strong>Comparativo de FEE</strong>
								</div>
								<div className={styles.container_fee_info}>
									<div className={styles.total_volume}>
										<span>Valor do FEE atual</span>
										<h4>
											{Number(pendenciesContractInfo?.fee)?.toFixed(2).toLocaleString() || 0}
										</h4>
									</div>

									<hr
										style={{
											border: '1px solid #727272', 
											transform: 'rotate(90deg)',
											width: '70px',
											marginTop: '50px',
											// marginLeft: '70px',
										}}
									/>

									<div className={styles.total_volume}>
										<span>Valor ajustado</span>
										<h4> {Number(pendenciesContractInfo?.company_fee)?.toFixed(2).toLocaleString() || 0} </h4>
									</div>
								</div>
							</div>

							<div className={styles.container_fee}>
								<div className={styles.container_fee_info}>
									<div className={styles.total_volume}>
										<span>Toneladas contratadas</span>
										<h4>{pendenciesContractInfo?.total_volume?.toLocaleString('pt-BR') || 0}</h4>
									</div>

									<hr
										style={{
											border: '1px solid #727272',
											transform: 'rotate(90deg)',
											width: '70px',
											marginTop: '50px',
										}}
									/>

									<div className={styles.total_volume}>
										<span>Preço de execução de contrato</span>
										<h4>{addNumbers()}</h4>
									</div>
								</div>
							</div>
						</main> */}
					</section>

					<FrameGraphic />
					{openModal()}
					<div className={styles.container_button}>
						<button onClick={() => history.goBack()}>Voltar</button>
						<div>
							{pendenciesContractInfo?.open === false ? (
								<>
									<button disabled>Mandar revisar</button>
									<button disabled>Aprovar Frame</button>
								</>
							) : (
								<>
									<button onClick={() => setVisible('review')}>
										Solicitar ajuste
									</button>
									<button onClick={() => setVisible('accepted')}>
										Aprovar Frame
									</button>
								</>
							)}
						</div>
					</div>
				</Content>
			</Layout>
			<PageFooter />
		</Layout>
	);
};

export default InfoPendencies;