import React, { useState } from "react";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";

import { AppState } from "../../rootReducer";
import styles from "./theme/index.module.scss";
import { ConfigMarket } from "../../modules/Main/Contracts/types";
import { siderHours } from "../../utils/siderHour";

type Props = {
  configMarket: ConfigMarket;
};

const StockTime: React.FC<Props> = ({ configMarket }) => {
  const [visible, setVisibility] = useState<Boolean>(true);

  const {
    color,
    selectedProductName
  } = useSelector((state: AppState) => state.infoHeader);

  return (
    <>
      <div className={styles.mainContainer}>
        <div
          className={styles.iconContainer}
          style={{
            background: `#${color || 'D2955D'}`,
          }}
          onClick={(e) => setVisibility(!visible)}
        >
          <strong>{selectedProductName}</strong>
          <span className={styles.stockTimeSpan}>
            Horário de solicitação de precificação
          </span>
          <span className={styles.stockTimeDays}>De Segunda a Sexta </span>
        </div>
        {visible && (
          <div className={styles.stockTimeClose}>
            
            
            <div className={styles.info}>
              <div className={styles.infoContainer}>
                <div className={styles.info}>
                  <div className={styles.infotxt}>
                    <Tooltip
                          title={
                            <>
                              <span style={{ color: "#ea6f24", fontWeight: "bold" }}>
                              Referência cotação de farelo de soja chicago board of trade (CBOT)
                              </span>
                            </>
                          }
                        >
                    <span className={styles.cbot}> C B O T </span>
                    </Tooltip>
                    <div className={styles.infotxt}>
                      <span>
                        <strong className={styles.spanFont}>Abertura: {siderHours(configMarket.cbot_open)} hs</strong>
                      <br/> 
                        <strong className={styles.spanFont}>Fechamento: {siderHours(configMarket.cbot_close)} hs</strong> 
                      <br/>
                        <strong className={styles.spanFont} style={{color: '#ea6f24', fontWeight: 400}}>100% até o dia 25 de cada mês</strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>


              <div className={styles.infoContainer}>
                <div className={styles.info}>
                  <div className={styles.infotxt}>
                    <Tooltip
                          title={
                            <>
                              <span style={{ color: "#0072bc", fontWeight: "bold" }}>
                              Referência de prêmio no porto de Paranaguá
                              </span>
                            </>
                          }
                        >
                      <span className={styles.basis}> B a s i s </span>
                    </Tooltip>
                    <div className={styles.infotxt}>
                      <span>
                        <strong className={styles.spanFont}>Abertura: {siderHours(configMarket.basis_open)} hs</strong>
                      <br/> 
                        <strong className={styles.spanFont}>Fechamento: {siderHours(configMarket.basis_close)} hs</strong> 
                      <br/>
                        <strong className={styles.spanFont} style={{color: '#0072bc', fontWeight: 400}}>100% até o dia 25 de cada mês</strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>


              <div className={styles.infoContainer} style={{paddingBottom: 10}}>
                <div className={styles.info}>
                  <div className={styles.infotxt}>
                    <Tooltip
                          title={
                            <>
                              <span style={{ color: "#66bc46", fontWeight: "bold" }}>
                              Referência de cotação de dólar BM&F
                              </span>
                            </>
                          }
                        >
                      <span className={styles.dolar}> D ó l a r </span>
                    </Tooltip>
                    <div className={styles.infotxt}>
                      <span>
                        <strong className={styles.spanFont}>Abertura: {siderHours(configMarket.dolar_open)} hs</strong>
                      <br/> 
                        <strong className={styles.spanFont}>Fechamento: {siderHours(configMarket.dolar_close)} hs</strong> 
                      <br/>
                        <strong className={styles.spanFont} style={{color: '#66bc46', fontWeight: 400}}>
                       
                            Volume exatamente igual ao já fixado em Flat Price
                            <Tooltip
                                title={
                                  <>
                                    <span style={{ color: "#ea6f24", fontWeight: "bold" }}>
                                    Referência cotação de farelo de soja chicago board of trade (CBOT)
                                    </span>
                                  </>
                                }
                              >
                              <span
                                style={{
                                  backgroundColor: "#ea6f24",
                                  padding: 2,
                                  fontWeight: "bold",
                                  borderRadius: 5,
                                  color: "white",
                                }}
                              >
                                C
                              </span>
                            </Tooltip>
                            +{" "}
                            <Tooltip
                              title={
                                <>
                                  <span style={{ color: "#0072bc", fontWeight: "bold" }}>
                                  Referência de prêmio no porto de Paranaguá
                                  </span>
                                </>
                              }
                            >
                              <span
                                style={{
                                  backgroundColor: "#0072bc",
                                  padding: 2,
                                  fontWeight: "bold",
                                  borderRadius: 5,
                                  color: "white",
                                }}
                              >
                                B
                              </span>
                            </Tooltip>
                          </strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>




            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default StockTime;
