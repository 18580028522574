import React from "react";
import {
  Legend,
  CartesianGrid,
  AreaChart,
  Area,
  ResponsiveContainer,
  YAxis,
  Tooltip,
  XAxis,
  Bar
} from "recharts";
import { useDispatch } from "react-redux"

import {
  CustomLabelCBOT,
  CustomLabelBasis,
  CustomLabelDolar,
} from "./CustomLabel";
import { ContractsHistoryMonth } from "../../../modules/Main/Contracts/types";

import'../theme/overrides.css'
import { selectedMonthGraphic, selectIdMonth } from "../FrameTable/modules/actions";

type Props = {
  contractshistoryMonth: ContractsHistoryMonth[];
  cbotHide: boolean;
  basisHide: boolean;
  dolarHide: boolean;
  open_closed_basis: boolean;
  open_closed_dolar: boolean;
  open_closed_cbot: boolean;
};

const renderTooltipContent = (o: any) => {
  const { active, payload } = o; 

  return (
    <>
      {active && payload?.length > 0 && (
        <div
          style={{
            paddingRight: 10,
            borderRadius: 5,
            background: '#fff'
          }}
        >
          <ul className="list">
            <li
              key={`1`}
              style={{ color: "#66bc46", fontSize: "18px", fontWeight: "bold" }}
            >
              {`${payload[0].payload.reference_month}`}
            </li>
            <li key={`2`} style={{ color: "#66bc46", fontSize: "18px" }}>
              {`${payload[0].name}: ${payload[0].value.toFixed(1)}`}
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

const LineChartComponent: React.FC<Props> = ({
  contractshistoryMonth,
  cbotHide,
  dolarHide,
  basisHide,
  open_closed_basis,
  open_closed_dolar,
  open_closed_cbot,
}) => {
  const dispatch = useDispatch();

  return (
    <ResponsiveContainer width="100%" height={320}>
      <AreaChart
        height={250}
        data={contractshistoryMonth}
        style={{ color: "white" }}
         onClick={e => {
           dispatch(selectIdMonth(Number(e?.activePayload[0]?.payload?.id)))
           dispatch(selectedMonthGraphic(String(e?.activeLabel)));
         }}
      >
        <CartesianGrid
          height={300}
          strokeDasharray="0 0"
          strokeWidth={0.2}
          style={{ color: "black" }}
        />
        <Legend />
        <YAxis name=" R$/Ton " unit=" R$ " />
        <XAxis interval={0} dataKey="reference_month" />
        <Tooltip content={renderTooltipContent} />

        <Area
          dot={true}
          width={50}
          height={20}
          isAnimationActive={false}
          fill="#7159c1"
          type="monotone"
          dataKey="frame_quote"
          name=" R$/Ton "
          stroke="#66bc46"
          fillOpacity={0.1}
          baseLine={[{ x: 12, y: 15 }]}
          strokeWidth={2}
          hide={cbotHide === false || basisHide === false ? false : true}
          legendType="circle"
        />

        <Area
          dot={false}
          fill="none"
          isAnimationActive={false}
          type="natural"
          dataKey="cbot_quote"
          hide={cbotHide}
          strokeWidth={0}
          fillOpacity={0}
          legendType="none"
          label={(props) => {
            return (
              <CustomLabelCBOT
                {...props}
                open_closed_cbot={open_closed_cbot}
                stroke
                position="bottomRight"
              />
            );
          }}
        />
        <Area
          dot={false}
          fill="#0072bc"
          isAnimationActive={false}
          type="natural"
          dataKey="basis_quote"
          hide={basisHide}
          strokeWidth={0}
          fillOpacity={0}
          legendType="none"
          label={(props) => {
            return (
              <CustomLabelBasis
                {...props}
                open_closed_basis={open_closed_basis}
                stroke
                position="bottomRight"
              />
            );
          }}
        />

        <Area
          dot={false}
          fill="#66bc46"
          isAnimationActive={false}
          type="natural"
          dataKey="dolar_quote"
          hide={dolarHide}
          strokeWidth={0}
          fillOpacity={0}
          legendType="none"
          name="DOLAR"
          label={(props) => {
            return (
              <CustomLabelDolar
                {...props}
                open_closed_dolar={open_closed_dolar}
                stroke
                position="bottomRight"
              />
            );
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
export default LineChartComponent;
