import React, { useEffect, useState } from 'react';
import { Button, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import TopRow from '../../../OrderComponents/TopRow';
import AlertRow from '../../../OrderComponents/Alerts/AlertRow';

import { AppState } from '../../../../rootReducer';
import { createAlert, fetchAlerts } from '../../../../modules/Order/Alerts/actions';
import { getSelectedContractInfo, selectedOptionGraphic, selectedUserContractGetAll } from '../../../../modules/Main/Contracts/actions';
import { AlertCreatePayload, AlertCreateResponse, AlertListResponse } from '../../../../modules/Order/Alerts/types';

import styles from '../theme/index.module.scss';
import OrderRowComponent from '../../../OrderComponents/ActiveAlerts/OrderRowComponent';
import { CreatedOrderStateObject, Order } from '../../../../modules/Order/Orders/types';
import { FrameNextPreviewSelected, MonthSelected } from '../../../../modules/Main/Contracts/types';

type Props = {
	loading: boolean;
	cbotSelected: boolean;
	basisSelected: boolean;
	dolarSelected: boolean;
	negotiation: 'cbot' | 'basis' | 'dolar' | '';
};

const CreateAlertModal: React.FC<Props> = ({
	negotiation,
	basisSelected,
	cbotSelected,
	dolarSelected,
	loading,
}) => {
	const items = ['Variáveis', 'Apontamentos', 'Valores'];

	const dispatch = useDispatch();

	const { valueQuote, selectedIdMonth, monthSelectedGraphic } = useSelector(
		(appState: AppState) => appState.orderSpot
	);

	const [cbotDirection, setCbotDirection] = useState<'>' | '<'>('>');
	const [basisDirection, setBasisDirection] = useState<'>' | '<'>('>');
	const [dolarDirection, setDolarDirection] = useState<'<' | '>'>('<');
	const [frameDirection] = useState<'<' | '>'>('<');

	const [cbotActiveSwitch, setCbotActiveSwitch] =
		useState<boolean>(cbotSelected);
	const [basisActiveSwitch, setBasisActiveSwitch] =
		useState<boolean>(basisSelected);
	const [dolarActiveSwitch, setDolarActiveSwitch] =
		useState<boolean>(dolarSelected);
	const [frameActiveSwitch] = useState<boolean>(false);

	const [triggerCbot, setCbotTrigger] = useState<number>(0);
	const [triggerBasis, setBasisTrigger] = useState<number>(0);
	const [triggerDolar, setDolarTrigger] = useState<number>(0);
	const [triggerFrame] = useState<number>(0);

	const {
		frameNextPreviewSelected,
		monthSelected,
	  }: {
		frameNextPreviewSelected: FrameNextPreviewSelected;
		monthSelected: MonthSelected;
	  } = useSelector((state: AppState) => state.contracts);
	  
	const handleCreate = () => {
		if (
			!cbotActiveSwitch &&
			!basisActiveSwitch &&
			!dolarActiveSwitch &&
			!frameActiveSwitch
		) {
			notification.warn({
				message: 'Selecione os campos corretamente',
				duration: 5,
			});
		} else {
			// let payload: AlertCreatePayload = {
			// 	month_id: Number(selectedIdMonth),
			// 	trigger_cbot: cbotActiveSwitch ? triggerCbot : 0,
			// 	direction_cbot: cbotDirection?.toString(),
			// 	trigger_basis: basisActiveSwitch ? triggerBasis : 0,
			// 	direction_basis: basisDirection?.toString(),
			// 	trigger_dolar: dolarActiveSwitch ? triggerDolar : 0,
			// 	trigger_frame: frameActiveSwitch ? triggerFrame : 0,
			// 	direction_dolar: dolarDirection?.toString(),
			// 	direction_frame: frameDirection?.toString(),
			// };

			let payload: AlertCreatePayload = {
				month_id: Number(selectedIdMonth),
				trigger_cbot: negotiation == 'cbot' ? triggerCbot : 0,
				direction_cbot: negotiation == 'cbot' ? cbotDirection?.toString() : '',
				trigger_basis: negotiation == 'basis' ? triggerBasis : 0,
				direction_basis: negotiation == 'basis' ? basisDirection?.toString() : '',
				trigger_dolar: negotiation == 'dolar' ? triggerDolar : 0,
				direction_dolar: negotiation == 'dolar' ?dolarDirection?.toString() : '',
				trigger_frame: 0,
				direction_frame: '',
			};
		
			dispatch(createAlert(payload));
			dispatch(selectedOptionGraphic('', false, false, false));

		}
	};
	useEffect(() => {
		setCbotTrigger(Number(valueQuote));
		setBasisTrigger(Number(valueQuote));
		setDolarTrigger(Number(valueQuote));
	}, [valueQuote]);

	const {
		alerts,
		alertData,
		toggleAlertData,
	}: {
		alerts: AlertListResponse[];
		alertData: AlertCreateResponse;
		toggleAlertData: AlertCreateResponse;
		loading: boolean;
	} = useSelector((state: AppState) => state.alerts);

	let contractIdSelectedStorage = localStorage.getItem('contractIdSelected');

	useEffect(() => {
		if (selectedIdMonth) {
			dispatch(fetchAlerts(selectedIdMonth));
		}
		if (contractIdSelectedStorage !== '' && contractIdSelectedStorage !== undefined && Number(contractIdSelectedStorage) ) {
		dispatch(
			selectedUserContractGetAll(Number(contractIdSelectedStorage), frameNextPreviewSelected?.prevActive + '')
		);
		}
	}, [alertData, selectedIdMonth, dispatch, toggleAlertData, valueQuote, monthSelectedGraphic]);

	const switchAlertNegotiation = () => {
		switch (negotiation) {
			case 'cbot':
				return (
					<AlertRow
						placeholder="PREÇO"
						variableName={'cbot'}
						directionSwitch={[cbotDirection, setCbotDirection]}
						activeSwitch={[cbotActiveSwitch, setCbotActiveSwitch]}
						triggerHandler={[triggerCbot, setCbotTrigger]}
						switchActive={true}
					/>
				);

			case 'basis':
				return (
					<AlertRow
						placeholder="VOLUME"
						variableName={'basis'}
						directionSwitch={[basisDirection, setBasisDirection]}
						activeSwitch={[basisActiveSwitch, setBasisActiveSwitch]}
						triggerHandler={[triggerBasis, setBasisTrigger]}
						switchActive={true}
					/>
				);

			case 'dolar':
				return (
					<AlertRow
						placeholder="CÂMBIO"
						variableName={'dolar'}
						directionSwitch={[dolarDirection, setDolarDirection]}
						activeSwitch={[dolarActiveSwitch, setDolarActiveSwitch]}
						triggerHandler={[triggerDolar, setDolarTrigger]}
						switchActive={true}
					/>
				);
		}
	};

	const renderAlertCreated = () => {
		let items = [
			'Variável',
			'Preço',
			'Indicador',
			'Mês',
			'Desativar',
			'Excluir',
		];

		return (
			<>
				<TopRow topItems={items} />

				{alerts?.length === 0 ? (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<span
							style={{
								fontWeight: 'bold',
								color: 'white',
								textAlign: 'center',
							}}
						>
							Nenhum alerta encontrado.
						</span>
					</div>
				) : (
					alerts?.map((item) => {
						return (
							<>
								{item.type === negotiation && (
									<OrderRowComponent
										month={`${item.month_ref}`}
										price={item?.trigger}
										variableName={item?.type.replace('frame', 'preço')}
										direction={item?.direction}
										key={item.id.toString()}
										is_active={item?.is_active}
										alert_id={item.id}
									/>
								)}
							</>
						);
					})
				)}
			</>
		);
	};

	return (
		<>
			<div className={styles.modalAlert}>
				{loading ? (
					<p style={{ textAlign: 'center', fontSize: 17 }}>Carregando</p>
				) : (
					<>{renderAlertCreated()}</>
				)}
			</div>

			<div className={styles.popoverContainer}>
				<TopRow topItems={items} />
				{switchAlertNegotiation()}
				<div className={styles.buttonContainer}>
					<Button
						type="primary"
						onClick={() => handleCreate()}
						style={{
							border: 'none',
							backgroundColor: '#5fa046',
							textTransform: 'uppercase',
							marginRight: 10,
						}}
					>
						Adicionar
					</Button>
				</div>
			</div>
		</>
	);
};

export default CreateAlertModal;