import React from "react";
import { Checkbox, notification, Popconfirm } from "antd";
import { useDispatch } from "react-redux";

import VariablesSpan from "../../../VariablesSpan";
import { deleteAlert, toggleAlert } from "../../../../modules/Order/Alerts/actions";

import decline from "../../../../assets/icons/decline.png";
import styles from "./theme/index.module.scss";
import "./theme/overrides.css";

type Props = {
  variableName: string;
  price: string | number;
  month: string;
  direction: string;
  is_active: boolean;
  alert_id: number;
};
const OrderRowComponent: React.FC<Props> = ({
  variableName,
  price,
  month,
  direction,
  is_active,
  alert_id,
}) => {
  const dispatch = useDispatch();

  const handleCancel = (alert_id: number) => {
    dispatch(deleteAlert( alert_id ));
  };

  return (
    <div className={styles.alertRowContainer}>
      <div className={styles.alertRowItem}>
        <VariablesSpan variableName={variableName} height="25px" width="70px" />
      </div>
      <div className={styles.alertRowItem}>{price?.toString().replace(".", ",")}</div>
      <div className={styles.alertRowItem}>
        {direction === "<" ? (
          <span className={styles.indicateSpanLess}>{direction}</span>
        ): 
          <span className={styles.indicateSpan}>{direction}</span>
        }
        
      </div>
      <div className={styles.alertRowItem}>{month}</div>
      <div className={styles.alertRowItem}>
        <Checkbox
          checked={is_active}
          onChange={(e) => dispatch(toggleAlert(alert_id, e.target.checked))}
        />
      </div>
      <div className={styles.alertRowItem}>
      <Popconfirm
          title="Você realmente deseja excluir este alerta?"
          onConfirm={() => handleCancel(alert_id)}
          okText="Sim"
          cancelText="Não"
          onCancel={() =>
            notification.warn({
              message: "Nenhuma alteração foi feita nos seus alertas.",
            })
          }
        >
          <img
            src={decline}
            alt="negar/cancelar"
            style={{ width: "20px", cursor: "pointer" }}
          />
        </Popconfirm>
      </div>
    </div>
  );
};

export default OrderRowComponent;
