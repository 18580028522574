import {
  CreateOrder,
  VALUE_QUOTE_SELECTED,
  SELECTED_MONTH_GRAPHIC,
  VALUE_ID_MONTH_SELECTED,
  SEND_CREATE_NEGOTIATION_SPOT,
  SEND_CREATE_NEGOTIATION_SPOT_FAIL,
  SEND_CREATE_NEGOTIATION_SPOT_SUCCESS,
} from './types';
import { Dispatch } from 'redux';
import Api from '../../../../api/api';
import { notification } from 'antd';


export const createNegotiationSpot = (payload: CreateOrder) => (
  dispatch: Dispatch
) => {

  dispatch({
    type: SEND_CREATE_NEGOTIATION_SPOT,
  });

  const renderMessage = (message: string) => {
		switch (message) {
			case 'excess of volume':
				return 'Excesso de Volume';
			case 'spot price not set':
				return 'Preço spot não definido';
			default:
				return 'Não foi possível enviar a solicitação de precificação';
		}
	};

	Api.post('/user/spot/order/create', payload, {
		headers: {
			authorization: `${localStorage.getItem('token')}`,
		},
	}).then((res) => {
		if (res.status === 200) {
			dispatch({
				type: SEND_CREATE_NEGOTIATION_SPOT_SUCCESS,
				payload: {
					data: res.data,
				},
			});
			notification.success({
				message: 'Intenção de compra enviada com sucesso.',
				duration: 5,
			});
		} else {
			dispatch({
				type: SEND_CREATE_NEGOTIATION_SPOT_FAIL,
			});
			notification.warn({
				message: renderMessage(res.data.error),
				duration: 5,
			});
		}
	});
};

export const selectedMonthGraphic = (monthSelected: string) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SELECTED_MONTH_GRAPHIC,
    payload: {
      monthSelectedGraphic: monthSelected,
    }
  });
};

export const selectedValueQuote = (valueQuote: number) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: VALUE_QUOTE_SELECTED,
    payload: {
      valueQuote,
    }
  });
};

export const selectIdMonth = (selectedIdMonth: number) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: VALUE_ID_MONTH_SELECTED,
    payload: {
      selectedIdMonth,
    }
  });
};
