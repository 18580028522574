import React from "react";

const Terms: React.FC = ({ }) => {
    
    return <div style={{height: 'calc(90vh - 120px)', overflowX: 'auto', textAlign: "justify", position: 'relative', padding: 10}}>


    <object data="https://cargill-server.s3-sa-east-1.amazonaws.com/Termos.pdf?#view=FitH" type="application/pdf" width="100%" height="95%">
      <p><a href="https://cargill-server.s3-sa-east-1.amazonaws.com/Termos.pdf">Baixar documento</a></p>
    </object>
  </div>;
};
export default Terms;
