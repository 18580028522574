import { combineReducers } from "redux";
import AuthReducer from "./modules/Login/reducer";
import ContractReducer from "./modules/Main/Contracts/reducer";
import SimulationReducer from "./modules/Main/Simulation/reducer";
import OrderReducer from "./modules/Order/Orders/reducer";
import alertReducer from "./modules/Order/Alerts/reducer";
import swingReducer from "./modules/Order/Swing/reducer";
import notificationReducer from "./modules/Notifications/reducer";
import userReducer from "./modules/Main/User/reducer";
import AuthRevokeReducer from './components/SideActions/reducer';
import getContractsAndProduct from "./components/SelectFrame/modules/reducer";
import pushInformationHeader from "./components/SelectFrame/components/modules/reducer";
import allContractsPendenciesReducer from "./modules/Pendencies/modules/reduce";
import createSpotReducer from './components/Frame/FrameTable/modules/reducer';

const rootReducer = combineReducers({
	auth: AuthReducer,
	authRevoke: AuthRevokeReducer,
	contracts: ContractReducer,
	simulation: SimulationReducer,
	orders: OrderReducer,
	alerts: alertReducer,
	swings: swingReducer,
	notifications: notificationReducer,
	user: userReducer,
	product: getContractsAndProduct,
	infoHeader: pushInformationHeader,
	contractsPending: allContractsPendenciesReducer,
	orderSpot: createSpotReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
