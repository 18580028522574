import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../../../rootReducer';
import cash from '../../../../assets/money.svg'
import { maskMoney } from '../../../../utils/moneyMask';

import styles from '../theme/index.module.scss';
import ModalConfirmation from '../ModalNote';

type Props = {
  reference_month: string;
  month_id: number;
  spot_price: number;
  frete: string;
  spot_volume: number
  spot_fixated: number
  spot_end_date: number
  tax: string;
  setIndex: React.Dispatch<React.SetStateAction<number | null>>
}

const TooltipSpot: React.FC<Props> = ({
  tax,
  frete,
  month_id,
  spot_price,
  spot_volume,
  spot_fixated,
  spot_end_date,
  reference_month,
  setIndex
}) => {

  const [tonValue, setTonValue] = useState(spot_volume - spot_fixated);
  const [price, setPrice] = useState(spot_price?.toFixed(2) || 0);
  const [visible, setVisible] = useState(false);

  const {color, selectedProductName} = useSelector((state: AppState) => state.infoHeader);

  return (
      <div className={styles.tooltipContainer}>
        <div className={styles.tooltipContent}>
          <img src={cash} alt="cash" className={styles.tooltipCashImg}/>

          <div>
            <span>Precificação BALÇÃO de {reference_month}</span>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
            }}>

            <strong
              style={{
                color: `#${color || 'D2955D'}`
              }}
            >
              {selectedProductName} /&nbsp;
            </strong>
            <strong style={{ color: '#7BB957'  }}>
              {frete}
            </strong>
            </div>
          </div>

        </div>
          <section className={styles.formSection}>
  
            <div>
              <input
                type="number"
                value={(price)}
                step={0.5}
                onChange={(e) => {
                  setPrice(Number(e.target.value) || 0);
                }}
              />
              <p>Preço</p>

            </div>

            <div>
              <input
                type="text"
                value={tonValue}
                onChange={(e) => {
                  if (isNaN(Number(e.target.value))) return 0;

                  setTonValue(Number(e.target.value))
                }}
              />
              <p>TON</p>
            </div>

            <div>
              <input disabled value={spot_end_date} />
              <p>Prazo de pagamento/ Dias</p>
            </div>

            <div>
              <input disabled value={tax}/>
              <p>Tipo de tributação</p>
            </div>
            <button className={styles.cancel} onClick={() => setIndex(null)}>Cancelar</button>
            <button onClick={() => setVisible(true)}>Enviar</button>
          </section>
          
            {visible === true && <ModalConfirmation month_id={month_id} price={Number(price)} setVisible={setVisible} tonValue={tonValue} setIndex={setIndex}/>}
      </div>
);
}

export default TooltipSpot;