import { Button, Checkbox, Select, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import CsvDownloader from "react-csv-downloader";
import { useDispatch } from "react-redux";

import FrameTop from "../../Frame/FrameTop";
import Months from "../../Months";
import TopRow from "../../OrderComponents/TopRow";
import AcceptedRow from "./AcceptedRow";

import { timeFormat } from "../../../utils/timeFormat";

import { selectedMonth } from "../../../modules/Main/Contracts/actions";
import { fetchAcceptedOrders } from "../../../modules/Order/Orders/actions";

import {
  ContractInfo,
  ContractsHistoryMonth,
  FrameNextPreviewSelected,
  MonthSelected,
} from "../../../modules/Main/Contracts/types";
import { Order } from "../../../modules/Order/Orders/types";

import styles from "./theme/index.module.scss";

type Props = {
  contractshistoryMonth: ContractsHistoryMonth[];
  monthSelected: MonthSelected;
  contractInfo: ContractInfo;
  acceptedOrders: Order[] | null;
  loading: boolean;
  frameNextPreviewSelected: FrameNextPreviewSelected;
};

export const AcceptedOrdersMain: React.FC<Props> = ({
  contractshistoryMonth,
  monthSelected,
  contractInfo,
  acceptedOrders,
  loading,
  frameNextPreviewSelected,
}) => {
  const dispatch = useDispatch();

  const [variableSelected, setVariableSelected] = useState<string>("");
  const [textVariableSelected, setTextVariableSelected] = useState<string>(
    "Escolha uma variável"
  );
  const [colorVariableSelected, setColorVariableSelected] =
    useState<string>("white");

  const [spotSelected, setSpotSelected] = useState<boolean>(false);

  let items = [
    "Variável",
    "Volume",
    "Preço",
    "Validade",
    "Permite precificação Parcial",
  ];

  const columns = [
    {
      id: "contrato",
      displayName: "Contrato",
    },
    {
      id: "mês de referência",
      displayName: "Mês de referência",
    },
    {
      id: "data de envio",
      displayName: "Data de envio",
    },
    {
      id: "variavel",
      displayName: "Variável",
    },
    {
      id: "volume",
      displayName: "Volume",
    },
    {
      id: "preco",
      displayName: "Preço",
    },
    {
      id: "validade",
      displayName: "Validade",
    },
    {
      id: "tipo",
      displayName: "Tipo",
    },
    {
      id: "identificador",
      displayName: "Identificador",
    },
  ];

  const [dataCsv, setDataCsv] = useState<any[]>([]);

  let itemTmp = {
    contrato: " --- ",
    "mês de referência": " --- ",
    "data de envio": " --- ",
    variavel: " --- ",
    volume: " --- ",
    preco: " --- ",
    validade: " --- ",
    tipo: " --- ",
    identificador: " --- ",
  };
  useEffect(() => {
    if (acceptedOrders) {
      let dataFinal = [];
      for (let item of acceptedOrders) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        itemTmp = {
          contrato: "" + contractInfo?.id + " - " + contractInfo?.name + "",
          "mês de referência": "" + item?.reference_month + "",
          "data de envio":
            "" + timeFormat(item?.date).format("DD/MM/YYYY - HH:mm") + "",
          variavel: "" + item?.variable + "",
          volume: "" + item?.fixated + "",
          preco: "" + item?.price + "",
          validade:
            "" +
            timeFormat(item?.expiration_date).format("DD/MM/YYYY - HH:mm") +
            "",
          tipo: item?.partial ? "Parcial" : "Normal",
          identificador: "" + item?.id + "",
        };
        dataFinal.push(itemTmp);
      }
      setDataCsv(dataFinal);
    }
  }, [acceptedOrders, loading]);

  let contractshistoryMonthTmp: ContractsHistoryMonth[] = contractshistoryMonth;
  let objectList: ContractsHistoryMonth;
  /*
Descomentar para liberar botao todos--aguardando geracao de OS
*/
  useEffect(() => {
    if (contractshistoryMonth && contractshistoryMonth?.length > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      objectList = {
        id: 0,
        base_contract: localStorage.getItem("contractIdSelected")
          ? Number(localStorage.getItem("contractIdSelected"))
          : 0,
        reference_month: "Todos",
        cbot_volume: 0,
        dolar_volume: 0,
        basis_volume: 0,
        frame_quote: 0,
        basis_quote: 0,
        open_closed_cbot: false,
        open_closed_basis: false,
        open_closed_dolar: false,
        dolar_quote: 0,
        cbot_quote: 0,
        fixated_cbot: 0,
        fixated_dolar: 0,
        fixated_basis: 0,
        end_date: "",
        days_to_end: 0,
        frete: "",
        spot_fixated: 0,
        spot_price: 0,
        spot_volume: 0,
        tax: "",
        spot_end_date: 0,
        alerts: [],
      };

      contractshistoryMonthTmp.push(objectList);

      dispatch(
        selectedMonth(
          localStorage.getItem("contractIdSelected")
            ? Number(localStorage.getItem("contractIdSelected"))
            : 0,
          contractshistoryMonthTmp
        )
      );
    }
  }, [contractshistoryMonth]);

  const { Option } = Select;

  const handleChange = (variable: string) => {
    setVariableSelected(variable);
    setSpotSelected(false);
    localStorage.setItem("variable", variable);
    if (contractInfo !== undefined && monthSelected) {
      dispatch(
        fetchAcceptedOrders(contractInfo?.id, monthSelected?.selected, variable)
      );
    }
    switch (variable) {
      case "cbot":
        setTextVariableSelected(
          "Referência cotação de farelo de soja chicago board of trade (CBOT)"
        );
        setColorVariableSelected("#ea6f24");
        return;
      case "basis":
        setTextVariableSelected("Referência de prêmio no porto de Paranaguá");
        setColorVariableSelected("#0072bc");
        return;
      case "dolar":
        setTextVariableSelected("Referência de cotação de dólar BM&F");
        setColorVariableSelected("#66bc46");
        return;
      case "spot":
        setTextVariableSelected("Referência cotação de (SPOT)");
        setColorVariableSelected("#FFD600");
        setSpotSelected(true);
        return;
      default:
        setTextVariableSelected("Escolha uma variável");
        setColorVariableSelected("white");
        return;
    }
  };

  return (
    <div className={styles.pendingContainer}>
      <div className={styles.pendingBody}>
        <FrameTop
          contractshistoryMonth={contractshistoryMonth}
          monthSelected={monthSelected}
          frameNextPreviewSelected={frameNextPreviewSelected}
          contractInfo={contractInfo}
        />
      </div>

      <div className={styles.mainComponent}>
        <div className={styles.selectMonthContainer}>
          <Months
            contractshistoryMonth={contractshistoryMonth}
            monthSelected={monthSelected}
          />
        </div>

        {contractshistoryMonth && contractshistoryMonth.length <= 0 && (
          <div className={styles.frameBodyContainer}>
            <div className={styles.lineChartContainer}>
              <h1
                style={{
                  fontFamily: "helvetica",
                  color: "white",
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                Nenhum mês encontrado!!!
              </h1>
            </div>
          </div>
        )}

        {dataCsv != null && (dataCsv.length > 0 || variableSelected !== "") ? (
          <div style={{ padding: "30px", textAlign: "center" }}>
            <div style={{ width: "54%", float: "left" }}>&nbsp;</div>

            <div
              style={{
                width: "5%",
                marginRight: "10px",
                fontWeight: "bold",
                color: "white",
                backgroundColor: "rgb(102, 188, 70)",
                border: "none",
                borderRadius: "2px",
                height: "30px",
                float: "left",
                minWidth: "100px",
              }}
            >
              <div className={styles.alertRowItem} style={{ marginTop: "4px" }}>
                <Checkbox
                  checked={spotSelected}
                  onChange={(e) => setSpotSelected(!spotSelected)}
                />
                <span style={{ color: "#FFF" }}>Balção</span>
              </div>
            </div>
            <div style={{ width: "5%", float: "left" }}>&nbsp;</div>
            <div style={{ width: "15%", float: "left" }}>
              <Tooltip
                title={
                  <>
                    <span
                      style={{
                        color: colorVariableSelected,
                        fontWeight: "bold",
                      }}
                    >
                      {textVariableSelected}
                    </span>
                  </>
                }
              >
                <Select
                  className={styles.antSelectSelector}
                  onSelect={(option) => handleChange(option)}
                  style={{
                    marginRight: 10,
                    fontWeight: "bold",
                    color: "white",
                    backgroundColor: "rgb(102, 188, 70)",
                    border: "none",
                    borderRadius: "2px",
                    width: "auto",
                    height: "30px",
                    float: "right",
                    minWidth: "140px",
                  }}
                  showArrow={true}
                  bordered={false}
                  placeholder="Filtrar por variável"
                  defaultValue="Filtrar por variável"
                >
                  <Option
                    style={{
                      color: "gray",
                      backgroundColor: "transparent",
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                    value=""
                  >
                    Filtrar por variável
                  </Option>

                  <Option
                    style={{
                      color: "#ea6f24",
                      width: "auto",
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                    value="cbot"
                  >
                    CBOT
                  </Option>

                  <Option
                    style={{
                      color: "#0072bc",
                      width: "auto",
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                    value="basis"
                  >
                    BASIS
                  </Option>

                  <Option
                    style={{
                      color: "#66bc46",
                      width: "auto",
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                    value="dolar"
                  >
                    DOLAR
                  </Option>

                  <Option
                    style={{
                      color: "#FFD600",
                      width: "auto",
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                    value="spot"
                  >
                    BALCÃO
                  </Option>
                </Select>
              </Tooltip>
            </div>
            <div style={{ width: "15%", float: "left" }}>
              <CsvDownloader
                children={
                  <Button
                    size="small"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      backgroundColor: "#66bc46",
                      border: "none",
                      borderRadius: "2px",
                      width: "auto",
                      height: "30px",
                      float: "right",
                    }}
                  >
                    BAIXAR CSV
                  </Button>
                }
                filename="AcceptedOrders"
                separator=";"
                wrapColumnChar=""
                columns={columns}
                datas={dataCsv}
                prefix={false}
                suffix={true}
                text="DOWNLOAD"
              />
            </div>
          </div>
        ) : null}

        {contractshistoryMonth && contractshistoryMonth.length > 0 && (
          <>
            <TopRow topItems={items} />
            {!spotSelected ? (
              <>
                <div>
                  {acceptedOrders
                    ?.filter((item) => item.variable != "spot")
                    .map((item: Order) => (
                      <div key={Math.random()}>
                        <AcceptedRow acceptedOrder={item} />
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <>
                <div>
                  {acceptedOrders?.map((item: Order) => (
                    <div key={Math.random()}>
                      <AcceptedRow acceptedOrder={item} />
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )}

        <div style={{ padding: "15px" }}></div>
      </div>
    </div>
  );
};

export default AcceptedOrdersMain;
