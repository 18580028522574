import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LineChartComponent from "./LineChartComponent";
import FrameTable from "./FrameTable";
import FrameTop from "./FrameTop";
import Months from "../Months";

import { AppState } from "../../rootReducer";
import { useFetch } from "../../api/useFetch";
import { cmaFetch } from "../../api/cmaFetch";
import { selectedUserContractGetAll } from "../../modules/Main/Contracts/actions";

import {
  ContractsHistoryMonth,
  MonthSelected,
  FrameNextPreviewSelected,
  ContractInfo,
  ContractSelectedMonth,
} from "../../modules/Main/Contracts/types";

import { Notification } from "../../modules/Notifications/types";

import styles from "./theme/index.module.scss";
import "./theme/overrides.css";

type Props = {
  contractshistoryMonth: ContractsHistoryMonth[];
  monthSelected: MonthSelected;
  frameNextPreviewSelected: FrameNextPreviewSelected;
  contractInfo: ContractInfo;
};

const FrameContainer: React.FC<Props> = () => {
  const {
    monthSelected,
    contractshistoryMonth,
    contractInfo,
    frameNextPreviewSelected,
  }: {
    monthSelected: MonthSelected;
    contractshistoryMonth: ContractsHistoryMonth[];
    contractInfo: ContractInfo;
    frameNextPreviewSelected: FrameNextPreviewSelected;
  } = useSelector((state: AppState) => state.contracts);

  const [cbotHide, setCbotHide] = useState<boolean>(false);
  const [basisHide, setBasisHide] = useState<boolean>(false);
  const [dolarHide, setDolarHide] = useState<boolean>(false);

  const dispatch = useDispatch();

//FUNCAO QUE ATIVA O FUNCIONAMENTO DO SWR
  const { data } =useFetch<ContractSelectedMonth>();

  cmaFetch<Notification>();

  useEffect(() => {
    if(localStorage.getItem('changeDataGlobalHome') === "true"){
      dispatch(
        selectedUserContractGetAll(
          Number(localStorage.getItem('contractIdSelected')),
          frameNextPreviewSelected?.prevActive + ''
        )
      );
    }
  }, [data])

  return (
    <div 
      onMouseEnter={() => { 
        localStorage.setItem('changeDataGlobalHome', 'true');
      }}
      onMouseLeave={() => { 
        localStorage.setItem('changeDataGlobalHome', 'false');
      }} 
      className={styles.frameMainContainer}>

      <FrameTop
        contractshistoryMonth={contractshistoryMonth}
        monthSelected={monthSelected}
        frameNextPreviewSelected={frameNextPreviewSelected}
        contractInfo={contractInfo}
      />
     
      {contractshistoryMonth && contractshistoryMonth.length === 0 && (
        <>
          <div className={styles.selectMonthContainer}>
            <Months
              contractshistoryMonth={contractshistoryMonth}
              monthSelected={monthSelected}
            />
          </div>

          <div className={styles.frameBodyContainer}>
            <div className={styles.lineChartContainer}>
                <h1 style={{ fontFamily: 'helvetica', color: 'white', fontSize: 18}}>Nenhum mês encontrado para este período!!!</h1>
            </div>
          </div>
        </>
      )}
            
      {contractInfo && contractshistoryMonth &&  contractshistoryMonth.length > 0 && (
        <>
          <div className={styles.selectMonthContainer}>
            <Months
              contractshistoryMonth={contractshistoryMonth}
              monthSelected={monthSelected}
            />
          </div>
          <div className={styles.frameBodyContainer}>
            <div className={styles.lineChartContainer}>
              <LineChartComponent
                contractshistoryMonth={contractshistoryMonth}
                cbotHide={cbotHide}
                basisHide={basisHide}
                dolarHide={dolarHide}
                open_closed_basis={
                  monthSelected?.contractSelected?.open_closed_basis
                }
                open_closed_dolar={
                  monthSelected?.contractSelected?.open_closed_dolar
                }
                open_closed_cbot={
                  monthSelected?.contractSelected?.open_closed_cbot
                }
              />
            </div>
              <div className={styles.frameTable}>
                <FrameTable
                  contractshistoryMonth={contractshistoryMonth}
                  cbotHide={[cbotHide, setCbotHide]}
                  basisHide={[basisHide, setBasisHide]}
                  dolarHide={[dolarHide, setDolarHide]}
                />
              </div>
            </div>
        </>
      )}
    </div>
  );
};

export default FrameContainer;
