import { Tooltip } from "antd";
import React from "react";

type Props = {
  variableName: string;
  height: string | number;
  width: string | number;
};

const VariablesSpan: React.FC<Props> = ({ variableName, height, width }) => {
  switch (variableName) {
    case "cbot":
      return (
        <Tooltip
            title={
              <>
                <span style={{ color: "#ea6f24", fontWeight: "bold" }}>
                Referência cotação de farelo de soja chicago board of trade (CBOT)
                </span>
              </>
            }
          >
            <span
              style={{
                color: "white",
                height,
                width,
                backgroundColor: "#ea6f24",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                fontWeight: "bold",
                borderRadius: 5,
              }}
            >
              {variableName.toUpperCase()}
            </span>
        </Tooltip>
      );
    case "basis":
      return (
        <Tooltip
            title={
              <>
                <span style={{ color: "#0072bc", fontWeight: "bold" }}>
                Referência de prêmio no porto de Paranaguá
                </span>
              </>
            }
          >
          <span
            style={{
              color: "white",
              height,
              width,
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              backgroundColor: "#0072bc",
              fontWeight: "bold",
              borderRadius: 5,
            }}
          >
            {variableName.toUpperCase()}
          </span>
        </Tooltip>
      );
    case "dolar":
      return (
        <Tooltip
            title={
              <>
                <span style={{ color: "#66bc46", fontWeight: "bold" }}>
                Referência de cotação de dólar BM&F
                </span>
              </>
            }
          >
        <span
          style={{
            color: "white",
            height,
            width,
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            backgroundColor: "#66bc46",
            fontWeight: "bold",
            borderRadius: 5,
          }}
        >
          {variableName.toUpperCase()}
        </span>
        </Tooltip>
      );

    case "frame":
      return (
        <span
          style={{
            color: "black",
            height,
            width,
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
            fontWeight: "bold",
            borderRadius: 5,
          }}
        >
          {variableName.toUpperCase()}
        </span>
      );


    case "preço":
      return (
        <span
          style={{
            color: "black",
            height,
            width,
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
            fontWeight: "bold",
            borderRadius: 5,
          }}
        >
          {variableName.toUpperCase()}
        </span>
      );

    case "spot":
      return (
        <span
          style={{
            color: "black",
            height,
            width,
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            backgroundColor: "#FFD600",
            fontWeight: "bold",
            borderRadius: 5,
          }}
        >
          {variableName === 'spot' && 'BALCÃO'}
        </span>
      );
  }
  return <span>{variableName}</span>;
};
export default VariablesSpan;
