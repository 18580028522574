import React, { useEffect, useState } from "react";
import { RouteComponentProps } from 'react-router';
import { Layout, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

//component Import
import SiderComponent from '../../components/Sider';
import PageFooter from '../../components/Footer';
import Simulation from '../../components/Simulation';
import FrameContainer from '../../components/Frame';
import FixedContainer from '../../components/Fixed';
import { logoutUser } from '../../utils/logout';
import InformationHeader from '../../components/Frame/InformationHeader';
import {
	selectedUserContractGetAll,
	selectedMonth,
	getUrlTradingView,
	getSelectedContractInfo,
	selectHoliday,
	selectedOptionGraphic,
	fetchUserContracts,
	getConfigMarket,
} from './Contracts/actions';
import {
	Contracts,
	ContractsHistoryMonth,
	MonthSelected,
	FrameNextPreviewSelected,
	ContractInfo,
	FixatedValues,
	TradingView,
	OptionGraphicSelected,
	ConfigMarket,
} from './Contracts/types';
import { AppState } from '../../rootReducer';

import { getAllProducts } from '../../components/SelectFrame/modules/actions';
import { fetchUserInfo, fetchUserTerms } from './User/actions';

import {
	SimulationResponse,
	MeanData,
	QuickSimulationResponse,
} from './Simulation/types';
import { UserInfo } from './User/types';
//styling
import styles from './theme/index.module.scss';
import './theme/overrides.scss';
import Terms from '../../components/Terms';
import CreateAlertModal from '../../components/Frame/LineChartComponent/CreateAlertModal';
import {
	setContractHeader,
	setProductHeader,
} from '../../components/SelectFrame/components/modules/actions';
import { ResponseContract } from '../Pendencies/modules/types';
import { Products } from "../../components/SelectFrame/modules/types";

const Main: React.FC<RouteComponentProps> = () => {
	const { Content } = Layout;
	const dispatch = useDispatch();

	const {
		contractshistoryMonth,
		monthSelected,
		frameNextPreviewSelected,
		contractInfo,
		monthSelectLoading,
		tradingView,
		optionGraphicSelected,
		loading,
		configMarket,
	}: {
		contracts: Contracts[];
		contractshistoryMonth: ContractsHistoryMonth[];
		monthSelected: MonthSelected;
		frameNextPreviewSelected: FrameNextPreviewSelected;
		contractInfo: ContractInfo;
		loading: boolean;
		fixatedValues: FixatedValues;
		monthSelectLoading: boolean;
		tradingView: TradingView;
		optionGraphicSelected: OptionGraphicSelected;
		configMarket: ConfigMarket
	} = useSelector((state: AppState) => state.contracts);

	const {
		products
	}: {
		products: Products[];
	} = useSelector((state: AppState) => state.product);

	const {
		simulationResponse,
	}: {
		simulationResponse: SimulationResponse | null;
		meanData: MeanData | null;
		quickSimulation: QuickSimulationResponse | null;
	} = useSelector((state: AppState) => state.simulation);

	const [modalVisible, setModalVisible] = useState<boolean>(false);

	const { color } = useSelector((state: AppState) => state.infoHeader);

	const {
		userInfo,
	}: {
		userInfo: UserInfo | null;
	} = useSelector((state: AppState) => state.user);

	const { contractsWhitProducts } = useSelector(
		(state: AppState) => state.product
	);

	const {
		responseContract,
	}: {
		responseContract: ResponseContract;
	} = useSelector((state: AppState) => state.contractsPending);

	const { monthSelectedGraphic } = useSelector(
		(appState: AppState) => appState.orderSpot
	);

	useEffect(() => {
		dispatch(fetchUserInfo());
	}, [dispatch]);

	useEffect(() => {
		if (userInfo !== null && userInfo?.accept_term) {
			dispatch(getAllProducts());
		}
	}, [dispatch, userInfo]);

	let contractIdSelectedStorage = localStorage.getItem('contractIdSelected');

	useEffect(() => {
		if (userInfo !== null && userInfo?.accept_term) {
			if (contractIdSelectedStorage !== '' && contractIdSelectedStorage !== undefined && Number(contractIdSelectedStorage) ) {
				dispatch(
					selectedUserContractGetAll(Number(contractIdSelectedStorage), null)
				);
				dispatch(getSelectedContractInfo(Number(contractIdSelectedStorage)));
				dispatch(selectHoliday(Number(contractIdSelectedStorage)));
			}
		}
	}, [contractIdSelectedStorage]);

	useEffect(() => {
		if (userInfo !== null) {
			localStorage.setItem('user_email', userInfo?.email);
			if (userInfo?.accept_term) {
				setModalVisible(false);
			} else {
				setModalVisible(true);
			}
		}
	}, [userInfo, contractshistoryMonth]);

	useEffect(() => {
		if(responseContract?.contract_id != undefined){
			localStorage.setItem(
				'contractIdSelected',
				responseContract?.contract_id?.toString()
			);
		}
		

		
		switch (responseContract?.product_base) {
			case 1:
				dispatch(setProductHeader('Farelo de Soja', 'D2955D'));
				break;
			case 2:
				dispatch(setProductHeader('Milho', 'E9D205'));
				break;
			case 3:
				dispatch(setProductHeader('Óleo de Soja', 'EF8F01'));
				break;
			default:
				break;
		}
	}, [contractsWhitProducts, dispatch, responseContract]);

	useEffect(() =>{
		if(responseContract != undefined){
			dispatch(
				setContractHeader(responseContract?.name, responseContract?.contract_id)
			);
		}
	}, [responseContract]);
	
	useEffect(() => {
		if (
			contractshistoryMonth &&
			contractshistoryMonth.length > 0 &&
			!monthSelected?.selected &&
			userInfo?.accept_term
		) {
			dispatch(
				selectedMonth(contractshistoryMonth[0].id, contractshistoryMonth)
			);
		}
	}, [contractshistoryMonth, dispatch, monthSelected, userInfo]);

	useEffect(() => {
		if (monthSelected?.selected) {
			dispatch(getUrlTradingView(monthSelected.selected));
		}
	}, [dispatch, monthSelected]);

	useEffect(() => {
		if (contractshistoryMonth && contractshistoryMonth.length > 0) {
			if (
				!contractshistoryMonth?.find(
					(item) => item.id === monthSelected?.selected
				) ||
				!monthSelected?.selected
			) {
				dispatch(
					selectedMonth(contractshistoryMonth[0].id, contractshistoryMonth)
				);
			}
		}
	}, [
		frameNextPreviewSelected,
		monthSelected,
		contractshistoryMonth,
		dispatch,
	]);

	const handleTerms = (termsValue: boolean = true) => {
		dispatch(fetchUserTerms(termsValue));
	};

	return (
		<Layout>
			<SiderComponent
				contracts={contractsWhitProducts}
				monthSelected={monthSelected}
				contractInfo={contractInfo}
			/>

			<Layout
				className="site-layout"
				style={{
					minHeight: '100vh',
					justifyContent: 'center',
					backgroundColor: '#0B1E22',
					left: 290,
					position: 'absolute',
					width: 'calc(100% - 290px)',
				}}
			>
				<div
					style={{
						width: 'calc(100% - 325px)',
						top: 6,
						position: 'fixed',
						zIndex: 999,
						background: 'none',
					}}
				>
					<InformationHeader
						style={{
							marginLeft: 35,
							background: `linear-gradient(90deg, #000000 27.71%, #${
								color || 'D2955D'
							} 75.91%)`,
						}}
					/>
				</div>
				<Content
					style={{
						margin: '16px',
						padding: 20,
						marginTop: 50,
						backgroundColor: '#0B1E22',
						justifyContent: 'space-around',
					}}
				>
					{contractsWhitProducts && contractsWhitProducts?.length > 0 ? (
						<>
						
							<Modal
								title={`Criar alerta para ${optionGraphicSelected?.optionSelected.toUpperCase()} - ${monthSelectedGraphic}`}
								visible={
									optionGraphicSelected?.basis === true ||
									optionGraphicSelected?.cbot === true ||
									optionGraphicSelected?.dolar === true
								}
								onCancel={() =>
									dispatch(selectedOptionGraphic('', false, false, false))
								}
								footer={null}
							>
								<CreateAlertModal
									negotiation={optionGraphicSelected?.optionSelected}
									cbotSelected={
										optionGraphicSelected?.cbot
									}
									basisSelected={
										optionGraphicSelected?.basis
									}
									dolarSelected={
										optionGraphicSelected?.dolar
									}
									loading={loading}
								/>
							</Modal>

							<div className={styles.frameContainer}>
								<FrameContainer
									contractshistoryMonth={contractshistoryMonth}
									monthSelected={monthSelected}
									frameNextPreviewSelected={frameNextPreviewSelected}
									contractInfo={contractInfo}
								/>
							</div>

							{contractshistoryMonth && contractshistoryMonth.length > 0 ? (
								<>
									<div className={styles.fixedContainer}>
										<FixedContainer
											contractshistoryMonth={contractshistoryMonth}
											monthSelected={monthSelected}
											contractInfo={contractInfo}
										/>
									</div>

									<div className={styles.simulationContainer}>
										{monthSelected?.selected && !monthSelectLoading ? (
											<Simulation
												contractshistoryMonth={contractshistoryMonth}
												monthSelected={monthSelected}
												simulationResponse={simulationResponse}
												contractInfo={contractInfo}
											/>
										) : (
											<div className={styles.mainContainer}>
												<div className={styles.simulationTop}>
													<span className={styles.simulationSpan}>
														SIMULAÇÃO
													</span>
												</div>
											</div>
										)}
									</div>

									<div className={styles.tradingViewContainer}>
										<div className={styles.mainContainer}>
											<div className={styles.simulationTop}>
												<span className={styles.simulationSpan}>
													GRÁFICO DE MERCADO COM REFERÊNCIA DE COTAÇÃO DE FARELO
													DE SOJA CHICAGO BOARD OF TRADE
												</span>
											</div>

											{tradingView?.url_overview ? (
												<iframe
													width="100%"
													height="400px"
													title="trading-view-oveview-soja"
													src={'' + tradingView.url_overview + ''}
												/>
											) : null}
										</div>
									</div>

									<div className={styles.tradingViewContainer}>
										<div className={styles.mainContainer}>
											<div className={styles.tradingViewMaxContainer}>
												<div
													className={styles.simulationTop}
													style={{ minHeight: 100 }}
												>
													<span className={styles.simulationSpan}>
														GRÁFICO DE MERCADO COM REFERÊNCIA DE COTAÇÃO DE
														FARELO DE SOJA CHICAGO BOARD OF TRADE
													</span>
												</div>
												{tradingView?.url_grafico ? (
													<iframe
														width="100%"
														height="400px"
														title="trading-view-oveview-soja"
														src={'' + tradingView.url_grafico + ''}
													/>
												) : null}
											</div>

											<div className={styles.tradingViewMaxContainer}>
												<div
													className={styles.simulationTop}
													style={{ minHeight: 100 }}
												>
													<span className={styles.simulationSpan}>
														GRÁFICO DE MERCADO COM REFERÊNCIA DE COTAÇÃO DE
														DOLAR BM&F
													</span>
												</div>
												{tradingView?.url_urd_brl ? (
													<iframe
														width="100%"
														height="400px"
														title="trading-view-oveview-soja"
														src={'' + tradingView.url_urd_brl + ''}
													/>
												) : null}
											</div>
										</div>
									</div>
								</>
							) : null}
						</>
					) : (
						<p
							style={{
								fontSize: 18,
								color: '#fff',
								marginTop: 60,
								textAlign: 'center',
							}}
						>
							Nenhum contrato disponível para este produto
						</p>
					)}
				</Content>
			</Layout>
			<PageFooter />

			<Modal
				title="Termos de uso"
				centered
				okText="Li e aceito"
				cancelButtonProps={{ className: styles.buttonCancelTerms }}
				okButtonProps={{ className: styles.buttonCancelTerms }}
				cancelText="Recusar"
				visible={modalVisible}
				onOk={() => handleTerms(true)}
				onCancel={() => logoutUser()}
				width="99%"
			>
				<Terms />
			</Modal>
		</Layout>
	);
};

export default Main;
