import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import { useDispatch  } from 'react-redux';

import home from "../../assets/icons/main.svg";
import settings from "../../assets/icons/order.svg";
import termsImage from "../../assets/icons/contract.svg";
import accepted from "../../assets/icons/accepted.svg";
import logout from "../../assets/icons/sign-out.svg";
import pendencies from '../../assets/icons/pendings.svg'

import Terms from "../Terms";
import { logoutUser } from "./actions";

import styles from "./theme/index.module.scss";
import { resetContactApproved } from '../../modules/Pendencies/modules/actions';

const SideActions: React.FC = () => {
	const [modalVisible, setModalVisible] = useState<boolean>(false);

	const dispatch = useDispatch();

	const handleLogout = () => {
		dispatch(logoutUser());
	};

	return (
		<div className={styles.actionsContainer}>
			<Link
				to="/main"
				onClick={() => dispatch(resetContactApproved())}
				style={{
					background: window.location.pathname === '/main' ? '#7BB957' : 'none',
					borderRadius: 30,
					justifyContent: 'center',
					alignItems: 'center',
					marginRight: 10,
				}}
			>
				<img alt="Home" src={home} title="Home" className={styles.icons} />
			</Link>

			<Link
				to="/order"
				style={{
					background:
						window.location.pathname === '/order' ? '#7BB957' : 'none',
					borderRadius: 30,
					justifyContent: 'center',
					alignItems: 'center',
					marginRight: 10,
				}}
			>
				<img
					className={styles.icons}
					src={settings}
					alt="Solicitação de precificação"
				/>
			</Link>

			<Link to="/acceptedorders">
				<img
					src={accepted}
					className={styles.icons}
					style={{
						background:
							window.location.pathname === '/acceptedorders'
								? '#7BB957'
								: 'none',
						borderRadius: 30,
						justifyContent: 'center',
						alignItems: 'center',
						marginRight: 10,
					}}
					alt="Solicitações de precificação Aceitas"
				/>
			</Link>

			<Link to="/pendencies">
				<img
					src={pendencies}
					className={styles.icons}
					style={{
						background:
							window.location.pathname === '/pendencies' ||
							window.location.pathname === '/pendencies/pending_contract'
								? '#7BB957'
								: 'none',
						borderRadius: 30,
						justifyContent: 'center',
						alignItems: 'center',
						marginRight: 10,
					}}
					alt="Contratos pendentes"
				/>
			</Link>

			<a onClick={() => setModalVisible(true)}>
				<img
					src={termsImage}
					className={styles.icons}
					alt="Termos de uso"
					style={{
						borderRadius: 30,
						justifyContent: 'center',
						alignItems: 'center',
						marginRight: 10,
						// background: modalVisible === true ? '#7BB957' : 'none',
					}}
				/>
			</a>

			<img
				src={logout}
				onClick={() => handleLogout()}
				className={styles.icons}
				alt="Logout"
			></img>

			<Modal
				title="Termos de uso"
				centered
				okText="Ok"
				visible={modalVisible}
				onOk={() => {
					setModalVisible(false);
				}}
				onCancel={() => {
					setModalVisible(false);
				}}
				cancelButtonProps={{ hidden: true }}
				width="90%"
			>
				<Terms />
			</Modal>
		</div>
	);
};
export default SideActions;
