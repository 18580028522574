import {
  State,
  Action,
  FETCH_NOTIFICATIONS_FAIL,
  FETCH_NOTIFICATIONS_SUCCESS,
  NOTIFICATION_SEEN,
  NOTIFICATION_SEEN_FAIL,
  NOTIFICATION_SEEN_SUCCESS,
  NOTIFICATION_DELETE_SUCCESS,
  NOTIFICATION_DELETE_FAIL,
  NOTIFICATION_DELETE,
} from "./types";

const INITIAL_STATE: State = {
  notificationsList: null,
  seenNotification: null,
  deletedNotification: null,
  fail: false,
  loading: false,
};

const notificationReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsList: action?.payload?.data?.notifications,
      };
    case FETCH_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    case NOTIFICATION_SEEN:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATION_SEEN_SUCCESS:
      return {
        ...state,
        loading: false,
        seenNotification: action.payload.data,
      };
    case NOTIFICATION_SEEN_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    case NOTIFICATION_DELETE:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATION_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        deletedNotification: action.payload.data,
      };
    case NOTIFICATION_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };

    default:
      return {
        ...state,
      };
  }
};

export default notificationReducer;
