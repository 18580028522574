import React, { useEffect, useState } from 'react'
import { Row, Col, Carousel, Spin } from 'antd'
import styles from "./theme/index.module.scss";
import { MonthSelected } from '../../../modules/Main/Contracts/types';
import { Order } from '../../../modules/Order/Orders/types';
import { timeFormat } from '../../../utils/timeFormat';

import iconNext from "../../../assets/icons/nextIcon.png";
import iconPrev from "../../../assets/icons/prevIcon.png";



const SampleNextArrow = (props: any) => {
  const { className, style, onClick } = props
  return (
    <img 
          className={className} 
          src={iconNext} 
          style={{
            ...style,
            display: "block",
            marginRight: "16px",
            width: "15%",
            height: "90%",
            top: "20px",
          }}
            onClick={onClick}
            />
  )
}

const SamplePrevArrow = (props: any) => {
  const { className, style, onClick } = props
  return (
    
      <img 
          className={className} 
          src={iconPrev} 
          style={{
            ...style,
            display: "block",
            marginLeft: "16px",
            width: "15%",
            height: "90%",
            top: "20px",
          }}
            onClick={onClick}
            />
   
  )
}

const settings = {
  dots: false,
  autoplay: false,
  infinite: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />
}

type Props = {
  value: any;
  monthSelected: MonthSelected;
  listAcceptOrders: Order[];
  avg: number;
  avgVolume: number;
  fixated: number
};

const CarouselArrows: React.FC<Props> = ({
  value,
  monthSelected,
  listAcceptOrders,
  avg,
  avgVolume,
  fixated
}) => {

  const [showAvg, setShowAvg] = useState<boolean>(false);
 
  useEffect(() => {
    
    setShowAvg(false);

    if(listAcceptOrders && monthSelected){
      setTimeout(() => {
        setShowAvg(true);
      }, 500);
    }

  }, [listAcceptOrders, monthSelected])
  
  return (
		<>
			<Row justify="center" style={{ height: 80 }}>
				<Col span={16}>
					{!showAvg ? (
						<Spin style={{ top: "35%", position: "absolute", left: "38%" }} />
					) : null}
					{listAcceptOrders && showAvg ? (
						<Carousel arrows {...settings}>
							<div style={{ fontSize: 10 }}>
								<div className="row" style={{ marginTop: "-4px" }}>
									<span className={styles.average}>Média</span>
								</div>

								<span
									style={{
										marginTop: 20,
										fontWeight: "bold",
										fontSize: 14,
										color: "white",
									}}
								>
									{
										// avg
										value > 0
											? avg !== 0
												? (avg / (avgVolume ? avgVolume : 1))
														.toFixed(2)
														.replace(".", ",")
												: "0,00"
											: "-"
									}
								</span>

								<hr
									style={{
										width: "50%",
										color: "white",
										marginTop: "0px",
										marginBottom: "0px",
									}}
								/>
								<div className={styles.bottomProgress}>
									{value.replace(".", ",")}%
								</div>
							</div>

							{listAcceptOrders?.map((item: Order) => (
								<div key={item.id.toString()} style={{ fontSize: 10 }}>
									<div className="row" style={{ marginTop: "-4px" }}>
										<span className={styles.average}>
											{timeFormat(item?.date).format("DD/MM/YY")}
										</span>
									</div>

									<span
										style={{
											marginTop: 20,
											fontWeight: "bold",
											fontSize: 14,
											color: "white",
										}}
									>
										{item.price.toString().replace(".", ",")}
									</span>

									<hr
										style={{
											width: "50%",
											color: "white",
											marginTop: "0px",
											marginBottom: "0px",
										}}
									/>
									<div className={styles.bottomProgress}>
										{avgVolume
											? ((item.fixated * 100) / fixated)
													.toFixed(0)
													.replace(".", ",") + "%"
											: "-"}
									</div>
								</div>
							))}
						</Carousel>
					) : null}
				</Col>
			</Row>
		</>
	);
}

export default CarouselArrows