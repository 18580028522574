import React from "react";
import styles from "./theme/index.module.scss";
import { Tooltip } from "antd";
type Props = {
  variableName: String;
  amount: number;
  toolTipAmount: number;
  contractMax: number;
};
const ProgressBar: React.FC<Props> = ({
  variableName,
  amount,
  toolTipAmount,
  contractMax,
}) => {
  const generateVariableColor = () => {
    switch (variableName) {
      case "cbot":
        return "#ea6f24";
      case "basis":
        return "#0072bc";
      case "dolar":
        return "#66bc46";
    }
  };
  return (
    <div className={styles.mainWrapper}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: "white",
        }}
      >
        <span>{toolTipAmount} ton</span>
        <span>{contractMax} ton</span>
      </div>
      <Tooltip title={toolTipAmount} color="geekblue">
        <div className={styles.progressWrapper}>
          <div
            style={{
              height: 18,
              width: amount + "%",
              backgroundColor: generateVariableColor(),
              borderRadius: 10,
            }}
          ></div>
        </div>
      </Tooltip>
    </div>
  );
};
export default ProgressBar;
