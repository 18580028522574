import React, { useEffect, useState } from "react";
//styles and antd imports
import { Layout, notification } from "antd";
import styles from "./theme/index.module.scss";

import { useDispatch, useSelector } from "react-redux";
// components imports
import SideActions from "../SideActions";
import SelectFrame from "../SelectFrame";
import StockTime from "../StockTime";
import {
  Contracts,
  MonthSelected,
  ContractInfo,
} from "../../modules/Main/Contracts/types";
import SiderInfo from "../SiderInfo";
import SideCalendar from "../SideCalendar";
import SideContractDownload from "../SideContractDownload";
import { AppState } from "../../rootReducer";

import {
	getConfigMarket,
} from './../../modules/Main/Contracts/actions';
import {
  ConfigMarket,
} from './../../modules/Main/Contracts/types';

import logo2 from "../../assets/icons/logo_cargill_sider2.jpg";

type Props = {
  contracts: Contracts[] | null;
  monthSelected: MonthSelected;
  contractInfo: ContractInfo;
};

const SiderComponent: React.FC<Props> = ({
  monthSelected,
  contractInfo,
}) => {

  const dispatch = useDispatch();

  const {
    loading,
    configMarket,
  }: {
    loading: boolean;
    configMarket: ConfigMarket
  } = useSelector((state: AppState) => state.contracts);

  const {
    failRevoke,
    loadingRevoke
  } : {
    failRevoke: boolean;
    loadingRevoke: boolean;
  } = useSelector((state: AppState) => state.authRevoke);

  const { Sider } = Layout;

  const [successAction, setSuccessAction] = useState(false);

  useEffect(() => {
    if(failRevoke) notification.error({message: "Não foi possível realizar o logout!",});
    if(loadingRevoke) setSuccessAction(true)
    if(!loadingRevoke && successAction && !failRevoke) {
      setSuccessAction(false)
      localStorage.clear()
      window.location.reload()
      notification.success({
        message: "Logout realizado com sucesso!",
        duration: 5,
      });
    }
  }, [failRevoke, loadingRevoke, successAction]);


	//carrega dados de abertura e fechamento de mercado
	useEffect(() => {
		dispatch(getConfigMarket());
	}, [dispatch]); 


  return (
    <Sider
      style={{
        backgroundColor: "white",
        justifyContent: "center",
        display: "flex",
        position: "fixed",
        maxHeight: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
        zIndex: 99,
      }}
      width={290}
      collapsible
      collapsedWidth={0}
      breakpoint="lg"
    >
      <div className={styles.logoContainer}>
        <img src={logo2} style={{height: 40}} alt="cargill-logo"></img>
      </div>
      <div className={styles.sideActionsContainer}>
        <span style={{
            color: "white",
            marginTop: 9,
            marginBottom: 10,
            fontWeight: 'bold',
            fontFamily: 'helvetica',
            width: '100%',
            textAlign: "center",
          }}>
          Gestão de Frames
        </span>
        <div className={styles.sideActionRow}>
          <SideActions />
        </div>
      </div>

      <div className={styles.selectFrameContainer}>
        <div className={styles.selectFrame}>
          <SelectFrame />
        </div>
      </div>

      <div className={styles.sideCalendarContainer}>
        <div className={styles.sideCalendar}>
          <SideCalendar monthSelected={monthSelected} />
        </div>
      </div>

      <div className={styles.stockTimeContainer}>
        <div className={styles.stockTime}>
          {!loading && configMarket && <StockTime configMarket={configMarket} />}
        </div>
      </div>
      <div className={styles.infoContainer}>
        {!loading && <SiderInfo contractInfo={contractInfo} />}
      </div>

      <SideContractDownload />
      
    </Sider>
  );
};
export default SiderComponent;
