import React, { useState, useEffect } from 'react';
//styles and antd imports
import styles from './theme/index.module.scss'

import {
  ContractInfo
} from "../../modules/Main/Contracts/types";

import { getContractPDF } from "../../modules/Main/Contracts/actions";

import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../rootReducer";

import { Button } from "antd";


const SideContractDownload:React.FC = () => {

  const dispatch = useDispatch();

  const {
    contractInfo,
    contractDownloading
  }: {
    contractInfo: ContractInfo,
    contractDownloading: boolean
  } = useSelector((state: AppState) => state.contracts);

  const [contractId, setContractId] = useState<string>("");

  useEffect(() => {
    if (contractInfo != null) {
      setContractId(`${contractInfo.id}`);
    }
  }, [contractInfo]);

  function downloadPDF() {
    dispatch(getContractPDF(contractId));
  }

  return (
    <div>
      {(() => {
        if (contractId !== "") {
          return (
            <div className={styles.sideContractDownloadContainer}>
              <div className={styles.sideContractDownload}>
                <Button
                  shape="round"
                  size="middle"
                  loading={contractDownloading}
                  style={{
                    border: "none",
                    width: 190,
                    marginRight: -15,
                    backgroundColor: "black",
                    color: "white",
                    height: "100%",
                    fontFamily: "helvetica",
                    fontSize: 12,
                    fontWeight: 600,
                    padding: 5
                  }}
                  onClick={downloadPDF}
                >
                  BAIXAR CONTRATO
                </Button>
              </div>
            </div>
          )  
        }
      })()}
    </div>
  );

}

export default SideContractDownload