import React, { useState, useEffect } from "react";
//styles and antd imports
import { Checkbox } from "antd";
import NotificationItem from "../NotificationItem";
import {
  Notification,
  NotificationSeenPayload,
} from "../../../modules/Notifications/types";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../rootReducer";
import {
  notificationSeen,
  notificationDelete,
} from "../../../modules/Notifications/actions";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons/";
import styles from "./theme/index.module.scss";

const NotificationList: React.FC = () => {
  const [selectedAlerts, setSelectedAlerts] = useState<Array<number>>([]);
  const [allAlerts, setAllAlerts] = useState<Array<number>>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [resetCheckBox, setResetCheckBox] = useState<boolean>(false);

  const dispatch = useDispatch();

  const {
    notificationsList,
    loading,
  }: {
    notificationsList: Notification[];
    loading: boolean;
    notificationSeen: NotificationSeenPayload;
  } = useSelector((state: AppState) => state.notifications);

  let alertIds: Array<number> = [];
  useEffect(() => {
    notificationsList?.map((item) => 
      alertIds.push(item.id)
    );
    setAllAlerts(alertIds);
  }, [notificationsList]);

  useEffect(() => {
    setSelectAll(false);
  }, [loading]);
  return (
    <div className={styles.mainNotificationListContainer}>
      <div className={styles.options}>
        <Checkbox
          checked={selectAll}
          onChange={() => setSelectAll(!selectAll)}
        />
        <CheckOutlined
          onClick={() => {
            dispatch(
              notificationSeen({ ids: selectAll ? allAlerts : selectedAlerts })
            );
            setSelectedAlerts([]);
            setResetCheckBox(false);
          }}
        />
        <DeleteOutlined
          onClick={() => {
            dispatch(
              notificationDelete({
                ids: selectAll ? allAlerts : selectedAlerts,
              })
            );
            setSelectedAlerts([]);
            setResetCheckBox(false);
          }}
        />
      </div>
      {notificationsList?.map((item: Notification) => {
        return (
					<NotificationItem
						key={item.id.toString()}
						selectedAlerts={[selectedAlerts, setSelectedAlerts]}
						selectAll={selectAll}
						loading={loading}
						id={item.id}
						title={item.notification_type}
						message={item.message}
						status={item.status}
						resetCheckbox={resetCheckBox}
					/>
				);
      })}
    </div>
  );
};
export default NotificationList;
