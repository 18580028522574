export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCTS_FAIL = "FETCH_PRODUCTS_FAIL";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

export const FETCH_CONTRACTS = "FETCH_CONTRACTS";
export const FETCH_CONTRACTS_FAIL = "FETCH_CONTRACTS_FAIL";
export const FETCH_CONTRACTS_SUCCESS = "FETCH_CONTRACTS_SUCCESS";

export type Constants = 
  | typeof FETCH_PRODUCTS
  | typeof FETCH_PRODUCTS_FAIL
  | typeof FETCH_PRODUCTS_SUCCESS

  | typeof FETCH_CONTRACTS
  | typeof FETCH_CONTRACTS_FAIL
  | typeof FETCH_CONTRACTS_SUCCESS;

export type Contracts = {
  id: number;
  name: string;
};

export type Products = {
  id: number;
  name: string;
};

export type State = {
  error: boolean;
  loading: boolean;
  products: Products[];
  contractsWhitProducts: Contracts[];
}

export type Action = {
  type: Constants;
  payload: {
    data: 
    | Products[]
    | Contracts[];
  }
}