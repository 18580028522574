import useSWR from 'swr'

import { ContractsHistoryMonth } from '../modules/Main/Contracts/types';
import api from './api';

export function useFetch<Data = ContractsHistoryMonth[], Error = any>() {
    let urlSend = `/user/contract/month/getall?contract_id=${localStorage.getItem('contractIdSelected')}&next=true`;
    if (localStorage.getItem('frameNextPreviewSelected') != null && localStorage.getItem('frameNextPreviewSelected') == "false") {
        urlSend = `/user/contract/month/getall?contract_id=${localStorage.getItem('contractIdSelected')}&previous=true&next=false`;
    }
    const { data, error, mutate } = useSWR<ContractsHistoryMonth[], Error>(urlSend, async urlSend => {

    const response = await api.get(urlSend, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		});

    return response.data;
    

    }, { refreshInterval: 10000 })

    
  return { data, error, mutate }
}