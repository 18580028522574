import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import milhoSvg from '../../../../assets/milho.svg';
import sojaSvg from '../../../../assets/farelo-soja.svg';
import oleoSvg from '../../../../assets/oleo.svg';

import { selectedProductIndex, setProductHeader } from '../modules/actions'

import { Products } from '../../modules/types'
import styles from "../../theme/index.module.scss";
import { AppState } from "../../../../rootReducer";

type ProductProps = {
  loading: boolean;
  products: Products[];
  selectProduct: (id: number) => void;
};

const SelectProduct: React.FC<ProductProps> = ({
  loading,
  products,
  selectProduct
}) => {

  const dispatch = useDispatch();

  const {
    indexProductSelected, selectedProductName
  } = useSelector((state: AppState) => state.infoHeader);

  const setSwitchProducts = (id: number, index: number) => {
    selectProduct(id)
    dispatch(selectedProductIndex(index))
    localStorage.setItem('productIdSelected', id+'')
  }

  useEffect(() => {

	if(indexProductSelected != undefined){
		switch (indexProductSelected+1) {
			case 1:
				dispatch(setProductHeader('Farelo de Soja', 'D2955D'));
				break;
			case 2:
				dispatch(setProductHeader('Milho', 'E9D205'));
				break;
			case 3:
				dispatch(setProductHeader('Óleo de Soja', 'EF8F01'));
				break;
			default:
				break;
		}
	}
	
  }, [indexProductSelected])
  

  const switchProducts = (name: string) => {
		switch (name) {
			case 'Milho':
				return (
					<img
						style={{height:32, width: 32}}
						src={milhoSvg}
						alt={name}
						title={name}
						onClick={(e) => {
							dispatch(setProductHeader(e.currentTarget.alt, 'E9D205'));
						}}
					/>
				);
			case 'Farelo de Soja':
				return (
					<img
						style={{height:32, width: 32}}
						src={sojaSvg}
						alt={name}
						title={name}
						onClick={(e) => {
							dispatch(setProductHeader(e.currentTarget.alt, 'D2955D'));
						}}
					/>
				);
			case 'Óleo de Soja':
				return (
					<img
						style={{height:32, width: 32}}
						src={oleoSvg}
						alt={name}
						title={name}
						onClick={(e) => {
							dispatch(setProductHeader(e.currentTarget.alt, 'EF8F01'));
						}}
					/>
				);
			default:
				break;
		}
	};

	return (
		<>
			<div className={styles.chooseFrame}>
				<span>Escolha o produtos:</span>
			</div>
			{!products || products?.length <= 0 ? (
				<p>Não encontramos nenhum produto</p>
			) : (
				<div className={styles.frame}>
					{products.map((values, index) => (
						<button
							key={values.id.toString()}
							disabled={loading}
							style={{
								borderWidth: 2.7,
								background: 'transparent',
								borderColor:
									indexProductSelected === index ? 'green' : 'transparent',
							}}
							onClick={() => setSwitchProducts(values.id, index)}
						>
							{switchProducts(values.name)}
						</button>
					))}
				</div>
			)}
		</>
	);
};
export default SelectProduct;
