import {
	State,
	Action,
	PENDENCIES,
	PENDENCIES_FAIL,
	PENDENCIES_SUCCESS,
	CONTRACT_REQUEST,
	CONTRACT_REQUEST_FAIL,
	CONTRACT_REQUEST_SUCCESS,
	CONTRACT_MONTH_INFO_REQUEST,
	CONTRACT_MONTH_INFO_REQUEST_FAIL,
	CONTRACT_MONTH_INFO_REQUEST_SUCCESS,
	SEND_CONTRACT_APPROVED,
	SEND_CONTRACT_APPROVED_FAIL,
	SEND_CONTRACT_APPROVED_SUCCESS,
	RESET_CONTRACT_APPROVED_SUCCESS,
} from './types';

const INITIAL_STATE: State = {
	fail: false,
	loading: false,
	pending: [],
	pendenciesContractInfo: null,
	pendenciesContractMonthInfo: [],
	responseContract: null,
};

const allContractsPendenciesReducer = (
	state = INITIAL_STATE,
	action: Action
) => {
	switch (action.type) {
		case PENDENCIES:
			return {
				...state,
				loading: true,
			};
		case PENDENCIES_SUCCESS:
			return {
				...state,
				loading: false,
				pending: action.payload.data,
			};
		case PENDENCIES_FAIL:
			return {
				...state,
				loading: false,
				fail: true,
			};

		case CONTRACT_REQUEST:
			return {
				...state,
				loading: true,
			};
		case CONTRACT_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				pendenciesContractInfo: action.payload.data,
			};
		case CONTRACT_REQUEST_FAIL:
			return {
				...state,
				loading: false,
				fail: true,
			};

		case CONTRACT_MONTH_INFO_REQUEST:
			return {
				...state,
				loading: true,
			};
		case CONTRACT_MONTH_INFO_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				pendenciesContractMonthInfo: action.payload.data,
			};
		case CONTRACT_MONTH_INFO_REQUEST_FAIL:
			return {
				...state,
				loading: false,
				fail: true,
			};

		case SEND_CONTRACT_APPROVED:
			return {
				...state,
				loading: true,
			};
		case SEND_CONTRACT_APPROVED_SUCCESS:
			return {
				...state,
				loading: false,
				responseContract: action.payload.data,
			};
		case RESET_CONTRACT_APPROVED_SUCCESS:
			return {
				...state,
				loading: false,
				responseContract: null,
			};
		case SEND_CONTRACT_APPROVED_FAIL:
			return {
				...state,
				loading: false,
				fail: true,
			};

		default:
			return {
				...state,
			};
	}
};

export default allContractsPendenciesReducer;
