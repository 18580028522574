import {
    FETCH_SWING,
    FETCH_SWING_FAIL,
    FETCH_SWING_STATE,
    FETCH_SWING_STATE_SUCCESS,
    FETCH_SWING_STATE_FAIL,
    FETCH_SWING_SUCESS
} from "./types";
import { Dispatch } from "redux";
import Api from "../../../api/api";

type data_swing = {
    month_id: number
}
export const fetchSwings =
	({ month_id }: data_swing) =>
	(dispatch: Dispatch) => {
		dispatch({ type: FETCH_SWING });

		Api.get(`user/month/swing?month_id=${month_id}`, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
			timeout: 15000,
		})
			.then((res) => {
				if (res.status === 200) {
					dispatch({
						type: FETCH_SWING_SUCESS,
						data: res.data,
					});
				} else {
					dispatch({ type: FETCH_SWING_FAIL });
				}
			})
			.catch((err) => {
				dispatch({ type: FETCH_SWING_FAIL });
			});
	};

type data_swing_state = {
	contract_id: number;
};
export const fetchStateSwing =
	({ contract_id }: data_swing_state) =>
	(dispatch: Dispatch) => {
		dispatch({ type: FETCH_SWING_STATE });

		Api.get(`user/contract/swing?contract_id=${contract_id}`, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
			timeout: 15000,
		})
			.then((res) => {
				if (res.status === 200) {
					dispatch({
						type: FETCH_SWING_STATE_SUCCESS,
						payload: res.data,
					});
				} else {
					dispatch({ type: FETCH_SWING_STATE_FAIL });
				}
			})
			.catch((err) => {
				dispatch({ type: FETCH_SWING_STATE_FAIL });
			});
	};