import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedContractInfo,
  selectedMonth,
} from "../../modules/Main/Contracts/actions";
import {
  ContractsHistoryMonth,
  MonthSelected,
} from "../../modules/Main/Contracts/types";
import { AppState } from "../../rootReducer";
import styles from "./theme/index.module.scss";

type Month = {
  name: string;
  id: number;
};
type Props = {
  contractshistoryMonth: ContractsHistoryMonth[];
  monthSelected: MonthSelected;
};

const Months: React.FC<Props> = ({ contractshistoryMonth, monthSelected }) => {
  const {
    loading,
  }: {
    loading: boolean;
  } = useSelector((state: AppState) => state.contracts);

  const dispatch = useDispatch();

  const handleSelect = (option: number) => {
    dispatch(selectedMonth(option, contractshistoryMonth));
    let contractIdSelectedStorage = localStorage.getItem("contractIdSelected");
    if (
      contractIdSelectedStorage !== "" &&
      contractIdSelectedStorage !== undefined
    ) {
      dispatch(getSelectedContractInfo(Number(contractIdSelectedStorage)));
    }
  };

  return (
    <div className={styles.topMonthsContainer}>
      {contractshistoryMonth ? (
        <div className={styles.topMonths}>
          <span style={{ width: 100 / contractshistoryMonth.length / 4 + "%" }}>
            -
          </span>
          {contractshistoryMonth?.map((item) => (
            <div
              style={{
                width: 100 / contractshistoryMonth.length + "%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                marginLeft: "20",
              }}
            >
              <span
                key={Math.random()}
                onClick={() => handleSelect(item.id)}
                className={
                  (monthSelected?.selected == null
                    ? 0
                    : monthSelected.selected) === item.id
                    ? styles.monthSpan
                    : styles.monthSpanSelected
                }
              >
                {item.reference_month}
              </span>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
export default Months;
