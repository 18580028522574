export const FETCH_SWING = "FETCH_SWING";
export const FETCH_SWING_FAIL = "FETCH_SWING_FAIL";
export const FETCH_SWING_SUCESS = "FETCH_SWING_SUCESS";
export const FETCH_SWING_STATE = "FETCH_SWING_STATE";
export const FETCH_SWING_STATE_FAIL = "FETCH_SWING_STATE_FAIL";
export const FETCH_SWING_STATE_SUCCESS = "FETCH_SWING_STATE_SUCCESS";

export type Constants =
| typeof FETCH_SWING
| typeof FETCH_SWING_FAIL
| typeof FETCH_SWING_SUCESS
| typeof FETCH_SWING_STATE
| typeof FETCH_SWING_STATE_FAIL
| typeof FETCH_SWING_STATE_SUCCESS

export type Swing = {
    top: number;
    bottom: number;
    flag: number;
    frequency: number;
    current: number;
}

export type SwingCheck = {
    swing: boolean;
}

export type SwingState = {
    fail: boolean;
    loading: boolean;
    loadingCheck: boolean;
    swing: SwingCheck | null;
    swingList: Swing[] | null,
}
  
export type Action = {
    type: Constants;
    payload: SwingCheck;
    data: Swing[];
}
