export const CREATE_ALERT = "CREATE_ALERT";
export const CREATE_ALERT_FAIL = "CREATE_ALERT_FAIL";
export const CREATE_ALERT_SUCCESS = "CREATE_ALERT_SUCCESS";

export const FETCH_ALERTS = "FETCH_ALERTS";
export const FETCH_ALERTS_FAIL = "FETCH_ALERTS_FAIL";
export const FETCH_ALERTS_SUCCESS = "FETCH_ALERTS_SUCESS";

export const TOGGLE_ALERT = "TOGGLE_ALERT";
export const TOGGLE_ALERT_FAIL = "TOGGLE_ALERT_FAIL";
export const TOGGLE_ALERT_SUCCESS = "TOGGLE_ALERT_SUCCESS";

export const SWING_ALERT = "SWING_ALERT";
export const SWING_ALERT_FAIL = "SWING_ALERT_FAIL";
export const SWING_ALERT_SUCCESS = "SWING_ALERT_SUCCESS";

export const BASIS_ALERT = "BASIS_ALERT";
export const BASIS_ALERT_FAIL = "BASIS_ALERT_FAIL";
export const BASIS_ALERT_SUCCESS = "BASIS_ALERT_SUCCESS";

export type Constants =
  | typeof CREATE_ALERT
  | typeof CREATE_ALERT_FAIL
  | typeof CREATE_ALERT_SUCCESS
  | typeof FETCH_ALERTS
  | typeof FETCH_ALERTS_FAIL
  | typeof FETCH_ALERTS_SUCCESS
  | typeof TOGGLE_ALERT
  | typeof TOGGLE_ALERT_FAIL
  | typeof TOGGLE_ALERT_SUCCESS
  | typeof SWING_ALERT
  | typeof SWING_ALERT_FAIL
  | typeof SWING_ALERT_SUCCESS
  | typeof BASIS_ALERT
  | typeof BASIS_ALERT_FAIL
  | typeof BASIS_ALERT_SUCCESS;

export type AlertCreateResponse = {
  id: number;
  month_base: number;
  user_base: number;
  user_email: string;
  type: string;
  trigger: number;
  direction: string;
  is_active: number;
};

export type AlertListResponse = {
  id: number;
  month_base: number;
  user_base: number;
  user_email: string;
  type: string;
  trigger: number;
  direction: string;
  is_active: boolean;
  month_ref: string;
};

export type AlertCreatePayload = {
  month_id: number;
  trigger_cbot: number;
  direction_cbot: string | undefined;
  trigger_basis: number;
  direction_basis: string | undefined;
  trigger_dolar: number;
  direction_dolar: string | undefined;
  direction_frame: string | undefined;
  trigger_frame: number;
};

export type SwingAlertPayload = {
  contract_id: number;
  activate: boolean;
};

export type BasisAlertPayload = {
  activate: boolean;
};
export type State = {
  fail: boolean;
  loading: boolean;
  alertData: AlertCreateResponse[] | null;
  alerts: AlertListResponse[] | null;
  toggleAlertData: AlertCreateResponse | null;
  basisAlertResponse: { status: boolean } | null;
  swingResponse: string;
  loadingSwing: boolean;
  failSwing: boolean;
};

export type AlertsAction = {
  type: Constants;
  payload: {
    data: AlertCreateResponse[] | AlertListResponse[];
  };
};
