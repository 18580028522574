import React, { useState, useEffect } from "react";
import styles from "./theme/index.module.scss";

import TopRow from "../TopRow";
import {
  Order,
  CreatedOrderStateObject,
} from "../../../modules/Order/Orders/types";
import PendingRow from "./PendingRow";
import { useDispatch } from "react-redux";
import {
	cancelOrder,
	confirmOrder,
} from "../../../modules/Order/Orders/actions";
import { Spin } from "antd";
import { UserInfo } from "../../../modules/Main/User/types";

type Props = {
	pendingOrders: Order[] | null;
	createdOrder: CreatedOrderStateObject;
	loading: boolean;
	userInfo: UserInfo | null;
};

const PendingOrders: React.FC<Props> = ({
	pendingOrders,
	loading,
	userInfo,
}) => {
	const dispatch = useDispatch();
	const [pendArray, setPendArray] = useState<Order[] | any>(pendingOrders);
	let items = ["Variável", "Volume", "Preço", "Validade", "Ações", "Viva Voz"];

	useEffect(() => {
		setPendArray(pendingOrders);
	}, [pendingOrders]);

	const handleCancel = (order_id: number) => {
		dispatch(cancelOrder({ order_id }));
	};
	const handleConfirm = (order_id: number, accepted: boolean) => {
		dispatch(confirmOrder({ order_id, accepted }));
	};

	return (
		<div className={styles.pendingContainer}>
			<TopRow topItems={items} />
			<div className={styles.pendingBody}>
				<div
					className={styles.orders}
					style={{ width: "100%", alignSelf: "center" }}
				>
					<Spin
						spinning={loading}
						style={{ textAlign: "center", alignSelf: "center" }}
					>
						{pendArray?.length === 0 ? (
							<span
								style={{
									fontWeight: "bold",
									color: "white",
									textAlign: "center",
									fontFamily: "helvetica",
								}}
							>
								Nenhuma solicitação de precificação encontrada.
							</span>
						) : (
							pendArray?.map((item: Order) => (
								<PendingRow
									pendingOrder={item}
									key={item.id.toString()}
									handleCancel={handleCancel}
									handleConfirm={handleConfirm}
									userInfo={userInfo}
								/>
							))
						)}
					</Spin>
				</div>
			</div>
		</div>
	);
};
export default PendingOrders;
