import React from "react";
import styles from "./theme/index.module.scss";
import SelectMonth from "../../SelectMonth/index";
import {
  ContractsHistoryMonth,
  MonthSelected,
} from "../../../modules/Main/Contracts/types";
import { Switch, Tooltip } from "antd";
import info from "../../../assets/icons/info.png";
import { useDispatch } from "react-redux";
import { SwingCheck } from "../../../modules/Order/Swing/types";
import {
  swingAlertActivate,
  basisAlertActivate,
} from "../../../modules/Order/Alerts/actions";
import { UserInfo } from "../../../modules/Main/User/types";

type Props = {
  contractshistoryMonth: ContractsHistoryMonth[];
  monthSelected: MonthSelected;
  userData: UserInfo | null;
  selectedSwing: SwingCheck | null;
  contractId: number;
  loadingCheck: boolean;
};
const SwingAbove: React.FC<Props> = ({
  contractshistoryMonth,
  monthSelected,
  userData,
  selectedSwing,
  contractId,
  loadingCheck
}) => {
  const dispatch = useDispatch();
  return (
    <div className={styles.swingTopHeader}>
      <span className={styles.componentSpan} style={{ marginLeft: "2%" }}>
        DADOS SWING
      </span>
      <SelectMonth
        months={contractshistoryMonth}
        monthSelected={monthSelected}
      />
      <span
        className={styles.componentSpan}
        style={{ marginLeft: "4%", marginRight: "3px" }}
      >
        ALERTA SWING
      </span>
      <Tooltip title="Você será informado quando cbot ultrapassar um limite superior ou inferior de variação.">
        <img
          src={info}
          alt="info"
          title="info"
          style={{ width: "20px", height: "20px", marginRight: "3px" }}
        />
      </Tooltip>
      <div>
        <Switch
          disabled={!monthSelected}
          loading={loadingCheck}
          checked={selectedSwing?.swing}
          onClick={(value: boolean) => {
            if (contractId){
              dispatch(
                swingAlertActivate({
                  contract_id: contractId,
                  activate: value,
                })
              );
            }
          }}
        />
      </div>
      <span
        className={styles.componentSpan}
        style={{ marginLeft: "4%", marginRight: "3px" }}
      >
        ALERTA BASIS
      </span>
      <Tooltip title="Você será informado quando ocorrer uma mudança no basis.">
        <img
          src={info}
          alt="info"
          title="info"
          style={{ width: "20px", height: "20px", marginRight: "3px" }}
        />
      </Tooltip>
      <div>
        <Switch
          disabled={!monthSelected}
          checked={userData?.receives_basis_alert ? true : false}
          onClick={(value: boolean) =>
            dispatch(
              basisAlertActivate({
                activate: value,
              })
            )
          }
        />
      </div>
     
      
    </div>
  );
};

export default SwingAbove;
