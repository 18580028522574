import React, { useState, useEffect } from "react";

//styling and antd
import styles from "./theme/index.module.scss";
import "./theme/index.css";
import { UserOutlined, LockFilled } from "@ant-design/icons";
import { Input, Button, notification } from "antd";

//routes
import { RouteComponentProps } from "react-router-dom";

//redux imports
import { useDispatch, useSelector } from "react-redux";


import logoFoot from "../../assets/icons/logoFootP.png";
import welcome from "../../assets/icons/welcome.svg";
import cargilFrameImg from "../../assets/icons/cargill-frame-interface.png"


import Loading from "../../components/Loading";
const LoginCallback: React.FC<RouteComponentProps> = (props) => {
  

  const dispatch = useDispatch();
  /*useEffect(() => {
    let teste = 0;
    if (1 === teste) {
      props.history.replace("/main");
    } else {
      props.history.replace("/");
    }
  }, []);*/


  useEffect(() => {
    try{
      let params = props.location.hash;
      let paramsJSON = params?.includes("#") ? JSON.parse('{"' + decodeURI(params.replace("#", "")).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}') : {};
      let token = paramsJSON?.["access_token"]
      if(!token) {
        props.history.replace("/");
        return;
      }

      localStorage.setItem("token", `${token}`);
      props.history.replace("/main");
  } catch (e) {
    props.history.replace("/");
  }
  }, [props.history, props.location.hash]);

  return (
    <div className={styles.pageContainer}>
      <video autoPlay muted loop className={styles.video} preload="auto">
        <source
          src="https://cargill-server.s3-sa-east-1.amazonaws.com/cargill.mp4"
          type="video/mp4"
        ></source>
      </video>
      
      <div className={styles.logoContainer}>
        {/*<span style={{color: 'white', zIndex:999, fontSize: 50, position: 'fixed', top:'33%', left:'10%', fontFamily: 'helvetica', fontWeight: "normal"}}>
          Bem-vindo ao 
          </span>
          <span style={{color: 'white', zIndex:999, fontSize: 112, position: 'fixed', top:'39%', left:'10%', fontFamily: 'helvetica'}}>
          Cargill Frame!
          </span>*/}

        {/*<img
          src="https://cargill-server.s3-sa-east-1.amazonaws.com/logo.svg"
          alt="Frame-Logo"
        ></img>*/}

        <img style={{position: 'absolute', left:'9%', top:'40%', width:'25%', height: '18%', zIndex:1000}}
          src={welcome}
          alt="Frame-Logo"
        ></img>
        <img  style={{position: 'absolute', left:'9%', top: '46%', height: '16%', width: '45%'}}
          src={cargilFrameImg}></img>
      </div>
      <div style={{backgroundColor: "white", position: "fixed", height: 85, bottom: -1, width: '100%', zIndex:1000}}>
        <div style={{position: 'relative',
                  padding: '30px',
                  width: '50vh',
                  minWidth: '50vh',
                  marginLeft: '65%'
                  }}>
          <div style={{justifyContent: 'center',
                      alignItems: 'center',
                  display: 'flex'}}>
                    <img src={logoFoot} alt="logo" style={{width: 120, top: '8%', position: 'absolute'}} />
                    </div>
        </div>
          
      </div>
    </div>
  );
};

export default LoginCallback;
