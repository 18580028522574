import React from 'react';
import { notification } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { sendApproveDeny } from '../../modules/actions';

import styles from '../theme/index.module.scss';
import Loading from '../../../../components/Loading';

type Props = {
	id: number;
	fee: number;
	currency: string;
	setVisible: React.Dispatch<React.SetStateAction<'review' | 'accepted' | ''>>;
};

const ApproveModalNote: React.FC<Props> = ({ id, setVisible }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	var loginButton = <Loading />

	const handlerClickApproved =(id: number) => {

		dispatch(
			sendApproveDeny({
				accepted: true,
				request_id: id,
			})
		);
		setVisible('');
		setTimeout(() => {
			history.push('/main');

			notification.success({
				message: 'Contrato criado com sucesso.',
				duration: 5,
			});
		}, 2000);

		return <div className={styles.buttonContainer}>{loginButton}</div>;
	}

	return (
		<div className={styles.modal_overlay}>
			<div className={styles.modal_wrapper}>
				<div className={styles.modal}>
					<h3>Atenção</h3>
					<div className={styles.fee}>
						<span style={{ textAlign: 'center', fontSize: '22px' }}>
							Deseja aprovar esse contrato
						</span>
					</div>

					<div className={styles.buttons}>
						<button onClick={() => setVisible('')}>Cancelar</button>
						<button
							onClick={() => {
								handlerClickApproved(id);
							}}
						>
							Aprovar
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ApproveModalNote;
