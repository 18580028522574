import React, { useState, useEffect } from "react";
import styles from "./theme/index.module.scss";
import TopRow from "../TopRow";
import AlertRow from "./AlertRow";
import { Button, notification } from "antd";
import { MonthSelected } from "../../../modules/Main/Contracts/types";
import { useDispatch } from "react-redux";
import { AlertCreatePayload } from "../../../modules/Order/Alerts/types";
import { createAlert } from "../../../modules/Order/Alerts/actions";

type Props = {
  monthSelected: MonthSelected;
};
const Alerts: React.FC<Props> = ({ monthSelected }) => {
  let items = ["Variáveis", "Apontamentos", "Valores", "Ativar/Desativar"];
  const dispatch = useDispatch();

  const [cbotDirection, setCbotDirection] = useState<">" | "<">(">");
  const [basisDirection, setBasisDirection] = useState<">" | "<">(">");
  const [dolarDirection, setDolarDirection] = useState<"<" | ">">("<");
  const [frameDirection, setFrameDirection] = useState<"<" | ">">("<");

  const [cbotActiveSwitch, setCbotActiveSwitch] = useState<boolean>(false);
  const [basisActiveSwitch, setBasisActiveSwitch] = useState<boolean>(false);
  const [dolarActiveSwitch, setDolarActiveSwitch] = useState<boolean>(false);
  const [frameActiveSwitch, setFrameActiveSwitch] = useState<boolean>(false);

  const [triggerCbot, setCbotTrigger] = useState<number>(0);
  const [triggerBasis, setBasisTrigger] = useState<number>(0);
  const [triggerDolar, setDolarTrigger] = useState<number>(0);
  const [triggerFrame, setFrameTrigger] = useState<number>(0);

  const handleCreate = () => {
    if (
      !cbotActiveSwitch &&
      !basisActiveSwitch &&
      !dolarActiveSwitch &&
      !frameActiveSwitch
    ) {
      notification.warn({
        message: "Selecione os campos corretamente",
        duration: 5,
      });
    } else {
      let payload: AlertCreatePayload = {
        month_id: monthSelected?.selected,
        trigger_cbot: cbotActiveSwitch ? triggerCbot : 0,
        trigger_basis: basisActiveSwitch ? triggerBasis : 0,
        trigger_dolar: dolarActiveSwitch ? triggerDolar : 0,
        trigger_frame: frameActiveSwitch ? triggerFrame : 0,
        direction_basis: basisDirection?.toString(),
        direction_cbot: cbotDirection?.toString(),
        direction_dolar: dolarDirection?.toString(),
        direction_frame: frameDirection?.toString(),
      };

      dispatch(createAlert(payload));
    }
  };
  useEffect(() => {
    setCbotTrigger(monthSelected?.contractSelected?.cbot_quote);
    setBasisTrigger(monthSelected?.contractSelected?.basis_quote);
    setDolarTrigger(monthSelected?.contractSelected?.dolar_quote);
  }, [monthSelected]);
  return (
    <div className={styles.alertsContainer}>
      <TopRow topItems={items} />
      <div className={styles.alertRowContainer}>
        <AlertRow
          placeholder="PREÇO"
          variableName={"cbot"}
          directionSwitch={[cbotDirection, setCbotDirection]}
          activeSwitch={[cbotActiveSwitch, setCbotActiveSwitch]}
          triggerHandler={[triggerCbot, setCbotTrigger]}
        />
        <AlertRow
          placeholder="VOLUME"
          variableName={"basis"}
          directionSwitch={[basisDirection, setBasisDirection]}
          activeSwitch={[basisActiveSwitch, setBasisActiveSwitch]}
          triggerHandler={[triggerBasis, setBasisTrigger]}
        />
        <AlertRow
          placeholder="CÂMBIO"
          variableName={"dolar"}
          directionSwitch={[dolarDirection, setDolarDirection]}
          activeSwitch={[dolarActiveSwitch, setDolarActiveSwitch]}
          triggerHandler={[triggerDolar, setDolarTrigger]}
        />
        <AlertRow
          placeholder="PREÇO"
          variableName={"preço"}
          directionSwitch={[frameDirection, setFrameDirection]}
          activeSwitch={[frameActiveSwitch, setFrameActiveSwitch]}
          triggerHandler={[triggerFrame, setFrameTrigger]}
        />
      </div>
      <div className={styles.submitBtnContainer}>
        <Button
          type="primary"
          style={{
            border: "none",
            backgroundColor: "#5fa046",
            textTransform: "uppercase",
            marginRight: 10,
          }}
          onClick={handleCreate}
        >
          Adicionar
        </Button>
      </div>
    </div>
  );
};

export default Alerts;
