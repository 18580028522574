import React, { useState, useEffect } from "react";
import styles from "./theme/index.module.scss";
import { Button, InputNumber, Progress, Tooltip } from "antd";
import Months from '../Months';

import { InfoCircleFilled } from '@ant-design/icons/';

import {
	ContractsHistoryMonth,
	MonthSelected,
	ContractInfo,
} from '../../modules/Main/Contracts/types';
import {
	SimulationPayload,
	SimulationResponse,
} from '../../modules/Main/Simulation/types';
import { useDispatch } from 'react-redux';
import { meanVolumeSimulate } from '../../modules/Main/Simulation/actions';
import { getPercent } from '../../utils/getPercent';
import { maskMoney } from '../../utils/moneyMask';
import { hotjar } from 'react-hotjar';

type Props = {
	contractshistoryMonth: ContractsHistoryMonth[];
	monthSelected: MonthSelected;
	simulationResponse: SimulationResponse | null;
	contractInfo: ContractInfo;
};

const Simulation: React.FC<Props> = ({
	contractshistoryMonth,
	monthSelected,
	simulationResponse,
	contractInfo,
}) => {
	const [cbotPrice, setCbotPrice] = useState<number>(
		monthSelected?.contractSelected?.cbot_quote
			? monthSelected?.contractSelected?.cbot_quote
			: 0
	);
	const [cbotVolume, setCbotVolume] = useState<number>(0);
	const [dolarPrice, setDolarPrice] = useState<number>(
		monthSelected?.contractSelected?.dolar_quote
			? Number(monthSelected?.contractSelected?.dolar_quote)
			: 0
	);
	const [dolarVolume, setDolarVolume] = useState<number>(0);
	const [basisPrice, setBasisPrice] = useState<number>(
		monthSelected?.contractSelected?.basis_quote
			? monthSelected?.contractSelected?.basis_quote
			: 0
	);
	const [basisVolume, setBasisVolume] = useState<number>(0);

	const [colorCbot, setColorCbot] = useState<string>('gray');
	const [colorBasis, setColorBasis] = useState<string>('gray');
	const [colorDolar, setColorDolar] = useState<string>('gray');
	const dispatch = useDispatch();

	const handleSimulation = () => {
		let payload: SimulationPayload = {
			cbot: cbotPrice,
			cbot_volume: cbotVolume,
			dolar: dolarPrice,
			dolar_volume: dolarVolume,
			basis: basisPrice ? basisPrice : 0,
			basis_volume: basisVolume ? basisVolume : 0,
			month_id: monthSelected?.contractSelected?.id,
			volume_trigger: 0,
			volume_trigger_negative: false,
		};
		dispatch(meanVolumeSimulate(payload));
	};

	useEffect(() => {
		handleSimulation();
		// eslint-disable-next-line
	}, [cbotVolume]);

	useEffect(() => {
		setBasisVolume(
			monthSelected?.contractSelected?.basis_volume -
				monthSelected?.contractSelected?.fixated_basis
		);
		setCbotVolume(
			monthSelected?.contractSelected?.cbot_volume -
				monthSelected?.contractSelected?.fixated_cbot
		);
		setDolarVolume(
			monthSelected?.contractSelected?.dolar_volume -
				monthSelected?.contractSelected?.fixated_dolar
		);
		setCbotPrice(monthSelected?.contractSelected?.cbot_quote);
		setBasisPrice(monthSelected?.contractSelected?.basis_quote);
		setDolarPrice(monthSelected?.contractSelected?.dolar_quote);

		setColorCbot('gray');
		setColorBasis('gray');
		setColorDolar('gray');

		// eslint-disable-next-line
	}, [monthSelected]);

	const handleClickInput = (nameInput: string) => {
		if (nameInput === 'cbot') {
			setColorCbot('white');
		} else if (nameInput === 'basis') {
			setColorBasis('white');
		} else if (nameInput === 'dolar') {
			setColorDolar('white');
		}
	};

	const handleInputChange = (
		value: number,
		variable: 'cbot' | 'basis' | 'dolar'
	) => {
		const checkCbot = simulationResponse?.cbot_fixated
			? simulationResponse?.cbot_fixated
			: 0;
		const checkBasis = simulationResponse?.basis_fixated
			? simulationResponse?.basis_fixated
			: 0;
		const checkDollar = simulationResponse?.dollar_fixated
			? simulationResponse?.dollar_fixated
			: 0;
		let variablesCheck = {
			cbot: {
				check: value <= checkCbot,
				setter: () => setCbotVolume(value),
				fixatedSetter: () => setCbotVolume(checkCbot),
			},
			basis: {
				check: value <= checkBasis,
				setter: () => setBasisVolume(value),
				fixatedSetter: () => setBasisVolume(checkBasis),
			},
			dolar: {
				check: value <= checkDollar,
				setter: () => setDolarVolume(value),
				fixatedSetter: () => setDolarVolume(checkDollar),
			},
		};

		// if (variablesCheck[variable].check || value === 0) {
		//   variablesCheck[variable].fixatedSetter();
		// } else {
		variablesCheck[variable].setter();
		//  }
	};

	const [isSelectedSimulation, setIsSelectedSimulation] =
		useState<boolean>(false);

	const hotJarSimulation = (type: string) => {
		if (!isSelectedSimulation) {
			setIsSelectedSimulation(true);
			hotjar.initialize(2030290, 6, 'loadingSimulation');
		}
	};

	return (
		<>
			<div
				className={styles.mainContainer}
				onClick={() => hotJarSimulation('cbot')}
			>
				<div className={styles.simulationTop}>
					<span className={styles.simulationSpan}>SIMULAÇÃO</span>
				</div>
				{contractInfo ? (
					<>
						<Months
							contractshistoryMonth={contractshistoryMonth}
							monthSelected={monthSelected}
						/>

						<div className={styles.simulationBody}>
							<div className={styles.simulationInput}>
								<div className={styles.inputContainer}>
									<Tooltip
										title={
											<>
												<span style={{ color: '#ea6f24', fontWeight: 'bold' }}>
													Referência cotação de farelo de soja chicago board of
													trade (CBOT)
												</span>
											</>
										}
									>
										<span
											style={{
												alignItems: 'center',
												color: '#ea6f24',
												fontSize: 15,
												fontWeight: 'bold',
											}}
										>
											CBOT
										</span>
									</Tooltip>
									<InputNumber
										size="large"
										className={styles.inputStyle}
										placeholder="Preço"
										value={cbotPrice}
										onChange={(value) => setCbotPrice(Number(value))}
										step={0.5}
										decimalSeparator=","
									/>
									<span style={{ marginLeft: 180 }}>USD/ston</span>
									<InputNumber
										style={{ color: colorCbot }}
										size="large"
										className={styles.inputStyle}
										placeholder="Volume"
										value={cbotVolume}
										max={monthSelected?.contractSelected?.cbot_volume}
										min={0}
										onFocus={() => handleClickInput('cbot')}
										onChange={(value) =>
											handleInputChange(Number(value), 'cbot')
										}
										step={contractInfo?.orders_size}
										decimalSeparator=","
									/>
									<span style={{ marginLeft: 220 }}>TON</span>
									<div className={styles.chartRow}>
										<div className={styles.chartItem}>
											<Tooltip
												title={
													<>
														<span style={{ color: '#ea6f24' }}>
															Simulado: {cbotVolume}
														</span>
														<span style={{ color: '#437d73', marginLeft: 5 }}>
															Fixado:{' '}
															{monthSelected?.contractSelected?.fixated_cbot}
														</span>
													</>
												}
											>
												<Progress
													type="circle"
													className={styles.progressCircle}
													percent={
														getPercent(
															monthSelected?.contractSelected?.cbot_volume
																? monthSelected?.contractSelected?.cbot_volume
																: 0,
															cbotVolume ? cbotVolume : 1
														) +
														getPercent(
															monthSelected?.contractSelected?.cbot_volume,
															monthSelected?.contractSelected?.fixated_cbot
														)
													}
													format={(value) => {
														return (
															<div style={{ fontSize: 16 }}>
																<div className={styles.topProgress}>
																	{simulationResponse?.cbot_simulated ? (
																		simulationResponse?.cbot_simulated
																			?.toFixed(2)
																			.replace('.', ',')
																	) : (
																		<span
																			style={{
																				fontWeight: 'bold',
																				fontSize: 18,
																			}}
																		>
																			-
																		</span>
																	)}
																</div>
																<hr style={{ width: '50%' }} />
																<div className={styles.bottomProgress}>
																	{value?.toFixed()}%
																</div>
															</div>
														);
													}}
													success={{
														percent: getPercent(
															monthSelected?.contractSelected?.cbot_volume,

															monthSelected?.contractSelected?.fixated_cbot
																? monthSelected?.contractSelected?.fixated_cbot
																: 1
														),
													}}
													strokeColor="#EA6F24"
													trailColor="#061417"
													showInfo={true}
													strokeLinecap="round"
													strokeWidth={8}
												/>
											</Tooltip>
										</div>
									</div>
								</div>
								<div className={styles.inputContainer}>
									<Tooltip
										title={
											<>
												<span style={{ color: '#0072bc', fontWeight: 'bold' }}>
													Referência de prêmio no porto de Paranaguá
												</span>
											</>
										}
									>
										<span
											style={{
												alignItems: 'center',
												color: '#0072bc',
												fontSize: 15,
												fontWeight: 'bold',
											}}
										>
											BASIS
										</span>
									</Tooltip>
									<InputNumber
										size="large"
										className={styles.inputStyle}
										placeholder="Preço"
										min={-10000}
										value={Number(basisPrice ? basisPrice.toFixed(2) : 0)}
										onChange={(value) => setBasisPrice(Number(value))}
										decimalSeparator=","
										step={0.5}
									/>
									<span style={{ marginLeft: 180 }}>USD/ston</span>
									<InputNumber
										style={{ color: colorBasis }}
										size="large"
										className={styles.inputStyle}
										placeholder="Volume"
										max={monthSelected?.contractSelected?.basis_volume}
										min={0}
										onFocus={() => handleClickInput('basis')}
										value={basisVolume ? basisVolume : 0}
										onChange={(value) =>
											handleInputChange(Number(value), 'basis')
										}
										step={contractInfo?.orders_size}
										decimalSeparator=","
									/>
									<span style={{ marginLeft: 220 }}>TON</span>
									<div className={styles.chartRow}>
										<div className={styles.chartItem}>
											<Tooltip
												title={
													<>
														<span style={{ color: '#0072bc' }}>
															Simulado:{' '}
															{basisVolume ? basisVolume.toFixed(2) : 0}
														</span>
														<span style={{ color: '#437d73', marginLeft: 5 }}>
															Fixado:{' '}
															{monthSelected?.contractSelected?.fixated_basis.toFixed(
																2
															)}
														</span>
													</>
												}
											>
												<Progress
													type="circle"
													size="default"
													className={styles.progressCircle}
													percent={
														getPercent(
															monthSelected?.contractSelected?.basis_volume
																? monthSelected?.contractSelected?.basis_volume
																: 0,
															basisVolume ? basisVolume : 1
														) +
														getPercent(
															monthSelected?.contractSelected?.basis_volume,
															monthSelected?.contractSelected?.fixated_basis
														)
													}
													format={(value) => {
														return (
															<div style={{ fontSize: 16 }}>
																<div className={styles.topProgress}>
																	{simulationResponse?.basis_simulated ? (
																		simulationResponse?.basis_simulated
																			.toFixed(2)
																			.replace('.', ',')
																	) : (
																		<span
																			style={{
																				fontWeight: 'bold',
																				fontSize: 18,
																			}}
																		>
																			-
																		</span>
																	)}
																</div>
																<hr style={{ width: '50%' }} />
																<div className={styles.bottomProgress}>
																	{value?.toFixed()}%
																</div>
															</div>
														);
													}}
													success={{
														percent: getPercent(
															monthSelected?.contractSelected?.basis_volume,

															monthSelected?.contractSelected?.fixated_basis
																? monthSelected?.contractSelected?.fixated_basis
																: 1
														),
													}}
													strokeColor="#0072bc"
													trailColor="#061417"
													strokeLinecap="round"
													strokeWidth={8}
												/>
											</Tooltip>
										</div>
									</div>
								</div>

								<div className={styles.inputContainer}>
									<Tooltip
										title={
											<>
												<span style={{ color: '#66bc46', fontWeight: 'bold' }}>
													Referência de cotação de dólar BM&F
												</span>
											</>
										}
									>
										<span
											style={{
												alignItems: 'center',
												fontSize: 15,
												fontWeight: 'bold',
												color: '#66bc46',
											}}
										>
											DÓLAR
										</span>
									</Tooltip>
									<InputNumber
										size="large"
										className={styles.inputStyle}
										placeholder="Preço"
										value={dolarPrice}
										onChange={(value) => setDolarPrice(Number(value))}
										step={0.1}
										decimalSeparator=","
										style={{ textAlign: 'center' }}
									/>
									<span style={{ marginLeft: 200 }}>R$/USD</span>
									<InputNumber
										style={{ color: colorDolar }}
										size="large"
										className={styles.inputStyle}
										placeholder="Volume"
										value={dolarVolume}
										min={0}
										onFocus={() => handleClickInput('dolar')}
										max={monthSelected?.contractSelected?.dolar_volume}
										onChange={(value) =>
											handleInputChange(Number(value), 'dolar')
										}
										step={contractInfo?.orders_size}
										decimalSeparator=","
									/>
									<span style={{ marginLeft: 220 }}>TON</span>
									<div className={styles.chartRow}>
										<div className={styles.chartItem}>
											<Tooltip
												title={
													<>
														<span style={{ color: '#66bc46' }}>
															Simulado: {dolarVolume}
														</span>
														<span style={{ color: '#437d73', marginLeft: 5 }}>
															Fixado:{' '}
															{monthSelected?.contractSelected?.fixated_dolar}
														</span>
													</>
												}
											>
												<Progress
													type="circle"
													className={styles.progressCircle}
													percent={
														getPercent(
															monthSelected?.contractSelected?.dolar_volume
																? monthSelected?.contractSelected?.dolar_volume
																: 0,
															dolarVolume ? dolarVolume : 1
														) +
														getPercent(
															monthSelected?.contractSelected?.dolar_volume,
															monthSelected?.contractSelected?.fixated_dolar
														)
													}
													format={(value) => {
														return (
															<div style={{ fontSize: 16 }}>
																<div className={styles.topProgress}>
																	{simulationResponse?.dollar_simulated ? (
																		simulationResponse?.dollar_simulated
																			.toFixed(3)
																			.replace('.', ',')
																	) : (
																		<span
																			style={{
																				fontWeight: 'bold',
																				fontSize: 18,
																			}}
																		>
																			-
																		</span>
																	)}
																</div>
																<hr style={{ width: '50%' }} />
																<div className={styles.bottomProgress}>
																	{value?.toFixed()}%
																</div>
															</div>
														);
													}}
													success={{
														percent: getPercent(
															monthSelected?.contractSelected?.dolar_volume,
															monthSelected?.contractSelected?.fixated_dolar
																? monthSelected?.contractSelected?.fixated_dolar
																: 1
														),
													}}
													strokeColor="#66bc46"
													trailColor="#061417"
													showInfo={true}
													strokeLinecap="round"
													strokeWidth={8}
												/>
											</Tooltip>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				) : null}
			</div>

			<div style={{ paddingTop: '10px' }}></div>

			<div
				className={styles.mainContainer}
				style={{ minHeight: '100px', border: '1px solid white' }}
			>
				<div className={styles.simulationInput}>
					<div className={styles.inputContainer}>
						<div className={styles.chartItem}>
							<div
								className={styles.resultDiv}
								style={{ borderBottom: '2px solid white' }}
							>
								<span className={styles.resultSpan}>
									R${' '}
									{simulationResponse?.frame_simulated
										? maskMoney(
												Number(simulationResponse?.frame_simulated).toFixed(2)
										  )
										: '-'}
									/ton
								</span>
							</div>
							<Tooltip
								title={
									<>
										<span style={{ color: '#ea6f24', fontWeight: 'bold' }}>
											Refere-se ao cálculo considerando os valores fixados
											ponderados com o valor simulado.
										</span>
									</>
								}
							>
								<span style={{ marginLeft: '120px', fontSize: 9 }}>
									<InfoCircleFilled /> PREÇO SIMULADO
								</span>
							</Tooltip>
						</div>
					</div>

					<div className={styles.inputContainer}>
						<div className={styles.chartItem}>
							<div
								className={styles.resultDiv}
								style={{ borderBottom: '2px solid white' }}
							>
								<span className={styles.resultSpan}>
									R${' '}
									{simulationResponse?.frame_fixated
										? maskMoney(
												Number(simulationResponse?.frame_fixated).toFixed(2)
										  )
										: '-'}
									/ton
								</span>
							</div>
							<Tooltip
								title={
									<>
										<span style={{ color: '#0072bc', fontWeight: 'bold' }}>
											Refere-se ao cálculo considerando os valores fixados
											ponderados de CBOT, BASIS e DOLAR.
										</span>
									</>
								}
							>
								<span style={{ marginLeft: '120px', fontSize: 9 }}>
									<InfoCircleFilled /> PREÇO FIXADO
								</span>
							</Tooltip>
						</div>
					</div>

					<div className={styles.inputContainer}>
						<div className={styles.chartItem}>
							<div
								className={styles.resultDiv}
								style={{ borderBottom: '2px solid white' }}
							>
								<span className={styles.resultSpan}>
									R${' '}
									{simulationResponse?.frame_market
										? maskMoney(
												Number(simulationResponse?.frame_market).toFixed(2)
										  )
										: '-'}
									/ton
								</span>
							</div>
							<Tooltip
								title={
									<>
										<span style={{ color: '#66bc46', fontWeight: 'bold' }}>
											Refere-se ao cálculo considerando os valores ponderados a
											mercado do mês de referência.
										</span>
									</>
								}
							>
								<span style={{ marginLeft: '120px', fontSize: 9 }}>
									<InfoCircleFilled /> PREÇO A MERCADO
								</span>
							</Tooltip>
						</div>
					</div>
				</div>

				<div className={styles.simulationBody}>
					<div className={styles.simulationInput}>
						<div className={styles.inputContainer}>
							<div className={styles.simulateButtonContainer}>
								<Button
									type="primary"
									size="large"
									style={{ border: 'none', backgroundColor: '#437d73' }}
									onClick={() => handleSimulation()}
								>
									SIMULAR
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Simulation;
