import React, { useState, useEffect, useRef } from "react";
//styles and antd imports
// import { Layout } from 'antd';
import styles from "./theme/index.module.scss";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import {
  MonthHolidays,
  MonthSelected,
  ContractsHistoryMonth,
} from "../../modules/Main/Contracts/types";

import { useSelector } from "react-redux";
import { AppState } from "../../rootReducer";
import { Spin } from "antd";
import moment from "moment";
import Modal from "antd/lib/modal/Modal";
import months from "./months.json";

type Props = {
  monthSelected: MonthSelected;
};

const SideCalendar: React.FC<Props> = ({ monthSelected }) => {
  const {
    monthHolidays,
    holidaysLoading,
    contractshistoryMonth,
  }: {
    monthHolidays: MonthHolidays[];
    holidaysLoading: boolean;
    contractshistoryMonth: ContractsHistoryMonth[];
  } = useSelector((state: AppState) => state.contracts);

  const fullCalendarRef = useRef<FullCalendar>(null);

  const [events, setEvents] = useState<{ title: string; date: string }[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [actualMonth, setActualMonth] = useState<string>("");
  const [selectedEvent, setSelectedEvent] = useState<{
    title: string;
    date: string;
  }>({ title: "", date: "" });

  useEffect(() => {
    let allHolidays: { title: string; date: string }[] = [];
    if (monthHolidays != null) {
      for (let i = 0; i < monthHolidays.length; i++) {
        if (monthHolidays[i].holidays.length > 0) {
          for (let j = 0; j < monthHolidays[i].holidays.length; j++) {
            let holiday = monthHolidays[i].holidays[j];
            if(holiday){
              let item = {
                title: ``,
                date: `${holiday.day.substring(0, 10)}`
              };
              allHolidays.push(item);
            }
            
          }
        }
      }
    }
    setEvents(allHolidays);
  }, [monthHolidays]);

  let OpenSelectedEvent = function (info: any) {
    setIsOpen(!isOpen);
    const date = info.event._instance.range.end;
    setSelectedEvent({
      title: `${info.event._def.title}`,
      date: `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`,
    });
  };

  const renderMonth = () => {
    let filteredMonthDate = monthHolidays?.filter(
      (item) =>
        item.reference_month ===
        monthSelected?.contractSelected?.reference_month
    );

    if (filteredMonthDate) {
      fullCalendarRef.current
        ?.getApi()
        ?.gotoDate(
          moment(filteredMonthDate[0]?.month_date)
            .subtract(1, "months")
            .format()
        );
    }
  };

  const renderHeaderTitle = () => {
    months.map((item, index) => {
      if (
        item.name ===
        monthSelected?.contractSelected?.reference_month
          .replace(/[0-9]/g, "")
          .replace("/", "")
      ) {
        setActualMonth(
          months[index === 0 ? months.length - 1 : index - 1]?.desc
        );
      }
    });
  };
  useEffect(() => {
    if (monthSelected?.selected) {
      renderMonth();
      renderHeaderTitle();
    }
  }, [monthSelected?.selected]);
  return (
    <div className={styles.mainContainer}>
      {(() => {
        if (holidaysLoading) return <Spin size="large" />;
      })()}

      {(() => {
        if (!holidaysLoading)
          return (
            <div>
              <div className={styles.header}>
                {/* {monthSelected?.contractSelected?.reference_month.toUpperCase()} */}

                {actualMonth}
              </div>
              <div className={styles.calendar}>
                <FullCalendar
                  ref={fullCalendarRef}
                  plugins={[dayGridPlugin]}
                  initialView="dayGridMonth"
                  editable={false}
                  selectable={false}
                  selectMirror={false}
                  weekends={false}
                  headerToolbar={{
                    center: "",
                    left: "",
                    right: "",
                    start: "",
                  }}
                  dayMaxEvents={false}
                  height={325}
                  aspectRatio={2}
                  locale="pt-br"
                  events={events}
                  eventClick={OpenSelectedEvent}
                  // events={[
                  //   {title: "Fechamento", date: "2020-09-07", color: "#FF5500"},
                  //   {title: "Feriado", date: "2020-09-08", color: "#77FF77"},
                  // ]}
                />
              </div>

              <Modal
                visible={isOpen}
                closable={true}
                onCancel={() => setIsOpen(!isOpen)}
                onOk={() => setIsOpen(!isOpen)}
                cancelButtonProps={{
                  style: {
                    display: "none",
                  },
                }}
              >
                {`${selectedEvent?.title}: ${selectedEvent?.date}`}
              </Modal>
            </div>
          );
      })()}
    </div>
  );
};

export default SideCalendar;
