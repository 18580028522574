import React, { useState, useEffect, useCallback } from 'react';
import { Switch, Button, Input, notification, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons/';
import { useDispatch } from 'react-redux';

import {
	ContractInfo,
	MonthSelected,
	FixatedValues,
} from '../../../modules/Main/Contracts/types';
import { createOrder } from '../../../modules/Order/Orders/actions';
import { CreatedOrderStateObject } from '../../../modules/Order/Orders/types';
import Modal from 'antd/lib/modal/Modal';
import VariablesSpan from '../../VariablesSpan';
import SliderRow from './SliderRow';

import styles from './theme/index.module.scss';
import Loading from '../../Loading';

type Props = {
	contractInfo: ContractInfo;
	monthSelected: MonthSelected;
	createdOrder: CreatedOrderStateObject;
	fixatedValues: FixatedValues;
	loading: boolean;
	loadingFixatedValues: boolean;
};
const CreateOrder: React.FC<Props> = ({
	contractInfo,
	monthSelected,
	createdOrder,
	fixatedValues,
	loadingFixatedValues,
}) => {
	const [cbotVolume, setCbotVolume] = useState<number | null>(fixatedValues?.fixated_cbot);
	const [basisVolume, setBasisVolume] = useState<number | null>(fixatedValues?.fixated_basis);
	const [dolarVolume, setDolarVolume] = useState<number | null>(fixatedValues?.fixated_dolar);

	const [strCbotPrice, setStrCbotPrice] = useState<string>('');
	const [strBasisPrice, setStrBasisPrice] = useState<string>('');
	const [strDolarPrice, setStrDolarPrice] = useState<string>('');

	const [cbotSelected, setCbotSelected] = useState<boolean>(false);
	const [basisSelected, setBasisSelected] = useState<boolean>(false);
	const [dolarSelected, setDolarSelected] = useState<boolean>(false);

	const [partialChecked, setPartialChecked] = useState<boolean>(true);

	const [validUntil, setValidUntil] = useState<number>(1);

	const [modalVisible, setModalVisible] = useState<boolean>(false);

	const dispatch = useDispatch();

	useEffect(() => {
		if((cbotVolume?cbotVolume:0) < fixatedValues?.fixated_cbot){
			setCbotVolume(fixatedValues?.fixated_cbot);
		}
		if((basisVolume?basisVolume:0) < fixatedValues?.fixated_basis){
			setBasisVolume(fixatedValues?.fixated_basis);
		}
		if((dolarVolume?dolarVolume:0) < fixatedValues?.fixated_dolar){
			setDolarVolume(fixatedValues?.fixated_dolar);
		}
	}, [fixatedValues, monthSelected])

	useEffect(() => {
		setCbotVolume(fixatedValues?.fixated_cbot);
		setBasisVolume(fixatedValues?.fixated_basis);
		setDolarVolume(fixatedValues?.fixated_dolar);
	}, [monthSelected])
	
	const renderModal = () => {
		if (basisSelected) {
			return (
				<div className={styles.variableContainer}>
					<VariablesSpan height={25} width="120px" variableName="basis" />
					<div className={styles.orderResume}>
						<span>Volume</span>
						<span
							style={{
								fontWeight: 'bold',
								color: '#0072bc',
							}}
						>
							{basisVolume} volume/ton
						</span>
					</div>
					<div className={styles.orderResume}>
						<span>Preço</span>
						<span
							style={{
								fontWeight: 'bold',
								color: '#0072bc',
							}}
						>
							{strBasisPrice}
						</span>
					</div>
				</div>
			);
		} else if (dolarSelected) {
			return (
				<div className={styles.variableContainer}>
					<VariablesSpan height={25} width="120px" variableName="dolar" />
					<div className={styles.orderResume}>
						<span>Volume</span>
						<span
							style={{
								fontWeight: 'bold',
								color: '#66bc46',
							}}
						>
							{dolarVolume} volume/ton
						</span>
					</div>
					<div className={styles.orderResume}>
						<span>Preço</span>
						<span
							style={{
								fontWeight: 'bold',
								color: '#66bc46',
							}}
						>
							{strDolarPrice}
						</span>
					</div>
				</div>
			);
		} else {
			return (
				<div className={styles.variableContainer}>
					<VariablesSpan height={25} width="120px" variableName="cbot" />
					<div className={styles.orderResume}>
						<span>Volume</span>
						<span
							style={{
								fontWeight: 'bold',
								color: '#ea6f24',
							}}
						>
							{cbotVolume} volume/ton
						</span>
					</div>
					<div className={styles.orderResume}>
						<span>Preço</span>
						<span
							style={{
								fontWeight: 'bold',
								color: '#ea6f24',
							}}
						>
							{strCbotPrice}
						</span>
					</div>
				</div>
			);
		}
	};

	useEffect(() => {
		const cbotPriceStr = `${monthSelected?.contractSelected?.cbot_quote}`;

		if (cbotPriceStr.indexOf('.') >= 0) {
			cbotPriceStr.replace('.', ',');
		}

		setStrCbotPrice(!!monthSelected ? cbotPriceStr : '');
		const basisPriceStr =
			`${monthSelected?.contractSelected?.basis_quote}`.replace('.', ',');
		setStrBasisPrice(!!monthSelected ? basisPriceStr : '');

		const dolarPriceStr = `${monthSelected?.contractSelected?.dolar_quote}`;

		if (dolarPriceStr.indexOf('.') >= 0) {
			dolarPriceStr.replace('.', ',');
		}

		setStrDolarPrice(!!monthSelected ? dolarPriceStr : '');
	}, [monthSelected]);

	useEffect(() => {
		const allowedErrors = ['order already exists'];
		if (!createdOrder.error || !allowedErrors.includes(createdOrder.error))
			return;

		setModalVisible(true);
	}, [createdOrder]);

	const clearState = useCallback(() => {
		setCbotSelected(false);
		setCbotVolume(fixatedValues?.fixated_cbot);
		setBasisSelected(false);
		setBasisVolume(fixatedValues?.fixated_basis);
		setDolarSelected(false);
		setDolarVolume(fixatedValues?.fixated_dolar);
	}, [fixatedValues]);

	const handleCreate = (forceCreate: boolean = false) => {
		setModalVisible(false);
		type sendCreatedOrder = {
			month_id: number;
			cbot: number | null;
			cbot_vol: number | null;
			basis: number | null;
			basis_vol: number | null;
			dolar: number | null;
			dolar_vol: number | null;
			force_create: boolean | null;
			valid_until: number;
			volume_trigger: number | null;
			partial: boolean | null;
		};
		let payload: sendCreatedOrder = {
			month_id: monthSelected?.selected,
			valid_until: validUntil,
			force_create: forceCreate,
			basis: null,
			basis_vol: null,
			cbot: null,
			cbot_vol: null,
			dolar: null,
			dolar_vol: null,
			volume_trigger: null,
			partial: null,
		};

		payload.partial = partialChecked;

		if (cbotSelected) {
			if (strCbotPrice == null || Number(strCbotPrice) < 0) {
				notification.warn({
					message: 'O preço definido para o CBOT é invalido!',
					duration: 5,
				});
				return;
			}

			payload.cbot = Number(strCbotPrice.replace(',', '.'));
			payload.cbot_vol =
				cbotVolume !== null
					? cbotVolume - monthSelected?.contractSelected?.fixated_cbot
					: 0;
		} else if (basisSelected) {
			if (strBasisPrice == null) {
				notification.warn({
					message: 'O preço definido para o BASIS é invalido!',
					duration: 5,
				});
				return;
			}
			payload.basis = strBasisPrice
				? Number(strBasisPrice.replace(',', '.'))
				: null;

			payload.basis_vol =
				basisVolume !== null
					? basisVolume - monthSelected?.contractSelected?.fixated_basis
					: 0;
		} else if (dolarSelected) {
			if (strDolarPrice == null || Number(strDolarPrice) < 0) {
				notification.warn({
					message: 'Preço invalido CBOT',
					duration: 5,
				});
				return;
			}
			payload.dolar = Number(strDolarPrice);
			payload.dolar_vol =
				dolarVolume !== null
					? dolarVolume - monthSelected?.contractSelected?.fixated_dolar
					: 0;
		}

		if (
			cbotSelected === false &&
			basisSelected === false &&
			dolarSelected === false
		) {
			notification.warn({
				message: 'Por favor selecione pelo menos uma varíavel',
				duration: 5,
			});
		} else {
			dispatch(createOrder(payload, clearState));
		}
	};

	return (
		
		<div className={styles.createOrderContainer}>
			<>
				
				<SliderRow
					title={'CBOT'}
					variableName="cbot"
					fixated={fixatedValues?.fixated_cbot}
					maxVolume={monthSelected?.contractSelected?.cbot_volume}
					volume={cbotVolume}
					step={contractInfo?.orders_size}
					selected={cbotSelected}
					setSelected={setCbotSelected}
					strPrice={strCbotPrice}
					setStrPrice={setStrCbotPrice}
					handleSliderChange={(value: number) => {
						if (value >= fixatedValues?.fixated_cbot) {
							setCbotVolume(value);
						}
					}}
					monthSelected={monthSelected}
					booleanDisabled={!fixatedValues || !!basisSelected || !!dolarSelected}
					measurement_unit="USD/ston"
				/>

				<SliderRow
					title={'BASIS'}
					variableName="basis"
					fixated={fixatedValues?.fixated_basis}
					maxVolume={monthSelected?.contractSelected?.basis_volume}
					volume={basisVolume}
					step={contractInfo?.orders_size}
					selected={basisSelected}
					setSelected={setBasisSelected}
					strPrice={(() => {
						return !fixatedValues ||
							(strBasisPrice != null && strBasisPrice !== 'null')
							? strBasisPrice
							: '0';
					})()}
					setStrPrice={setStrBasisPrice}
					handleSliderChange={(value: number) => {
						if (value >= fixatedValues?.fixated_basis) {
							setBasisVolume(value);
						}
					}}
					monthSelected={monthSelected}
					booleanDisabled={!fixatedValues || !!cbotSelected || !!dolarSelected}
					measurement_unit="USD/ston"
				/>


				<SliderRow
					title={'DÓLAR'}
					variableName="dolar"
					fixated={fixatedValues?.fixated_dolar}
					maxVolume={monthSelected?.contractSelected?.dolar_volume}
					volume={dolarVolume}
					step={contractInfo?.orders_size}
					selected={dolarSelected}
					setSelected={setDolarSelected}
					strPrice={strDolarPrice}
					setStrPrice={setStrDolarPrice}
					handleSliderChange={(value: number) => {
						if (value >= fixatedValues?.fixated_dolar) {
							setDolarVolume(value);
						}
					}}
					monthSelected={monthSelected}
					booleanDisabled={!fixatedValues || !!cbotSelected || !!basisSelected}
					measurement_unit="R$/USD"
				/>

				<div className={styles.bottomWrapper}>
					<div className={styles.bottomContainer}>
						<Input
							size="large"
							type="number"
							max={100}
							placeholder="Dias Válidos"
							value={validUntil}
							className={styles.inputOverride}
							min={1}

							onChange={(e) => setValidUntil(Number(e.target.value))}
							style={{
								backgroundColor: '#5fa046',
								border: 'none',
								color: 'white',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								textAlign: 'center',
								fontWeight: 'bold',
								borderRadius: 5,
								fontSize: 12,
								width: 120,
								height: 38,
							}}
						/>
						<Tooltip
							title={
								<>
									<span style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
										Dias válidos com referência ao horário de mercado
									</span>
								</>
							}
						>
							<span className={styles.pregaoSpan}>
								{validUntil > 1 ? 'dias ' : 'dia '}
								<InfoCircleFilled />
							</span>
						</Tooltip>
					</div>
					<div className={styles.submitBtnContainer}>
						<div className={styles.coll} style={{ textAlign: 'center' }}>
							<Switch
								onChange={(checked: boolean) => setPartialChecked(checked)}
								checked={partialChecked}
							/>

							<div style={{ maxWidth: 150 }}>
								<span
									style={{
										fontSize: 12,
										color: 'white',
										wordWrap: 'break-word',
									}}
								>
									Permitir precificação parcial
								</span>
							</div>
						</div>

						<div
							className={styles.row}
							style={{ alignItems: 'center', textAlign: 'center' }}
						>
							{!loadingFixatedValues 
								?
									<Button
										type="primary"
										disabled={!cbotSelected && !dolarSelected && !basisSelected}
										style={{
											border: 'none',
											backgroundColor: '#5fa046',
											textTransform: 'uppercase',
											marginRight: 10,
										}}
										onClick={() => handleCreate()}
									>
										Adicionar
									</Button>
								:
								<Button
										type="primary"
										disabled={true}
										style={{
											border: 'none',
											backgroundColor: '#5fa046',
											textTransform: 'uppercase',
											marginRight: 10,
										}}
										onClick={() => handleCreate()}
									>
										<Loading />
									</Button>
							}
							
						</div>
					</div>
				</div>
				<Modal
					visible={modalVisible}
					onOk={() => handleCreate(true)}
					okText="Criar"
					cancelText="Cancelar"
					onCancel={() => setModalVisible(false)}
				>
					<div style={{ width: '100%', height: '100%', padding: 10 }}>
						<h1 style={{ color: '#fff' }}>
							Esta solicitação de precificação já foi criada, deseja criar outra
							solicitação igual ?
						</h1>
						<div className={styles.modal}>{renderModal()}</div>
					</div>
				</Modal>
			</>
		</div>
	);
};
export default CreateOrder;
