import React from "react";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";

type Props = {
  exact: boolean;
  path: string;
  component: React.FunctionComponent<RouteComponentProps>;
  private: boolean;
};

const PrivateRoute: React.FC<Props> = ({ exact, path, component }) => {
  return localStorage.getItem("token") ? (
    <Route component={component} exact={exact} path={path} />
  ) : (
    <Redirect to="/" />
  );
};
export default PrivateRoute;
