import {
  FETCH_USER_INFO_FAIL,
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_INFO,
} from "./types";
import { Dispatch } from "redux";
import Api from "../../../api/api";

export const fetchUserInfo = () => (dispatch: Dispatch) => {
	Api.get('user/me', {
		headers: {
			authorization: `${localStorage.getItem('token')}`,
		},
	})
		.then((res) => {
			if (res.status === 200 && res.data) {
				dispatch({
					type: FETCH_USER_INFO_SUCCESS,
					payload: {
						data: res.data,
					},
				});
			} else {
				dispatch({
					type: FETCH_USER_INFO_FAIL,
				});
			}
		})
		.catch((err) =>
			dispatch({
				type: FETCH_USER_INFO_FAIL,
			})
		);
};

export const fetchUserTerms = (value: boolean) => (dispatch: Dispatch) => {
	Api.post(
		'user/me/terms',
		{ accept_term: value },
		{
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		}
	)
		.then((res) => {
			if (res.status === 200 && res.data) {
				dispatch({
					type: FETCH_USER_INFO_SUCCESS,
					payload: {
						data: res.data,
					},
				});
			} else {
				dispatch({
					type: FETCH_USER_INFO_FAIL,
				});
			}
		})
		.catch((err) =>
			dispatch({
				type: FETCH_USER_INFO_FAIL,
			})
		);
};
