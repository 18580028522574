import React from "react";
import styles from "./theme/index.module.scss";

import OrderRowComponent from "./OrderRowComponent";
import TopRow from "../TopRow";
import { AlertListResponse } from "../../../modules/Order/Alerts/types";
import { Spin } from "antd";

type Props = {
  alerts: AlertListResponse[];
  loading: boolean;
};
const ActiveAlerts: React.FC<Props> = ({ alerts, loading }) => {
  let items = ["Variável", "Preço", "Indicador", "Mês", "Desativar", "Excluir"];

  return (
		<div className={styles.activeAlertsContainer}>
			<TopRow topItems={items} />
			<Spin spinning={loading}>
				{alerts?.length === 0 ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<span
							style={{
								fontWeight: "bold",
								color: "white",
								textAlign: "center",
							}}
						>
							Nenhum alerta encontrado.
						</span>
					</div>
				) : (
					alerts?.map((item) => {
						return (
							<OrderRowComponent
								month={`${item.month_ref}`}
								price={item?.trigger}
								variableName={item?.type.replace("frame", "preço")}
								direction={item?.direction}
								key={item.id.toString()}
								is_active={item?.is_active}
								alert_id={item.id}
							/>
						);
					})
				)}
			</Spin>
		</div>
	);
};

export default ActiveAlerts;
