import { Action, AUTH, AUTH_SUCCESS, AUTH_FAIL, State } from "./types";

const InitialState: State = {
  auth: [],
  fail: false,
  loading: false,
};

const authReducer = (state = InitialState, action: Action) => {
  switch (action.type) {
    case AUTH:
      return {
        ...state,
        loading: true,
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        auth: action.payload.data,
      };
    case AUTH_FAIL:
      return {
        ...state,
        fail: true,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
export default authReducer;
