import {
  State,
  Action,
  FETCH_CONTRACTS_FAIL,
  FETCH_CONTRACTS_SUCCESS,
  FETCH_CONTRACTS,
  LIST_CONTRACT,
  LIST_CONTRACT_FAIL,
  LIST_CONTRACT_SUCCESS,
  SELECTED_CONTRACT_FAIL,
  SELECTED_CONTRACT_SUCCESS,
  FETCH_HOLIDAYS,
  SELECTED_HOLIDAYS_FAIL,
  SELECTED_HOLIDAYS_SUCCESS,
  FRAME_NEXT_PREVIEW,
  FETCH_CONTRACT_INFO,
  FETCH_CONTRACT_INFO_FAIL,
  FETCH_CONTRACT_INFO_SUCCESS,
  FETCH_FIXATED,
  FETCH_FIXATED_FAIL,
  FETCH_FIXATED_SUCCESS,
  OPTION_GRAPHIC_SELECTED,
  SELECTED_MONTH_SUCCESS,
  DOWNLOADING_CONTRACT,
  CONTRACT_DOWNLOAD_SUCCESS,
  CONTRACT_DOWNLOAD_FAIL,
  FETCH_TRADINGVIEW,
  FETCH_TRADINGVIEW_SUCCESS,
  FETCH_TRADINGVIEW_FAIL,
  CONFIG_MARKET_SUCCESS,
  CONFIG_MARKET_FAIL
} from "./types";

const INITIAL_STATE: State = {
  contracts: null,
  contractshistoryMonth: null,
  monthSelected: null,
  contractSelectedMonth: null,
  contractshistoryMonthSelected: null,
  frameNextPreviewSelected: null,
  optionGraphicSelected: null,
  contractInfo: null,
  monthSelectLoading: false,
  false: false,
  loading: false,
  holidaysLoading: false,
  contractDownloading: false,
  fixatedValues: null,
  monthHolidays: null,
  tradingView: null,
  configMarket: null,
};

const contractReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case FETCH_CONTRACTS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CONTRACTS_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    case FETCH_CONTRACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        contracts: action.payload.data,
      };

    case LIST_CONTRACT:
      return {
        ...state,
        loading: true,
      };
    case LIST_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        contractshistoryMonth: action.payload.data,
      };
    case LIST_CONTRACT_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    case SELECTED_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        contractshistoryMonth: action.payload.data,
      };
    case SELECTED_CONTRACT_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    case FETCH_HOLIDAYS:
      return {
        ...state,
        holidaysLoading: true,
      }
    case SELECTED_HOLIDAYS_SUCCESS:
      return {
        ...state,
        holidaysLoading: false,
        monthHolidays: action.payload.data,
      };
    case SELECTED_HOLIDAYS_FAIL:
      return {
        ...state,
        holidaysLoading: false,
      };
    case DOWNLOADING_CONTRACT:
      return {
        ...state,
        contractDownloading: true
      }
    case CONTRACT_DOWNLOAD_SUCCESS:
      return {
        ...state,
        contractDownloading: false
      }
    case CONTRACT_DOWNLOAD_FAIL:
      return {
        ...state,
        contractDownloading: false
      }
    case FRAME_NEXT_PREVIEW:
      return {
        ...state,
        loading: false,
        fail: false,
        frameNextPreviewSelected: action.payload.data,
      };

    case OPTION_GRAPHIC_SELECTED:
      return {
        ...state,
        loading: false,
        fail: false,
        optionGraphicSelected: action.payload.data,
      };
      
    case SELECTED_MONTH_SUCCESS:
      return {
        ...state,
        loading: false,
        fail: false,
        monthSelected: action.payload.data,
        contractshistoryMonthSelected: action.payload.data,
      };

    case FETCH_CONTRACT_INFO:
      return {
        ...state,
        loading: true,
      };

    case FETCH_CONTRACT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        contractInfo: action.payload.data,
      };

    case FETCH_CONTRACT_INFO_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    case FETCH_FIXATED:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FIXATED_SUCCESS:
      return {
        ...state,
        loading: false,
        fixatedValues: action.payload.data,
      };
    case FETCH_FIXATED_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };

    case FETCH_TRADINGVIEW:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TRADINGVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        tradingView: action.payload.data,
      };
    case FETCH_TRADINGVIEW_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    case CONFIG_MARKET_SUCCESS:
      return {
        ...state,
        loading: false,
        fail: false,
        configMarket: action.payload.data,
      };
    case CONFIG_MARKET_FAIL:
    return {
      ...state,
      loading: false,
      fail: true,
    };

    default: {
      return {
        ...state,
      };
    }
  }
};
export default contractReducer;
