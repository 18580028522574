import React, { useEffect } from "react";
import { hotjar } from "react-hotjar";
import { useDispatch, useSelector } from "react-redux";

import SelectProduct from "./components/SelectProduct";
import SelectContract from "./components/SelectContract";

import { AppState } from "../../rootReducer";
import {
  selectHoliday,
  getSelectedContractInfo,
  selectedUserContractGetAll,
} from "../../modules/Main/Contracts/actions";
import { getContractsWhitProduct } from "./modules/actions";
import { selectedProductIndex, setContractHeader, setProductHeader } from "./components/modules/actions";

import { UserInfo } from "../../modules/Main/User/types";

import styles from "./theme/index.module.scss";
import { ContractSelectedMonth } from "../../modules/Main/Contracts/types";

const SelectFrame: React.FC = () => {
  const dispatch = useDispatch();
    
  const {
    loading,
    contractSelectedMonth,
  } : {
    loading: boolean;
    contractSelectedMonth: ContractSelectedMonth
  } = useSelector((state: AppState) => state.contracts);

  const {
    products,
    loading: load,
    contractsWhitProducts,
  } = useSelector((state: AppState) => state.product);

  const { selectedContractName, selectedProductName } = useSelector(
		(state: AppState) => state.infoHeader
	);

	const {
		userInfo,
	}: {
		userInfo: UserInfo | null;
	} = useSelector((state: AppState) => state.user);


	let contractSelectedId = localStorage.getItem('contractIdSelected');
	let productIdSelectedLocastorage = localStorage.getItem('productIdSelected');

	useEffect(() => {
		const contractHeaderSelected = contractsWhitProducts?.filter(
			(contract) => contract.id === Number(contractSelectedId)
		)[0];
		const productHeaderSelected = products?.filter(
			(prod) => prod.id === Number(productIdSelectedLocastorage)
		)[0]; 
		if(productHeaderSelected != undefined){
			dispatch(selectedProductIndex(productHeaderSelected?.id-1));
		}
		if ((contractSelectedId == undefined || contractSelectedId == "undefined" || contractSelectedId == "" || contractHeaderSelected == undefined)) {
			dispatch(
				setContractHeader(
					contractsWhitProducts[contractsWhitProducts.length-1]?.name,
					contractsWhitProducts[contractsWhitProducts.length-1]?.id
				)
			);
			localStorage.setItem(
				'contractIdSelected',
				contractsWhitProducts[contractsWhitProducts.length-1]?.id?.toString()
			);
		}else{
			
			dispatch(
				setContractHeader(
					(contractHeaderSelected != undefined ? contractHeaderSelected.name : ''),//',//contractHeaderSelected.name,
					Number(contractSelectedId)
				)
			);

		}
	
		
	}, [contractsWhitProducts, contractSelectedId, selectedProductName]);

	useEffect(() => {
		if(contractSelectedId !== "" && contractSelectedId !== null && contractSelectedId !== undefined && contractSelectedId != "undefined"){
			dispatch(getSelectedContractInfo(Number(contractSelectedId)));
			dispatch(selectedUserContractGetAll(Number(contractSelectedId), null));
		}
	}, [dispatch])

	useEffect(() => {
		
		if(products?.length > 0 && (productIdSelectedLocastorage == '' || productIdSelectedLocastorage == undefined)){
			dispatch(getContractsWhitProduct(products[0].id));
			localStorage.setItem('productIdSelected', products[0].id + '');
			dispatch(selectedProductIndex(products[0]?.id-1));
			//dispatch(setProductHeader(products[0]?.name, 'D2955D'));
		}else if(products?.length > 0 && Number(productIdSelectedLocastorage)){
			dispatch(getContractsWhitProduct(Number(productIdSelectedLocastorage)));
			
		}
	}, [products]);

	useEffect(() => {
		const productHeaderSelected = products?.filter(
			(product) => product.name === selectedProductName
		)[0];
		// if (!productHeaderSelected) {
		// 	const product_id = localStorage.getItem('productIdSelected');

		// 	if (product_id) {
		// 		dispatch(getContractsWhitProduct(Number(product_id)));
		// 		dispatch(setProductHeader(products[0]?.name, 'D2955D'));
		// 		localStorage.setItem('productIdSelected', product_id);
		// 	} else {
		// 		dispatch(getContractsWhitProduct(products[0]?.id));
		// 		dispatch(setProductHeader(products[0]?.name, 'D2955D'));
		// 		localStorage.setItem('productIdSelected', products[0]?.id.toString());
		// 	}
		// } else {
		// 	localStorage.setItem(
		// 		'productIdSelected',
		// 		productHeaderSelected?.id.toString()
		// 	);
		// 	dispatch(getContractsWhitProduct(productHeaderSelected.id));
		// }
	}, [dispatch, contractSelectedMonth]);

	// useEffect(() => {
	// 	if (userInfo !== null && userInfo?.accept_term) {
	// 		const contract_id = localStorage.getItem('contractIdSelected');
	// 		if (contract_id && contractSelectedMonth == null) {
	// 			dispatch(selectedUserContractGetAll(Number(contract_id), null));
	// 			hotjar.initialize(2030290, 6, 'loadingcontractsWhitProducts');
	// 			dispatch(getSelectedContractInfo(Number(contract_id)));
	// 			dispatch(selectHoliday(Number(contract_id)));
	// 			localStorage.setItem('contractIdSelected', contract_id);
	// 		}
	// 	}
	// }, [userInfo, dispatch, contractSelectedMonth]);

	useEffect(() => {
		if (userInfo !== null && userInfo?.accept_term) {
			const contract_id = localStorage.getItem('contractIdSelected');
			if (contract_id && contractSelectedMonth == null) {
				hotjar.initialize(2030290, 6, 'loadingcontractsWhitProducts');
			}
		}
	}, [userInfo]);

	const handleSelect = (id: number) => {
		// Quando escolhe um contrato
		if (userInfo !== null && userInfo?.accept_term) {
			dispatch(selectedUserContractGetAll(id, null));
			dispatch(getSelectedContractInfo(id));
			dispatch(selectHoliday(id));
			// localStorage.setItem('contractIdSelected', id + '');
			hotjar.initialize(2030290, 6, 'loadingContracts');
		}
	};

  return (
    <div className={styles.mainContainer}>

      <SelectProduct
        loading={load}
        products={products}
        selectProduct={id => dispatch(getContractsWhitProduct(id)) }
      />

      <SelectContract
        loading={loading}
        contract={contractsWhitProducts}
        selectContract={(id) => handleSelect(id)}
      />
    </div>
  );
}; 
export default SelectFrame;
