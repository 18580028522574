import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./theme/index.module.scss";
import { InputNumber, Spin } from "antd";
import { ContractsHistoryMonth, MonthSelected } from "../../modules/Main/Contracts/types";
import { quickSimulate } from "../../modules/Main/Simulation/actions";
import { QuickSimulationResponse } from "../../modules/Main/Simulation/types";
import VariablesSpan from "../VariablesSpan";
import { AppState } from "../../rootReducer";
import Loading from "../Loading";
import SelectMonth from "../SelectMonth";


const QuickSimulation: React.FC = () => {

  const {
    monthSelected,
    contractshistoryMonth,
  }: {
    monthSelected: MonthSelected;
    contractshistoryMonth: ContractsHistoryMonth[];
  } = useSelector((state: AppState) => state.contracts);

  const {
    quickSimulation,
    loading,
  }: {
    quickSimulation: QuickSimulationResponse | null;
    loading: boolean;
  } = useSelector((state: AppState) => state.simulation);


  const [cbot, setCbotPrice] = useState<number>(monthSelected?.contractSelected?.cbot_quote);
  const [basis, setBasisPrice] = useState<number>(monthSelected?.contractSelected?.basis_quote);
  const [dolar, setDolarPrice] = useState<number>(monthSelected?.contractSelected?.dolar_quote);

  const dispatch = useDispatch();

  useEffect(() => {
    
    simulationAction();

  }, [cbot, basis, dolar]);

  useEffect(() => {
    
    setCbotPrice(monthSelected?.contractSelected?.cbot_quote);
    setBasisPrice(monthSelected?.contractSelected?.basis_quote);
    setDolarPrice(monthSelected?.contractSelected?.dolar_quote);

    simulationAction();

  }, [monthSelected]);


  const simulationAction = () => {
    dispatch(
      quickSimulate({
        month_id: monthSelected?.selected,
        basis: basis ? basis : 0,
        dolar: dolar ? dolar : 0,
        cbot: cbot ? cbot : 0,
      })
    );
  }
 
  const handleSimulation = (
    variable: "cbot" | "basis" | "dolar",
    value: number
  ) => {
    let variableSet = {
      cbot: {
        setter: () => setCbotPrice(value),
      },
      basis: {
        setter: () => setBasisPrice(value),
      },
      dolar: {
        setter: () => setDolarPrice(value),
      },
    };

    variableSet[variable].setter();

  };


  return (
    <div className={styles.quickSimulationMain}>
      <div className={styles.quickSimulationTop}>
        <span className={styles.quickSimulationSpan}>CONSULTA RÁPIDA</span>
        <SelectMonth
          months={contractshistoryMonth}
          monthSelected={monthSelected}
        />
      </div>
        
              
      <div className={styles.quickSimulationBody}>
        <span
          style={{
            color: "white",
            fontWeight: "bold",
            borderBottom: "1px solid #fff",
            textTransform: "uppercase",
            marginTop: "-1%",
          }}
        >
          Inpute os valores
        </span>
        <InputNumber
          onChange={(value) => handleSimulation("cbot", Number(value))}
          value={cbot}
          style={{ width: 250, marginTop: "1%" }}
          size="middle"
          placeholder="CBOT"
          decimalSeparator=","
          className={styles.inputStyle}
        />
        <VariablesSpan variableName="cbot" height="20px" width="150px" />

        <InputNumber
          onChange={(value) => handleSimulation("basis", Number(value))}
          value={basis ? basis : 0}
          style={{ width: 250 }}
          placeholder="BASIS"
          decimalSeparator=","
          className={styles.inputStyle}
        />
        <VariablesSpan variableName="basis" height="20px" width="150px" />
        <InputNumber
          onChange={(value) => handleSimulation("dolar", Number(value))}
          value={dolar}
          style={{ width: 250 }}
          placeholder="DÓLAR"
          decimalSeparator=","
          className={styles.inputStyle}
        />
        <VariablesSpan variableName="dolar" height="20px" width="150px" />
        {loading ? (
          <div style={{padding: 5}}>
            <Spin/>
          </div>
        ):
          <span className={styles.quickResult}>
            Preço: R${" "}
            {quickSimulation?.frame_simulated
              ? quickSimulation?.frame_simulated?.toFixed(2)
              : "-"}
            /ton
          </span>
        }
        <VariablesSpan variableName="preço" height="20px" width="150px" />
      </div>
    </div>
  );
};

export default QuickSimulation;
