import React from "react";

import logobkpi from "../../assets/icons/biomalogo.png";

const PageFooter: React.FC = () => {
  return (
    <div
      style={{
        backgroundColor: "rgb(11, 30, 34)",
        position: "fixed",
        left: "0",
        bottom: "0",
        height: "5vh",
        padding: "5px",
        width: "100%",
        color: "white",
        borderTop: "solid 1px white",
      }}
    >
      <div
        style={{
          textAlign: "right",
          marginRight: "15px",
          alignItems: "center",
        }}
      >
        <span
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            fontSize: 9,
            fontFamily: 'helvetica'
          }}
        >
          DESENVOLVIDO POR 
        </span>
        <a
          href="https://www.biomainvestimentos.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{ cursor: "pointer" }}
        >
          <img style={{ width: "7%", marginLeft:3 }} src={logobkpi} alt="Bioma investimentos" />
        </a>
      </div>
    </div>
  );
};

export default PageFooter;
