import React, { Dispatch, useEffect, useState } from "react";
import { Slider, Switch, InputNumber } from "antd";

import VariablesSpan from "../../../VariablesSpan";
import styles from "./theme/index.module.scss";
import { MonthSelected } from "../../../../modules/Main/Contracts/types";

type Props = {
  title: string;
  fixated: number | null;
  maxVolume: number | null;
  step: string | null;
  selected: boolean;
  setSelected: Dispatch<React.SetStateAction<boolean>>;
  volume: number | null;
  strPrice: string;
  setStrPrice: (value: string) => void;
  handleSliderChange: (value: number) => void;
  booleanDisabled: boolean;
  variableName: string;
  measurement_unit: string;
  monthSelected:MonthSelected;
};

const SliderRow: React.FC<Props> = ({
	fixated,
	maxVolume,
	step,
	selected,
	setSelected,
	volume,
	strPrice,
	setStrPrice,
	handleSliderChange,
	booleanDisabled,
	measurement_unit,
	variableName,
	monthSelected,
}) => {
	const value = Number(
		volume != null && fixated != null ? volume - fixated : 0
	);

		const [valueInputSpiner, setValueInputSpiner] = useState(value);

	useEffect(() => {
		setValueInputSpiner(0);
		handleSliderChange(fixated ? fixated : 0)
	}, [fixated]);

	useEffect(() => {
		setValueInputSpiner(0);
		handleSliderChange(fixated ? fixated : 0)
	}, [monthSelected]);

	const renderStep = () => {
		switch (variableName) {
			case 'cbot':
				return 0.5;
			case 'dolar':
				return 0.1;
			case 'frame':
				return 100;
			case 'basis':
				return 0.5;
		}
	};

	const alterValue = () => {
		if (maxVolume)
			return valueInputSpiner > maxVolume ? valueInputSpiner : Number(step);
	};

	return (
		<div className={styles.cbotSliderContainer}>
			<div className={styles.cbotSlider}>
				<div>
					<VariablesSpan
						height="30px"
						width="80px"
						variableName={variableName}
					/>
					<div className={styles.sliderRow}>
						<Slider
							marks={{
								[fixated ? fixated : 0]: {
									style: {
										color: '#ffffff',
									},
								},
							}}
							min={0}
							step={step ? Number(step) : 0}
							max={maxVolume ? maxVolume : 0}
							value={volume || 0}
							onChange={(value: number) => {
								setValueInputSpiner(
									(value != null && fixated != null ? (value - fixated < 0 ? 0 : value - fixated) : 0)
								);
								handleSliderChange(value);
							}}
							style={{ height: '50%' }}
							trackStyle={{ backgroundColor: '#5fa047 ' }}
							handleStyle={{ borderColor: '#5fa047' }}
							disabled={booleanDisabled}
						/>
					</div>
				</div>
				<span style={{ color: 'white' }}>{`${
					volume != null && fixated != null ? volume : 0
				} TON`}</span>
			</div>
			<div className={styles.cbotSpanContainer}>
				<InputNumber
					size="large"
					type="number"
					disabled={booleanDisabled}
					step={alterValue()}
					value={valueInputSpiner}
					min={0}
					max={Number(maxVolume) - Number(fixated)}
					placeholder="VOLUME/TON"
					className={styles.inputOverride}
					onChange={(value) => {
						setValueInputSpiner(Number(value));
						handleSliderChange(
							value != null && fixated != null ? Number(value) + fixated : 0
						);
					}}
					style={{
						backgroundColor: '#5fa046',
						border: 'none',
						color: 'white',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						textAlign: 'center',
						fontWeight: 'bold',
						borderRadius: 5,
						fontSize: 12,
						width: 120,
					}}
				/>
				<span style={{ color: 'white', fontSize: '12px' }}>TON</span>
				<InputNumber
					size="large"
					type="text"
					step={renderStep()}
					disabled={booleanDisabled}
					placeholder="PREÇO"
					value={Number(strPrice) || 0}
					decimalSeparator=","
					onChange={(value) => {
						setStrPrice(String(value));
					}}
					className={styles.inputOverride}
					style={{
						backgroundColor: '#5fa046',
						border: 'none',
						color: 'white',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						textAlign: 'center',
						fontWeight: 'bold',
						borderRadius: 5,
						fontSize: 12,
						width: 120,
					}}
				/>
				<span style={{ color: 'white', fontSize: '12px' }}>
					{measurement_unit}
				</span>
			</div>
			<div className={styles.sliderContainer}>
				<Switch
					disabled={booleanDisabled}
					onChange={(checked: boolean) => setSelected(checked)}
					checked={selected}
				/>
			</div>
		</div>
	);
};

export default SliderRow;
