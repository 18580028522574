import { notification } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { sendApproveDeny } from '../../modules/actions';

import styles from '../theme/index.module.scss'

type Props = {
	id: number;
	fee: number;
	currency: string;
	setVisible: React.Dispatch<React.SetStateAction<'review' | 'accepted' | ''>>;
};

const ModalNote: React.FC<Props> = ({
  id,
  fee,
  currency,
  setVisible,
}) => {
  
  const history = useHistory();
  const dispatch = useDispatch();

  const [note, setNote] = useState('');

  const valueFeeCurrency = `${currency === 'dólar' || currency === 'dolar' ? '$' : 'R$'} ${fee}`
  return (
		<div className={styles.modal_overlay}>
			<div className={styles.modal_wrapper}>
				<div className={styles.modal}>
					<h3>Motivo de negação</h3>
					<div className={styles.fee}>
						<span>Fee</span>
						<input value={valueFeeCurrency} disabled />
					</div>
					<div className={styles.fee}>
						<span>Descrição</span>
						<textarea
							cols={30}
							rows={10}
							value={note}
							onChange={(e) => setNote(e.target.value.toString())}
						/>
					</div>

					<div className={styles.buttons}>
						<button onClick={() => setVisible('')}>Cancelar</button>
						<button
							onClick={() => {
								dispatch(
									sendApproveDeny({
										accepted: false,
										request_id: id,
										note,
									})
								);
								setVisible('');
								history.goBack();
								notification.info({
									message: 'O contrato foi enviado para revisão.',
									duration: 5,
								});
							}}
						>
							Enviar
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ModalNote;