import {
  FETCH_CONTRACTS,
  FETCH_CONTRACTS_SUCCESS,
  FETCH_CONTRACTS_FAIL,
  LIST_CONTRACT,
  LIST_CONTRACT_FAIL,
  LIST_CONTRACT_SUCCESS,
  SELECTED_CONTRACT_FAIL,
  SELECTED_CONTRACT_SUCCESS,
  SELECTED_HOLIDAYS_FAIL,
  SELECTED_HOLIDAYS_SUCCESS,
  SELECTED_MONTH_SUCCESS,
  FRAME_NEXT_PREVIEW,
  FETCH_CONTRACT_INFO,
  FETCH_CONTRACT_INFO_FAIL,
  FETCH_CONTRACT_INFO_SUCCESS,
  FETCH_FIXATED,
  FETCH_FIXATED_FAIL,
  FETCH_FIXATED_SUCCESS,
  FETCH_HOLIDAYS,
  ContractsHistoryMonth,
  DOWNLOADING_CONTRACT,
  CONTRACT_DOWNLOAD_SUCCESS,
  CONTRACT_DOWNLOAD_FAIL,
  FETCH_TRADINGVIEW,
  FETCH_TRADINGVIEW_FAIL,
  FETCH_TRADINGVIEW_SUCCESS,
  OPTION_GRAPHIC_SELECTED,
  CONFIG_MARKET_SUCCESS,
  CONFIG_MARKET_FAIL
} from "./types";
import { Dispatch } from "redux";
import Api from "../../../api/api";
import { notification } from 'antd';

export const fetchUserContracts = (option: number) => (dispatch: Dispatch) => {
	dispatch({
		type: FETCH_CONTRACTS,
	});

	let urlSend = `user/contract/getall?product=${option}`;

	Api.get(urlSend, {
		headers: {
			authorization: `${localStorage.getItem('token')}`,
		},
	})
		.then((res) => {
			if (res.status === 200) {
				dispatch({
					type: FETCH_CONTRACTS_SUCCESS,
					payload: {
						data: res.data,
					},
				});
			} else {
				dispatch({
					type: FETCH_CONTRACTS_FAIL,
				});
			}
		})
		.catch((err) =>
			dispatch({
				type: FETCH_CONTRACTS_FAIL,
			})
		);
};

export const selectedOptionGraphic =
	(
		option_selected: 'cbot' | 'basis' | 'dolar' | '',
		cbot: boolean,
		basis: boolean,
		dolar: boolean
	) =>
	(dispatch: Dispatch) => {
		dispatch({
			type: OPTION_GRAPHIC_SELECTED,
			payload: {
				data: {
					optionSelected: option_selected,
					cbot,
					basis,
					dolar,
				},
			},
		});
	};

export const selectedUserContractGetAll =
	(option: number, frameNextPreviewSelected: string | null) =>
	(dispatch: Dispatch) => {
		if (option === 0) {
			return;
		}
		dispatch({
			type: LIST_CONTRACT,
		});

		let urlSend = `/user/contract/month/getall?contract_id=${option}&next=true`;
		if (frameNextPreviewSelected != null && !frameNextPreviewSelected) {
			urlSend = `/user/contract/month/getall?contract_id=${option}&previous=true&next=false`;

			dispatch({
				type: FRAME_NEXT_PREVIEW,
				payload: {
					data: {
						prevActive: true,
						nowActive: false,
					},
				},
			});
		} else {
			dispatch({
				type: FRAME_NEXT_PREVIEW,
				payload: {
					data: {
						prevActive: false,
						nowActive: true,
					},
				},
			});
		}

		Api.get(urlSend, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		})
			.then((res) => {
				if (res.status === 200) {
					dispatch({
						type: LIST_CONTRACT_SUCCESS,
						payload: {
							data: res.data,
						},
					});
					dispatch({
						type: SELECTED_MONTH_SUCCESS,
						payload: {
							data: {
								selected: res.data[0].id,
								contractSelected: res.data[0],
							},
						},
					});
				} else {
					dispatch({
						type: LIST_CONTRACT_FAIL,
					});
				}
			})
			.catch((err) =>
				dispatch({
					type: LIST_CONTRACT_FAIL,
				})
			);
	};

	export const getConfigMarket = () => (dispatch: Dispatch) => {
		
		Api.get(`/user/config/market/getall`, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		})
			.then((res) => { 
				if (res.status === 200) {
					dispatch({
						type: CONFIG_MARKET_SUCCESS,
						payload: {
							data: res.data,
						},
					});
				} else {
					dispatch({
						type: CONFIG_MARKET_FAIL,
					});
				}
			})
			.catch((err) =>
				dispatch({
					type: CONFIG_MARKET_FAIL,
				})
			);
	};




export const selectedUserContractGet =
(option: number) => (dispatch: Dispatch) => {
	dispatch({
		type: SELECTED_CONTRACT_SUCCESS,
	});

	Api.get(`/user/contract/get?contract_id=${option}`, {
		headers: {
			authorization: `${localStorage.getItem('token')}`,
		},
	})
		.then((res) => {
			if (res.status === 200) {
				dispatch({
					type: SELECTED_CONTRACT_SUCCESS,
					payload: {
						data: res.data,
					},
				});
			} else {
				dispatch({
					type: SELECTED_CONTRACT_FAIL,
				});
			}
		})
		.catch((err) =>
			dispatch({
				type: SELECTED_CONTRACT_FAIL,
			})
		);
};


export const selectHoliday = (option: number) => (dispatch: Dispatch) => {
	if (option === 0) {
		return;
	}
	dispatch({
		type: FETCH_HOLIDAYS,
	});

	Api.get(`/user/contract/month/holidays?contract_id=${option}`, {
		headers: {
			authorization: `${localStorage.getItem('token')}`,
		},
	})
		.then((response) => {
			if (response.status === 200) {
				dispatch({
					type: SELECTED_HOLIDAYS_SUCCESS,
					payload: {
						data: response.data,
					},
				});
			} else {
				dispatch({
					type: SELECTED_HOLIDAYS_FAIL,
				});
			}
		})
		.catch((error) => {
			dispatch({
				type: SELECTED_HOLIDAYS_FAIL,
			});
		});
};

export const getContractPDF = (contractId: string) => (dispach: Dispatch) => {
	dispach({
		type: DOWNLOADING_CONTRACT,
	});

	Api.get(`/user/contract/pdf/download?contract_id=${contractId}`, {
		headers: {
			authorization: `${localStorage.getItem('token')}`,
		},
	})
		.then((response) => {
			if (response.status === 200) {
				let pdf = response.data['pdf_B64'];
				const linkSource = `data:application/pdf;base64,${pdf}`;
				const downloadLink = document.createElement('a');
				const fileName = 'contract.pdf';

				downloadLink.href = linkSource;
				downloadLink.download = fileName;
				downloadLink.click();

				notification.success({
					message: 'Contrato baixado com sucesso',
				});

				dispach({
					type: CONTRACT_DOWNLOAD_SUCCESS,
				});
			} else {
				notification.error({
					message: 'Houve um erro ao baixar o contrato',
				});
			}
		})
		.catch(() => {
			dispach({
				type: CONTRACT_DOWNLOAD_FAIL,
			});
			notification.error({
				message: 'Houve um erro ao baixar o contrato',
			});
		});
};

export const selectedMonth =
	(option: number, contractArray: ContractsHistoryMonth[]) =>
	(dispatch: Dispatch) => {
		const result = contractArray?.find((item) => item.id === option);

		if (result) {
			dispatch({
				type: SELECTED_MONTH_SUCCESS,
				payload: {
					data: {
						selected: option,
						contractSelected: result,
					},
				},
			});
		}
	};

export const getSelectedContractInfo =
	(contractId: number) => (dispatch: Dispatch) => {
		dispatch({
			type: FETCH_CONTRACT_INFO,
		});
		Api.get(`/user/contract/get?contract_id=${contractId}`, {
			headers: {
				authorization: `${localStorage.getItem('token')}`,
			},
		})
			.then((res) => {
				if (res.status === 200) {
					dispatch({
						type: FETCH_CONTRACT_INFO_SUCCESS,
						payload: {
							data: res.data,
						},
					});
				} else {
					dispatch({
						type: FETCH_CONTRACT_INFO_FAIL,
					});
				}
			})
			.catch((err) => {
				dispatch({ type: FETCH_CONTRACT_INFO_FAIL });
			});
	};

export const fetchFixated = (month_id: number) => (dispatch: Dispatch) => {
	dispatch({
		type: FETCH_FIXATED,
	});

	Api.get(`user/month/fixated?month_id=${month_id}`, {
		headers: { authorization: `${localStorage.getItem('token')}` },
	})
		.then((res) => {
			if (res.status === 200 && res.data) {
				dispatch({
					type: FETCH_FIXATED_SUCCESS,
					payload: {
						data: res.data,
					},
				});
			} else {
				dispatch({
					type: FETCH_FIXATED_FAIL,
				});
			}
		})
		.catch((err) =>
			dispatch({
				type: FETCH_FIXATED_FAIL,
			})
		);
};

export const getUrlTradingView = (month_id: number) => (dispatch: Dispatch) => {
	dispatch({
		type: FETCH_TRADINGVIEW,
	});
	Api.get(`/user/month/widget?month_id=${month_id}`, {
		headers: {
			authorization: `${localStorage.getItem('token')}`,
		},
	})
		.then((res) => {
			if (res.status === 200) {
				dispatch({
					type: FETCH_TRADINGVIEW_SUCCESS,
					payload: {
						data: res.data,
					},
				});
			} else {
				dispatch({
					type: FETCH_TRADINGVIEW_FAIL,
				});
			}
		})
		.catch((err) => {
			dispatch({ type: FETCH_TRADINGVIEW_FAIL });
		});
};




