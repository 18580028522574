import {
    FETCH_SWING,
    FETCH_SWING_FAIL,
    FETCH_SWING_SUCESS,
    Action,
    SwingState,
    FETCH_SWING_STATE,
    FETCH_SWING_STATE_FAIL,
    FETCH_SWING_STATE_SUCCESS
} from "./types";

const INITIAL_STATE: SwingState = {
    fail: false,
    loading: false,
    loadingCheck: false,
    swing: null,
    swingList: []
}

const swingReducer = (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case FETCH_SWING:
            return {
                ...state,
                loading: true,
            }
        case FETCH_SWING_SUCESS:
            return {
                ...state,
                loading: false,
                swingList: action.data,
            }
        case FETCH_SWING_FAIL:
            return {
                ...state,
                loading: false,
                fail: true
            }
        case FETCH_SWING_STATE:
            return {
                ...state,
                loadingCheck: true
            }
        case FETCH_SWING_STATE_SUCCESS:
            return {
                ...state,
                loadingCheck: false,
                swing: action.payload
            }
        case FETCH_SWING_STATE_FAIL:
            return {
                ...state,
                loadingCheck: false,
            }
        default:
            return {
                ...state
            }
    }
}

export default swingReducer;